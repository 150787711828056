import styled from "styled-components";

const PropertyPosterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .posters {
    width: 80vw;
    display: grid;
    grid-template-columns: repeat(2, auto);
    column-gap: 70px;
    padding: 0 70px;
  }

  .button-container {
    padding: 25px;
    #download {
      height: 40px;
      width: auto;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      .download-icon {
        margin: 0;
        font-size: 20px;
        margin-right: 10px;
      }
    }
  }
`;

export default PropertyPosterWrapper;
