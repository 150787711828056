import styled from "styled-components";
import {
  blue,
  error,
  green_call,
  scarpaGray300,
  scarpaGray700,
  white,
} from "../../StylesConstants";

export const CallContainer = styled.div`
  position: fixed;
  width: 250px;
  border-radius: 12px;
  background-color: ${white};
  padding: 20px 14px;
  top: 10%;
  right: 2%;
  border: 1px solid ${scarpaGray300};
  -webkit-box-shadow: 0px 0px 11px 0px rgba(50, 193, 255, 1);
  -moz-box-shadow: 0px 0px 11px 0px rgba(50, 193, 255, 1);
  box-shadow: 0px 0px 11px 0px rgba(50, 193, 255, 1);

  .close-action {
    position: relative;
    top: -9px;
    text-align: right;
    margin-bottom: -20px;
    color: ${scarpaGray700};
    cursor: pointer;
  }
  .buscar-btn {
    background-color: ${blue};
    border: 1px solid ${blue};
    border-radius: 5px;
    color: ${white};
    font-family: "Open Sans SemiBold";
    font-style: normal;
    font-weight: normal;
    line-height: 26px;
    padding: 2px 8px;
    margin: auto;
    display: flex;
  }

  .loading {
    display: grid;
    align-items: center;
    justify-content: center;
    padding: 25% 15%;
  }
`;

export const WrapperCall = styled.div`
  position: relative;
`;

export const CallOptions = styled.div`
  text-align: center;
  .number-phone {
    font-family: "Open Sans Bold";
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
  }
  .action-call {
    height: 45px;
    width: 45px;
    margin: auto;
    padding: 9px;
    border-radius: 25px;
    background-color: ${green_call};
    color: white;
    cursor: pointer;
  }
  .action-hangout {
    height: 45px;
    width: 45px;
    margin: auto;
    padding: 9px;
    border-radius: 25px;
    background-color: ${error};
    color: white;
    cursor: pointer;
  }
`;
export const InputSection = styled.div`
  display: grid;
  margin-bottom: 16px;

  label {
    margin-left: 0;
    font-family: "Open Sans SemiBold";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
  }
  input {
    height: 40px;
    border: 1px solid ${scarpaGray300};
    border-radius: 4px;
    padding: 0 10px;
  }
  .errors {
    font-family: "Open Sans";
    font-size: 13px;
    color: ${error};
  }
`;
