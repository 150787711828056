import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { withRouter } from "react-router";
import { SellersCallableWrapper } from "./style";

const ListSellersCallable = ({ data, isLoading, ...props }) => {
  const urlHubspot = process.env.REACT_APP_HUBSPOT_LINK;

  return (
    <>
      <SellersCallableWrapper>
        <div className="table-container">
          <Table className="table">
            <Thead>
              <Tr>
                <Th>NID</Th>
                <Th>Conjunto</Th>
                <Th>Area</Th>
                <Th>Piso</Th>
                <Th>Hab</Th>
                <Th>Baños</Th>
                <Th>Garajes</Th>
                <Th>Ascensor</Th>
                <Th>Precio Inicial</Th>
                <Th>Precio Habi</Th>
                <Th>Oportunidad del Negocio</Th>
                <Th>Pocentaje de Ventas</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data &&
                data.map(apartment => (
                  <Tr key={apartment.inmueble_id}>
                    <Th>
                      <a href={`${urlHubspot}${apartment.nid}`} target="_blank">
                        {apartment.nid}
                      </a>
                    </Th>
                    <Td>{apartment.proyecto}</Td>
                    <Td>{apartment.area} m</Td>
                    <Td>{apartment.piso}º</Td>
                    <Td>{apartment.habitaciones}</Td>
                    <Td>{apartment.banos}</Td>
                    <Td>{apartment.garajes < 1 ? "0" : apartment.garajes}</Td>
                    <Td>
                      {apartment.ascensores < 1 ? "0" : apartment.ascensores}
                    </Td>
                    <Td>
                      {apartment.last_ask_price !== null
                        ? "$ " +
                          new Intl.NumberFormat().format(
                            apartment.last_ask_price
                          )
                        : "$ 0"}
                    </Td>
                    <Td>
                      {apartment.precio_manual !== null
                        ? "$ " +
                          new Intl.NumberFormat().format(
                            apartment.precio_manual
                          )
                        : "$ 0"}
                    </Td>
                    <Td>
                      {apartment.oportunidad_del_negocio !== null
                        ? apartment.oportunidad_del_negocio
                        : "N/A"}
                    </Td>
                    <Td>
                      {apartment.percentage !== null
                        ? `${apartment.percentage.toFixed(2)} %`
                        : "0 %"}
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </div>
      </SellersCallableWrapper>
    </>
  );
};

export default withRouter(ListSellersCallable);
