import { createStore, applyMiddleware } from "redux";
import rootReducer from "./root-reducer";
import { INITIAL_STATE } from "./initialState";
import logger from "redux-logger";

const middleware = [];

if (process.env.NODE_ENV === "development") middleware.push(logger);

const store = createStore(
  rootReducer,
  INITIAL_STATE,
  applyMiddleware(...middleware)
);
export default store;
