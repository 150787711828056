import React from "react";
import CRMLeadsListContainer from "../../components/CRMLeadsList/CRMLeadsList.container";

const CRMPage = () => (
  <>
    <CRMLeadsListContainer />
  </>
);

export default CRMPage;
