const URL = process.env.REACT_APP_ENDPOINT_SELLER;
const GET_PROPERTY = process.env.REACT_APP_ENDPOINT_GET_PROPERTY_NOTE
const POST_PROPERTY = process.env.REACT_APP_ENDPOINT_POST_PROPERTY_NOTE 
const APIKEY = process.env.REACT_APP_SELLER_APIKEY

export const getAllComments = async (nid) => {
    const url =  `${URL}${GET_PROPERTY}?nid=${nid}`
    const rawResponse = await fetch(url, {
        method: "GET",
        headers: {
        "Content-Type": "application/json",
        "x-api-key": APIKEY,
        },
    })
    return await rawResponse.json();
}


export const putNote = async (note, nid, user, phone) => {
    console.log(phone);
    const url =  `${URL}${POST_PROPERTY}`
    const rawResponse = await fetch(url, {
        method: "POST",
        headers: {
        "Content-Type": "application/json",
        "x-api-key": APIKEY,
        },
        body: JSON.stringify(
            {
                user,
                note,
                nid,
                phone
            }
        ) 
    });
    return await rawResponse.json();
}