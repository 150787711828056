import { ROLE } from "../components/const/constants";

export const INITIAL_STATE = {
  formData: {
    email: "",
    role: ROLE.SALES, //callcenter o ventanero
    name: "",
    lastName: "",
    authenticated: false,
    flagBarrio: "",
    imageProfile: "",
    idHubspot: 0,
    inmueble_id: 0,
    imagesUpdate: [],
    messageStatus: "",
    nid: "",
    conjunto: "",
    selectedTab: "",
  },
};
