import React, { useEffect, useState } from "react";
//import { useAppDispatch } from "../../redux/hooks";

import { Button } from "@habitech/call-to-action";
import {
  ContainerCallCard,
  NumberTitle,
  PhoneNumberStatus,
  Line,
  Separator,
  SeparatorButtons,
} from "./CallCard.styles";

const CallCard = ({
  phone,
  disabledCallButton,
  callStatus,
  callCustomer,
  hangUp,
}) => {
  return (
    <ContainerCallCard>
      <NumberTitle>Número de contacto:</NumberTitle>
      <PhoneNumberStatus>{phone}</PhoneNumberStatus>
      <Line />
      <Button
        dataId="test-id"
        variant="primary"
        fluid
        onClick={() => callCustomer(phone)}
        disabled={disabledCallButton}
      >
        Llamar
      </Button>
      <Separator />
      <Button dataId="test-id" variant="white" fluid onClick={() => hangUp()}>
        Colgar
      </Button>
      <SeparatorButtons />
      <Line />
      <PhoneNumberStatus>{callStatus}</PhoneNumberStatus>
    </ContainerCallCard>
  );
};

export default CallCard;
