import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import InfoCallForm from "./infoCallForm";
import CallCard from "../CallCard/CallCard";
import { connect } from "react-redux";
import { getTokenTwilio } from "../../services/financialToolServices";
import { validatePhone } from "../../utils/callUtils";
const Device = require("twilio-client").Device;

const InfoCallContainer = ({ phone, role, ...props }) => {
  const [callStatus, setCallStatus] = useState("Cargando");
  const [disabledCallButton, setDisabledCallButton] = useState(true);

  useEffect(() => {
    Device.destroy();
    getTokenTwilio()
      .then(response => {
        setDisabledCallButton(false);
        setCallStatus("Ready");
        return response.data;
      })
      .then(data => {
        Device.setup(data.token, { debug: true });
      });
  }, [phone]);

  const callCustomer = phoneNumber => {
    const number = validatePhone(phoneNumber);
    if (number) {
      const params = {
        to_phone: number,
        agent: props.email,
        nid: props.nid,
        role: role,
      };
      Device.connect(params);
    }
  };

  const hangUp = () => {
    Device.disconnectAll();
  };

  /* Callback to let us know Twilio Client is ready */

  Device.on("error", function(error) {
    setDisabledCallButton(true);
    setCallStatus("ERROR: " + error.message);
  });

  Device.on("disconnect", function(connection) {
    setDisabledCallButton(false);
    // Disable the hangup button and enable the call buttons
    setCallStatus("Llamada finalizada!");
  });

  Device.on("connect", function(connection) {
    setDisabledCallButton(true);
    setCallStatus("En llamada con " + connection.message.to_phone);
  });

  return (
    <Fragment>
      <CallCard
        phone={phone}
        disabledCallButton={disabledCallButton}
        callStatus={callStatus}
        callCustomer={callCustomer}
        hangUp={hangUp}
      />
    </Fragment>
  );
};

const mapStateToProps = state => ({
  authenticated: state.formData.authenticated,
  email: state.formData.email,
  role: state.formData.role,
});

export default connect(mapStateToProps, null)(withRouter(InfoCallContainer));
