import React, { Fragment } from "react";
import { SimuladorWrapper, TitleWrapper } from "./style";
import { Accordion, Card } from "react-bootstrap";
import CreditCalculatorContainer from "../CreditCalculator/creditCalculatorContainer";

const Simulador = props => {
  return (
    <Fragment>
      <TitleWrapper>
        <div className="title">
          <h1>¿Que quieres saber?</h1>
        </div>
      </TitleWrapper>
      <SimuladorWrapper>
        <div className="grid-simulador">
          <CreditCalculatorContainer />
        </div>
      </SimuladorWrapper>
    </Fragment>
  );
};

export default Simulador;
