import styled from "styled-components";
import { IconButton, Modal } from "@material-ui/core";
import {
  archiaBoldFamily,
  archiaRegular,
  muliBoldFamily,
  scarpaGray600,
  nightBlue800,
  nightBlue400,
  nightBlue000,
  brightTurquoise600,
  errorPrimary,
  neutralSecondary,
  tomatoCoral500,
} from "../../StylesConstants";

export const CallableWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-height: 544px;
  padding: 40px 0;

  ::-webkit-input-placeholder {
    text-align: center;
  }
  :-moz-placeholder {
    /* Firefox 18- */

    text-align: center;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */

    text-align: center;
  }
  :-ms-input-placeholder {
    text-align: center;
  }
  .card {
    width: 619px;
    margin-right: 16px;
    border-radius: 16px;
    border-color: transparent;
    box-shadow: 5px 5px 10px #cccccc, -5px -5px 10px #f4f4f4;
    padding: 31px 54px 35px 42px;

    .title {
      font-family: ${muliBoldFamily};
      font-size: 28px;
      color: ${scarpaGray600};
      margin-bottom: 32px;
    }
    .content {
      display: flex;
      justify-content: space-between;

      .call-information {
        display: flex;
        flex-direction: row;

        .subtitle {
          font-family: ${archiaBoldFamily};
          font-size: 14px;
          color: ${nightBlue800};
          margin-bottom: 4px;
        }

        .description {
          font-family: ${archiaRegular};
          font-size: 24px;
          color: ${nightBlue400};
          margin-bottom: 26px;
        }

        .contact-information {
          padding-left: 14px;
        }
      }

      .call-buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: auto;
      }
    }
    .inputNumber {
      padding-left: 8px;
      box-sizing: border-box;
    }
  }
`;

const ModalContainer = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CallingContainer = styled(ModalContainer)`
  .content {
    display: flex;
    flex-direction: column;
    width: 619px;
    height: 293px;
    background-color: ${neutralSecondary};
    box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05),
      0px 16px 24px rgba(48, 49, 51, 0.1);
    border-radius: 16px;
    padding: 15px 15px 50px 15px;

    .calling-text {
      margin-top: 24px;
      margin-bottom: 0;
      text-align: center;
      color: ${brightTurquoise600};
      font-family: ${muliBoldFamily};
      font-size: 20px;
    }

    .close {
      display: flex;
      justify-content: flex-end;
      color: ${nightBlue400};
      cursor: pointer;
    }
  }
`;

export const FailedContainer = styled(ModalContainer)`
  .content {
    width: 619px;
    height: 293px;
    background-color: ${neutralSecondary};
    box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05),
      0px 16px 24px rgba(48, 49, 51, 0.1);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0 0 0;

    .red-circle {
      color: ${errorPrimary};
      background: ${neutralSecondary};
      font-size: 140px;
    }
    .failed-text {
      color: ${tomatoCoral500};
      font-family: ${muliBoldFamily};
      font-size: 20px;
      margin: 20px 0 50px 0;
    }
  }
`;

export const Calling = styled.div`
  display: block;
  text-align: center;
  background-color: ${brightTurquoise600};
  padding: 15px 24px 15px 18px;
  border-radius: 100px;
  color: ${nightBlue000};
  width: 170px;
  height: 54px;
  margin-bottom: 21px;
  font-family: ${muliBoldFamily};
  font-size: 16px;
  cursor: pointer;
`;

export const EndCall = styled.div`
  display: block;
  text-align: center;
  background-color: ${nightBlue000};
  padding: 15px 24px 15px 18px;
  border-radius: 100px;
  color: ${errorPrimary};
  width: 170px;
  height: 54px;
  border: 1px solid ${errorPrimary};
  font-family: ${muliBoldFamily};
  font-size: 16px;
  cursor: pointer;
`;

export const StyledIconButton = styled(IconButton)`
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};

  svg {
    background-color: #00bb2d;
    color: ${neutralSecondary};
    border-radius: 1rem;
  }
`;
