import React, { useEffect, useState } from "react";
import Select from "react-select";
import { ErrorMessage } from "formik";
import { SelectWrapper } from "./style";
import { isMobile } from "react-device-detect";
import {
  error,
  white,
  purple,
  grey,
  black,
  light_purple,
} from "../../../StylesConstants";

const SelectComponent = ({
  field,
  form,
  label,
  className,
  resetSelect,
  ...props
}) => {
  const [selected, setSelected] = useState(props.default);
  const { errors, touched, setFieldTouched, setFieldValue, values } = form;
  const { name, value, onChange, onBlur } = field;

  useEffect(() => {
    props.options.forEach(option => {
      if (option.value === values?.[name]) setSelected(option);
    });
    if (resetSelect) setSelected(props.default);
  }, [resetSelect, values]);

  useEffect(() => {
    if (value === ''){
      setSelected({label: "Select...", value: ''});
    }
  }, [value]);

  const selectStyles = {
    control: (styles, { isDisabled, isFocused, isSelected }) => ({
      ...styles,
      backgroundColor:
        errors[name] && touched[name] && selected.value === ""
          ? "rgba(255, 0, 0, 0.1)"
          : isDisabled
          ? "rgba(170, 167, 252, 0.2)"
          : { white },
      boxSizing: "border-box",
      borderRadius: "100px",
      padding: "0px 6px",
      height: "40px",
      border: styles.isFocused
        ? 0
        : isDisabled
        ? `2px solid  ${light_purple}`
        : errors[name] && touched[name] && selected.value === ""
        ? `2px solid  ${error}`
        : `2px solid  ${grey}`,
      // This line disable the blue border
      boxShadow: styles.isFocused ? 0 : 0,
      "&:hover": {
        border: `2px solid ${
          errors[name] && touched[name] && selected.value === "" ? error : grey
        }`,
      },
    }),
    singleValue: (styles, { isDisabled }) => ({
      ...styles,
      color: isDisabled
        ? light_purple
        : errors[name] && touched[name] && selected.value === ""
        ? error
        : black,
    }),
    menu: styles => ({
      ...styles,
      border: `2px solid  ${grey}`,
      boxSizing: "border-box",
      borderRadius: "8px",
      padding: "16px 16px",
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        fontSize: "16px",
        lineHeight: "19px",
        border: isFocused ? `2px solid  ${purple}` : "none",
        borderRadius: "100px",
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? null
          : isFocused
          ? purple
          : null,
        color: isDisabled
          ? grey
          : isSelected
          ? purple
          : isFocused
          ? white
          : null,
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled && (isSelected ? purple : ""),
        },
      };
    },
    input: styles => ({ ...styles }),
    placeholder: styles => ({ ...styles }),
  };

  return (
    <SelectWrapper>
      {!isMobile && (
        <div className="react-select-container">
          {label && (
            <label htmlFor={name} className="input-label">
              {label} {props.required ? "*" : ""}
            </label>
          )}
          <Select
            id={props.id}
            defaultValue={props.default}
            options={props.options}
            styles={selectStyles}
            value={selected}
            isDisabled={props.disabled}
            onChange={changeEvent => {
              if (resetSelect) setSelected(props.default);
              setFieldValue(name, changeEvent.value);
              setFieldTouched(name, true);
              setSelected({
                label: changeEvent.label,
                value: changeEvent.value,
              });
              if (props.handleChange) {
                props.handleChange(changeEvent.value);
              }
            }}
            onBlur={onBlur}
          />
          <select
            className="select-input-hidden"
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            style={{ display: "block" }}
          >
            <option value="">Seleccione</option>
            {props.options.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {errors[name] && touched[name] && (
            <div className="input-feedback">{errors[name]}</div>
          )}
          <ErrorMessage
            name="error-select-helper"
            component="div"
            className="invalid-feedback"
          />
        </div>
      )}
      {isMobile && (
        <div className="react-select-container">
          {label && (
            <label htmlFor={name} className="input-label">
              {label} {props.required ? "*" : ""}
            </label>
          )}
          <Select
            id={props.id}
            defaultValue={props.default}
            options={props.options}
            styles={selectStyles}
            value={selected}
            isDisabled={props.disabled}
            onChange={changeEvent => {
              if (resetSelect) setSelected(props.default);
              setFieldValue(name, changeEvent.value);
              setFieldTouched(name, true);
              setSelected({
                label: changeEvent.label,
                value: changeEvent.value,
              });
              if (props.handleChange) {
                props.handleChange(changeEvent.value);
              }
            }}
            onBlur={onBlur}
          />
          <select
            className="select-input-hidden"
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            style={{ display: "block" }}
          >
            <option value="">Seleccione</option>
            {props.options.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {errors[name] && touched[name] && (
            <div className="input-feedback">{errors[name]}</div>
          )}
          <ErrorMessage
            name="error-select-helper"
            component="div"
            className="invalid-feedback"
          />
        </div>
      )}
    </SelectWrapper>
  );
};

export default SelectComponent;
