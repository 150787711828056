const typeListButtons = {
    red: {
      title: 'Black List',
      message: 'No estamos dispuestos a comprar un inmueble en este conjunto'
    },
    green: {
      title: 'White List',
      message: 'Tenemos facilidad en el proceso de venta en este conjunto'
    },
    blue: {
      title: 'No List',
      message: 'No tenemos experiecia comercial en este conjunto'
    },
    yellow: {
      title: 'Grey List',
      message: 'Tenemos dificultades en el proceso de venta en este conjunto'
    }
  }

  export default typeListButtons;