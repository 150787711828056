import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Overlay from "../Overlay/overlay";
import CloudDownloadRoundedIcon from "@material-ui/icons/CloudDownloadRounded";
import PropertyPoster from "../PropertyPoster/propertyPoster";
import VisibilityRoundedIcon from "@material-ui/icons/VisibilityRounded";
import {
  bogotaBlue100,
  electricPurple400,
  electricPurple600,
  scarpaGray100,
  scarpaGray500,
} from "../../StylesConstants";
import ListSalesPropertiesHead from "./listSalesPropertiesHead";
import { getComparator, stableSort } from "../../utils/tableSort";

const useStyles = makeStyles(theme => ({
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    maxWidth: "100vw",
  },
  tableRow: {
    "&:hover": {
      backgroundColor: `${bogotaBlue100} !important`,
    },
  },
  head: {
    backgroundColor: `${scarpaGray100}`,
    fontWeight: "bold",
    fontSize: 13,
    color: `${scarpaGray500}`,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  viewIcon: {
    margin: 0,
    color: `${electricPurple400}`,
    "&:hover": {
      color: `${electricPurple600}`,
    },
  },
  downloadIcon: {
    margin: 0,
    color: `${electricPurple400}`,
    cursor: "pointer",
    "&:hover": {
      color: `${electricPurple600}`,
    },
  },
}));

const ListSalesProperties = props => {
  const { listSalesProperties } = props;

  const url_datasheet = process.env.REACT_APP_URL_DATASHEET;
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [open, setOpen] = useState(false);
  const [propertyPoster, setPropertyPoster] = useState({});

  const openLeftModal = property => {
    setPropertyPoster(property);
    setOpen(true);
  };

  const closeLeftModal = () => setOpen(false);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <ListSalesPropertiesHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(
                listSalesProperties,
                getComparator(order, orderBy)
              ).map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    key={row.inmueble}
                    hover
                    className={classes.tableRow}
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      align="center"
                    >
                      {row.nid}
                    </TableCell>
                    <TableCell align="left">{row.conjunto_edificio}</TableCell>
                    <TableCell align="left">
                      {`${row.zona_grande} / ${row.zona_mediana}`}
                    </TableCell>
                    <TableCell align="center">
                      {row.disponible_para
                        ? row.disponible_para === "venta"
                          ? "V"
                          : "P"
                        : ""}
                      {row.semillero_propietarios
                        ? row.semillero_propietarios === 1
                          ? " - S"
                          : ""
                        : ""}
                    </TableCell>
                    <TableCell align="center">{row.dias_en_venta}</TableCell>
                    <TableCell align="center">{row.num_habitaciones}</TableCell>
                    <TableCell align="center">
                      {row.banos === null || row.banos === 0 ? 0 : row.banos}
                    </TableCell>
                    <TableCell>{row.area} m</TableCell>
                    <TableCell align="center">
                      {row.num_piso === null || row.num_piso === 0
                        ? 0
                        : row.num_piso}
                      º
                    </TableCell>
                    <TableCell align="center">
                      {row.garajes === null || row.garajes === 0
                        ? 0
                        : row.garajes}
                    </TableCell>
                    <TableCell align="center">
                      {row.ascensores === null || row.ascensores === 0
                        ? 0
                        : row.ascensores}
                    </TableCell>
                    <TableCell align="center">
                      {`$ ${new Intl.NumberFormat().format(row.precio_venta)}`}
                    </TableCell>
                    <TableCell align="center">
                      <a
                        className="go-to"
                        href={`${url_datasheet}${row.nid}/${row.slug}`}
                        target="_blank"
                        rel="external noopener noreferrer"
                      >
                        <VisibilityRoundedIcon className={classes.viewIcon} />
                      </a>
                    </TableCell>
                    <TableCell align="center">
                      <div
                        className="poster"
                        onClick={() => {
                          openLeftModal(row);
                        }}
                      >
                        <CloudDownloadRoundedIcon
                          className={classes.downloadIcon}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Overlay openLeftModal={open} closeLeftModal={closeLeftModal}>
        {open && <PropertyPoster property={propertyPoster} />}
      </Overlay>
    </div>
  );
};

export default ListSalesProperties;
