import React, { useState } from "react";
import MoneyBorrowCalculator from "./moneyBorrowCalculator";

const MoneyBorrowCalculatorContainer = props => {
  const [tipoCredito, setTipoCredito] = useState("credito");
  const [cuotaMensual, setCuotaMensual] = useState(0);
  const [cuotaInicial, setCuotaInicial] = useState(0);
  const [cuotaInicialPercentage, setCuotaInicialPercentage] = useState(30);
  const [valorCredito, setValorCredito] = useState(0);
  const [plazoYears, setPlazoYears] = useState(20);
  const [isOpen, setIsOpen] = useState(true);
  const [isOpenExpenses, setIsOpenExpenses] = useState(true);
  const [tasaMensual, setTasaMensual] = useState(0.8);
  const [incomeNeeded, setIncomeNeeded] = useState(0);
  const [valorInmueble, setValorInmueble] = useState(0);
  const [monthlyIncome, setMonthlyIncome] = useState(0);

  const toggle = () => setIsOpen(!isOpen);
  const toggleExpenses = () => setIsOpenExpenses(!isOpenExpenses);

  const handleChangeYears = (event, newValue) => {
    setPlazoYears(newValue);
  };

  const handleChangeCuota = (event, newValue) => {
    setCuotaInicialPercentage(newValue);
  };
  const handleChangeTipoCredito = (name, event) => {
    setCuotaInicialPercentage(name === "leasing" ? 10 : 30);
    setTipoCredito(name);
  };
  const handleChangeInmuebleValue = e => {
    if (e.target.value.length > 5) {
      setValorInmueble(e.target.value);
    } else {
      setCuotaInicialPercentage(tipoCredito === "leasing" ? 10 : 30);
      setValorCredito(0);
      setIncomeNeeded(0);
    }
  };
  const handleChangeMonthlyIncome = e => {
    if (e.target.value.length > 6) {
      setMonthlyIncome(e.target.value);
    } else {
      setCuotaInicialPercentage(tipoCredito === "leasing" ? 10 : 30);
      setValorCredito(0);
      setIncomeNeeded(0);
    }
  };
  const handleChangeRate = (event, newValue) => {
    setTasaMensual(event.target.value);
  };
  const activeLeasing = tipoCredito === "leasing" ? "active" : "";
  const activeCredito = tipoCredito === "credito" ? "active" : "";

  const calculate = () => {
    const value = valorInmueble;
    const meses = plazoYears * 12;
    const tasaMen = tasaMensual / 100;
    const cuotaMen = monthlyIncome * 0.3;
    const montoCredito =
      cuotaMen * ((1 - Math.pow(1 + tasaMen, -meses)) / tasaMen);
    setCuotaInicial(
      parseInt(Math.max(valorInmueble - montoCredito, valorInmueble * 0.1))
    );
    setValorCredito(parseInt(montoCredito));
    setCuotaMensual(parseInt(montoCredito));
    setIncomeNeeded(parseInt(cuotaMen));
  };
  return (
    <MoneyBorrowCalculator
      activeCredito={activeCredito}
      handleChangeTipoCredito={handleChangeTipoCredito}
      cuotaMensual={cuotaMensual}
      cuotaInicial={cuotaInicial}
      valorInmueble={valorInmueble}
      valorCredito={valorCredito}
      cuotaInicialPercentage={cuotaInicialPercentage}
      handleChangeCuota={handleChangeCuota}
      plazoYears={plazoYears}
      tipoCredito={tipoCredito}
      handleChangeYears={handleChangeYears}
      tasaMensual={tasaMensual}
      incomeNeeded={incomeNeeded}
      activeLeasing={activeLeasing}
      handleChangeRate={handleChangeRate}
      handleChangeInmuebleValue={handleChangeInmuebleValue}
      handleChangeMonthlyIncome={handleChangeMonthlyIncome}
      calculate={calculate}
    />
  );
};

export default MoneyBorrowCalculatorContainer;
