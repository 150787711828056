import styled from "styled-components";
import {
  muliBoldFamily,
  nightBlue500,
  skyBlue500,
} from "../../StylesConstants";

export const ModalAlertWrapper = styled.div`
  .modal-header {
    border-bottom: none;
    padding: 24px 24px 8px;
    .modal-title {
      margin: 20px 0 0 112px;
      .icon-info {
        width: 72px;
        height: 72px;
        color: ${skyBlue500};
      }
    }
  }
  .modal-body {
    .text-info {
      font-family: ${muliBoldFamily};
      font-size: 20px;
      text-align: center;
      color: ${nightBlue500} !important;
    }
  }
`;
