import styled from "styled-components";
import {
  nightBlue200,
  skyBlue300,
  skyBlue400,
  skyBlue100,
  scarpaGray700,
  muliRegular,
  nightBlue300,
  archiaRegular,
  neutralSecondary,
} from "../../StylesConstants";

export const CallTimeWrapper = styled.div`
  display: flex;
  margin: -60px 46px 0 auto;
  flex-direction: row;
  width: 300px;
  height: 124px;
  background: ${neutralSecondary};
  border: 1px solid ${skyBlue300};
  border-radius: 16px;
  box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05),
    0px 16px 24px rgba(48, 49, 51, 0.1);
  padding: 20px 36px 15px 20.5px;

  .close {
    color: ${nightBlue200};
    cursor: pointer;
    padding-right: 12px;
  }

  .iconWrapper {
    display: flex;
    color: ${skyBlue400};
    background: ${skyBlue100};
    border-radius: 28px;
    height: 44px;
    width: 44px;
    align-items: center;
    justify-content: center;
    align-self: center;
  }
  .textWrapper {
    padding-left: 13px;
    padding-top: 10px;
    .title {
      color: ${scarpaGray700};
      font-size: 16px;
      font-family: ${muliRegular};
      font-weight: 800;
      margin: 0;
    }

    .information {
      color: ${nightBlue300};
      font-family: ${archiaRegular};
      font-size: 22px;
      margin: 0;
    }

    .time {
      color: ${nightBlue300};
      font-family: ${archiaRegular};
      font-size: 14px;
      margin: 0;
    }
  }
`;
