import styled from "styled-components";
import { size, purple, grey, white, black } from "../../StylesConstants";
import Slider from "@material-ui/core/Slider";
import { withStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";

export const CalculatorAndServiceWrapper = styled.div`
  display: grid;
  grid-area: calculator;
  margin-bottom: 64px;
  position: sticky;

  .slider-cuota-mob {
    display: block;
  }
  .slider-cuota {
    display: none;
  }

  .container-services {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    grid-template-columns: 2fr 1fr;
    grid-template-areas:
      "calculator"
      "services";

    .calculator-side {
      display: grid;
      grid-template: 1fr;
      grid-template-areas:
        "toggles"
        "detalles "
        "controls"
        "monthlyPay"
        "income";

      .calculadora-mob {
        margin-bottom: 0;
      }
      .collapsed-items {
        padding: 5% 1%;
        text-align: left;
        display: grid;
        grid-template-areas: "name icon";
        border-top: 1px solid #cecece;
        border-bottom: 1px solid #cecece;
        margin: 0;

        .name {
          font-size: 17px;
          font-style: normal;
          font-weight: bold;
          line-height: 24px;
          color: ${black};
        }
        .icon {
          font-size: 17px;
          font-style: normal;
          font-weight: bold;
          line-height: 24px;
          color: ${black};
          text-align: right;
        }
      }

      .toggles {
        .tittle-section {
          display: none;
        }
        .menu-toggle {
          display: grid;
          grid-template-areas: "left right";
          margin: 24px 0;

          .toggle-left {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            height: 40px;
            text-align: center;
            font-weight: normal;
            font-size: 14px;
            padding: 8px 7px;
            border: 1px solid ${grey};
            border-right: none;

            &:hover {
              cursor: pointer;
            }
          }
          .toggle-right {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            height: 40px;
            text-align: center;
            font-weight: normal;
            font-size: 14px;
            padding: 8px 7px;
            border: 1px solid ${grey};
            &:hover {
              cursor: pointer;
            }
          }
          .active {
            background-color: #320066;
            border: 1px solid #320066;
            color: ${white};
            font-weight: 600;
          }
        }
      }

      .detalles {
        display: grid;
        grid-template-areas: "name value ";
        margin: 0 4%;
        .name {
          text-align: left;
          font-size: 17px;
          line-height: 28px;
        }
        .value {
          text-align: right;
          font-size: 17px;
          line-height: 28px;
          span {
            margin-right: 5%;
          }
          .input-value {
            width: 80%;
            text-align: right;
            padding-left: 5%;
            padding-right: 5%;
            border: 1px solid ${purple};
            border-radius: 10px;
            margin-bottom: 10%;
            &:focus {
              outline: none;
            }
          }
        }
      }
      .controls {
        border-bottom: 1px solid ${grey};
        border-top: 1px solid ${grey};
        padding: 0 4%;
        .title-initial {
          margin-bottom: -15px;
          margin-top: 32px;
          .title-init {
            text-align: left;
            .tab-years {
              background-color: rgb(242, 229, 245, 0.5);
              padding: 5px 9px;
              border-radius: 5px;
              span {
                font-size: 17px;
                color: ${black};
                opacity: 1;
              }
            }
          }
          .value-init {
            text-align: right;
          }
        }
        .note {
          text-align: left;
          color: #979499;
          font-size: 13px;
        }
      }
      .monthly-pay {
        grid-area: monthlyPay;
        display: grid;
        grid-template: 0.3fr 1fr/1fr;
        justify-items: center;
        align-items: center;
        margin-top: 5%;
        margin-bottom: 10%;
        .monthly-title {
          display: grid;
          justify-self: center;
          text-align: center;
          font-size: 17px;
          line-height: 28px;
        }
        .monthly-amount {
          span {
            align-self: center;
            justify-self: end;
            font-size: 17px;
            line-height: 28px;
          }
          p {
            align-self: center;
            margin-bottom: 0;
          }
          display: grid;
          grid-template: 1fr / 0.3fr 1fr;
          justify-self: center;
          text-align: center;
          font-size: 40px;
          line-height: 38px;
        }
      }
      .income {
        display: grid;
        grid-template: 1fr / 1fr 1fr;
        grid-gap: 10px;
        .needed-income {
          display: grid;
          grid-template: 0.2fr 1fr / 1fr;
          border: 1px solid ${grey};
          border-radius: 10px;
          .needed-income-title {
            justify-self: center;
            align-self: center;
            p {
              font-size: 12px;
            }
          }
          .needed-income-amount {
            display: grid;
            grid-template: 1fr / 0.3fr 1fr;
            p {
              font-size: 20px;
            }
            span {
              margin-top: 5%;
              justify-self: end;
            }
          }
        }
        .fix-rate {
          display: grid;
          grid-template: 0.2fr 1fr / 1fr;
          border: 1px solid ${grey};
          border-radius: 10px;
          .fix-rate-title {
            justify-self: center;
          }
          .fix-rate-amount {
            display: grid;
            grid-template: 1fr / 1fr 1fr;
            span {
              justify-self: end;
              margin-top: 5%;
            }
          }
          .input-rate-value {
            width: 80%;
            height: 50%;
            text-align: right;
            padding-left: 5%;
            padding-right: 5%;
            border: 1px solid ${grey};
            border-radius: 10px;
            margin-bottom: 10%;
            justify-self: center;
            &:focus {
              outline: none;
            }
          }
        }
      }
    }
    .services-side {
      display: grid;
      grid-template: 1fr;
      grid-template-areas:
        "toggles"
        "detalles "
        "controls";

      .calculadora-mob {
        margin-bottom: 35px;
      }
      .collapsed-items {
        padding: 5% 1%;
        text-align: left;
        display: grid;
        grid-template-areas: "name icon";
        border-top: 1px solid #cecece;
        border-bottom: 1px solid #cecece;
        margin: -1px 0px 0 0;

        .name {
          font-size: 17px;
          font-style: normal;
          font-weight: bold;
          line-height: 24px;
          color: ${black};
        }
        .icon {
          font-size: 17px;
          font-style: normal;
          font-weight: bold;
          line-height: 24px;
          color: ${black};
          text-align: right;
        }
      }

      .toggles {
        .tittle-section {
          display: none;
        }
        .menu-toggle {
          display: grid;
          grid-template-areas: "left right";
          margin: 24px 0;

          .toggle-left {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            height: 40px;
            text-align: center;
            font-weight: normal;
            font-size: 14px;
            padding: 8px 7px;
            border: 1px solid ${grey};
            border-right: none;

            &:hover {
              cursor: pointer;
            }
          }
          .toggle-right {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            height: 40px;
            text-align: center;
            font-weight: normal;
            font-size: 14px;
            padding: 8px 7px;
            border: 1px solid ${grey};
            &:hover {
              cursor: pointer;
            }
          }
          .active {
            background-color: #320066;
            border: 1px solid #320066;
            color: ${white};
            font-weight: 600;
          }
        }
      }

      .detalles {
        display: grid;
        grid-template-areas: "name value ";
        margin: 0 4%;
        .name {
          text-align: left;
          font-size: 17px;
          line-height: 28px;
        }
        .value {
          text-align: right;
          font-size: 17px;
          line-height: 28px;
        }
      }
      .controls {
        border-bottom: 1px solid ${grey};
        border-top: 1px solid ${grey};
        padding: 0 4%;
        .title-initial {
          margin-bottom: -15px;
          margin-top: 32px;
          .title-init {
            text-align: left;
            .tab-years {
              background-color: rgb(242, 229, 245, 0.5);
              padding: 5px 9px;
              border-radius: 5px;
              span {
                font-size: 17px;
                color: ${black};
                opacity: 1;
              }
            }
          }
          .value-init {
            text-align: right;
          }
        }
        .note {
          text-align: left;
          color: #979499;
          font-size: 13px;
          line-height: 16px;
        }
      }
    }
  }

  @media screen and (min-width: ${size.tablet}) {
    .container-services {
      display: grid;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        "calculator"
        "services";
      grid-column-gap: 10px;
      .calculator-side {
        display: grid;
        grid-area: calculator;
        grid-template: 1fr;
        grid-template-areas:
          "toggles"
          "detalles "
          "controls";
        margin-bottom: 35px;
        .toggles {
          .tittle-section {
            display: block;
            text-align: left;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            color: ${black};
            margin-top: 15px;
            padding-top: 15px;
            border-top: none;
          }
        }
        .detalles {
          display: grid;
          grid-template-areas: "name value ";
          margin: 2px 4%;
          .name {
            text-align: left;
            font-size: 17px;
            line-height: 28px;
            p {
              margin-bottom: 29px !important;
            }
          }

          .value {
            text-align: right;
            font-size: 17px;
            line-height: 28px;
            p {
              margin-bottom: 29px !important;
            }
          }
        }
        .controls {
          border-top: 1px solid ${grey};
          border-bottom: none;
          padding: 0 4%;
          .title-initial {
            margin-bottom: -15px;
            margin-top: 32px;
            margin-bottom: 28px;
            .title-init {
              text-align: left;
              .tab-years {
                background-color: rgb(242, 229, 245, 0.5);
                padding: 5px 9px;
                border-radius: 5px;
                span {
                  font-size: 17px;
                  color: ${black};
                  opacity: 1;
                }
              }
            }
            .value-init {
              text-align: right;
            }
          }
          .note {
            text-align: left;
            color: #979499;
            font-size: 13px;
            line-height: 16px;
            margin-top: 7px;
          }
        }
      }
    }
    .slider-cuota-mob {
      display: none;
    }
    .slider-cuota {
      display: block;
      margin-bottom: 28px;
      .MuiSlider-valueLabel {
        margin-top: 4%;
        span {
          width: 130px;
          height: 32px;
          display: flex;
          transform: rotate(0);
          align-items: center;
          padding: 5px;
          border-radius: 10px 10px 10px 0;
          justify-content: center;
          background-color: ${purple};
        }
      }
    }
  }

  @media screen and (min-width: ${size.desktop}) {
    .slider-cuota-mob {
      display: none;
    }
    .slider-cuota {
      display: block;
      margin-bottom: 40px;
    }
    .container-services {
      display: grid;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      grid-template-columns: 1fr;
      grid-template-areas:
        "calculator"
        "services";

      .calculator-side {
        display: grid;
        grid-area: calculator;
        grid-template: 1fr;
        grid-template-areas:
          "toggles"
          "detalles "
          "controls";
        .toggles {
          .tittle-section {
            display: block;
            text-align: left;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            color: ${black};
            margin-top: 15px;
            padding-top: 15px;
            border-top: 1px solid ${grey};
          }
        }
        .detalles {
          display: grid;
          grid-template-areas: "name value ";
          margin: 0 16%;

          p {
            border-bottom: 1px solid ${grey};
            padding-bottom: 23px;
            margin-bottom: 24px;
          }
        }
        .controls {
          border-bottom: 1px solid #dddddd;
          border-top: none;
          padding: 0 16%;
        }
      }
    }
  }
`;

export const Calculator = styled.div`
  margin-bottom: 10%;
  #button-calcular-container {
    display: grid;
    justify-items: center;
  }
  #button-calcular {
    margin-bottom: 10%;
  }
`;

export const PrettoSlider = withStyles({
  root: {
    color: "#6301CC",
    height: 8
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#6301CC",
    border: "2px solid #6301CC",
    marginTop: -8,
    marginLeft: -10,
    "&:focus,&:hover,&$active": {
      boxShadow: "0px 0px 0px 10px rgba(124,1,255,0.16)"
    }
  },
  active: {
    color: red
  },
  valueLabel: {
    left: "calc(-50% + 4px)"
  },
  track: {
    height: 6,
    borderRadius: 4,
    backgroundColor: "#6301CC"
  },
  rail: {
    height: 6,
    borderRadius: 4,
    backgroundColor: "#6301CC"
  }
})(Slider);
