import React, { useState } from 'react'
import UseAnimations from 'react-useanimations';
import arrowRight from 'react-useanimations/lib/arrowRightCircle'
import { WrappInputComment } from '../Comments.style'



const InputComments = ({ addComment }) => {
    const [text, setText] = useState('');

    const validateText = text => {
        if (text.length) addComment(text)
    }

    return (
        <WrappInputComment>
            <textarea
                placeholder="Añadir comentario a la llamada"
                className="textarea"
                cols="28"
                rows="3"
                onChange={(e) => setText(e.target.value)}
            />
            <UseAnimations
                className="send"
                animation={arrowRight}
                size={28}
                strokeColor={'white'}
                onClick={() => validateText(text)}
            />
        </WrappInputComment>
    )
}

export default InputComments
