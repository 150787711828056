import React, { useState } from "react";
import CRMEditFormPage from "./CRMEditFormPage";
import { connect, useSelector } from "react-redux";
import { addCrmDataAction } from "../../redux/actions/crmData.actions";
import {
  formSections,
  allInputs,
  breakDireccion,
  habiWebPost,
  formCompatibleValue,
} from "./CRMEditForm.utils";
import {
  createValidationObject,
  createInitialValuesFromData,
} from "../../utils/formUtils";

const CRMEditFormContainer = props => {
  const { formData } = useSelector(state => state);
  const [loading, setLoading] = useState(false);
  const { crmData, crmLead, addCrmData, setIsSave } = props;

  const inputInitialValues = createInitialValuesFromData(allInputs, {
    ...crmData,
    ...breakDireccion(crmData),
  });

  const initialValues = {
    ...inputInitialValues,
    pais: crmData.pais,
    country: crmData.pais,
    num_apartamento: formCompatibleValue(crmData),
    agent_crm: formData.email,
    url_publicacion: crmData.url_publicacion,
    deal_id: crmData.deal_id,
    fuente_id: crmData.fuente_id,
  };

  const validationSchema = createValidationObject(allInputs);

  return (
    <>
      <CRMEditFormPage
        crmData={crmData}
        crmLead={crmLead}
        initialValues={initialValues}
        validationSchema={validationSchema}
        habiWebPost={habiWebPost(setLoading, addCrmData, setIsSave)}
        formSections={formSections}
        loading={loading}
      />
    </>
  );
};

const mapStateToProps = state => ({
  crmData: state.crmData,
  crmLead: state.crmLead,
});

const mapDispatchToProps = dispatch => ({
  addCrmData: value => dispatch(addCrmDataAction(value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CRMEditFormContainer);
