export const crmLeadActionTypes = {
  ADD_CRM_LEAD_DATA: "ADD_CRM_LEAD_DATA",
  CLEAR_CRM_LEAD_DATA: "CLEAR_CRM_LEAD_DATA",
};

export const addCrmLeadAction = payload => ({
  type: crmLeadActionTypes.ADD_CRM_LEAD_DATA,
  payload,
});

export const clearCrmLeadAction = () => ({
  type: crmLeadActionTypes.CLEAR_CRM_LEAD_DATA,
});
