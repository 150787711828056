import React, { useEffect, useState } from "react";
import ListSalesPropertiesMap from "./listSalesPropertiesMap";
import axios from "axios";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import { connect } from "react-redux";
import ListSalesPropertiesNid from "./listSalesPropertiesNid";

const ListSalesPropertyMapContainer = (props) => {
  const INITIAL_PAGE = 1;
  const apikey = process.env.REACT_APP_HABI_FINANCIAL_TOOL_API_KEY;
  const rootUrl = process.env.REACT_APP_FORM_API_URL_V2 + process.env.REACT_APP_ENDPOINT_HABI_FINANCIAL_TOOL_API_BASE_PATH;
  const endpointUrl = process.env.REACT_APP_ENDPOINT_HABI_FINANCIAL_TOOL_API_GET_MAPA_LOTES;
  const url = rootUrl + endpointUrl;

  //States
  const [listSalesProperties, setListSalesProperties] = useState([]);
  const [paginator, setPaginator] = useState({
    currentPage: INITIAL_PAGE,
    max_pages: 0,
    totalItemsCount: 0,
  });
  const [pageData, setPageData] = useState(INITIAL_PAGE);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const getInmuebles = (loteid) => {
      const fetchData = async () => {
        const body = {
          lote_id: loteid,
          type: props.selectedTab,
        };
        await axios({
          url: url,
          method: "post",
          headers: {
            "x-api-key": apikey,
            "Content-Type": "application/json",
          },
          data: body,
        })
          .then((response) => {
            let data = response.data;
            setListSalesProperties(data);
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(function() {
            window.scrollTo(0, 0);
            setIsLoading(false);
          });
      };
      fetchData();
    };

    if (props.idconjunto) {
      getInmuebles(props.idconjunto);
    }

    return () => {
      setIsLoading(false);
    };
  }, [props.idconjunto]);

  const handlePageChange = (newPage) => {
    setPageData(newPage);
  };
  if (props.selectedTab === "comparativo") {
    return (
      <BlockUi tag="div" blocking={isLoading}>
        <ListSalesPropertiesMap
          listSalesProperties={listSalesProperties}
          handlePageChange={handlePageChange}
          paginator={paginator}
        />
      </BlockUi>
    );
  } else {
    return (
      <BlockUi tag="div" blocking={isLoading}>
        <ListSalesPropertiesNid
          listSalesProperties={listSalesProperties}
          handlePageChange={handlePageChange}
          paginator={paginator}
        />
      </BlockUi>
    );
  }
};

const mapStateToProps = (state) => ({
  role: state.formData.role,
  selectedTab: state.formData.selectedTab,
});

export default connect(mapStateToProps)(ListSalesPropertyMapContainer);
