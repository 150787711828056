import React, { Fragment } from "react";
import ComparableCardInfo from "./comparableCardInfo";
import { TableTitle } from "./styles";

const ComparableCardContainer = ({ apartmentData, ...props }) => {
  const countDataHeaders = [
    "NID",
    "# Aptos. por conjunto",
    "# Aptos. por area",
    "# Aptos. por zona",
    "Valor Promedio Apto.",
    "Valor Mínimo Apto.",
    "Rangos Pricing",
    " Dif. Precio Manual y Precio Inicial",
    "Oportunidad de Negocio",
    "Lista",
    "Simulador pricing",
    "Imagen del Polígono",
  ];

  return (
    <Fragment>
      <TableTitle>Resumen General</TableTitle>
      <ComparableCardInfo
        countDataHeaders={countDataHeaders}
        apartmentData={apartmentData}
      />
    </Fragment>
  );
};

export default ComparableCardContainer;
