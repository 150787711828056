import React, { useState, useEffect } from "react";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import CallRoundedIcon from "@material-ui/icons/CallRounded";
import CallEndRoundedIcon from "@material-ui/icons/CallEndRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

import { WrapperCall, CallContainer, InputSection, CallOptions } from "./style";
import { Button } from '@habitech/call-to-action';

const Device = require("twilio-client").Device;

const Call = ({
  disabledCallButton,
  callCustomer,
  user,
  hangUp,
  validatePhone,
  closeCall,
  ...props
}) => {
  const [value, setValue] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const [errorDescription, setErrorDescription] = useState("");

  const getPhone = async nid => {
    const rootUrl =
      process.env.REACT_APP_FORM_API_URL_V2 +
      process.env.REACT_APP_ENDPOINT_HABI_CMS_SALES_API_BASE_PATH;
    const endpointUrl = process.env.REACT_APP_ENDPOINT_GET_PHONE_BY_NID;
    const url = rootUrl + endpointUrl;
    const apikey = process.env.REACT_APP_HABI_CMS_SALES_API_KEY;
    const body = { nid: parseInt(nid) };
    setLoading(true);
    await axios({
      url: url,
      method: "post",
      headers: {
        "x-api-key": apikey,
        "Content-Type": "application/json",
      },
      data: body,
    })
      .then(response => {
        setLoading(false);
        const phoneNew = validatePhone(response.data.data.phone);
        setPhone(phoneNew);
        setErrors(false);
        setErrorDescription("");
      })
      .catch(error => {
        setLoading(false);
        setPhone("");
        setErrors(true);
        setErrorDescription("Registro invalido intente con otro NID");
        return error;
      });
  };
  const busqueda = () => {
    if (value !== "") {
      getPhone(value);
      setErrors(false);
      setErrorDescription("");
    } else {
      setErrors(true);
      setErrorDescription("Por favor ingrese un NID");
    }
  };

  const handleChange = event => {
    let { value } = event.target;
    setValue(value);
  };
  const hangCall = () => {
    setPhone("");
    hangUp();
    closeCall();
  };
  return (
    <CallContainer>
      <div className="close-action">
        <CloseRoundedIcon
          onClick={() => {
            hangCall();
          }}
        />
      </div>
      {loading ? (
        <div className="loading">
          <CircularProgress />
        </div>
      ) : phone ? (
        <CallOptions>
          <p className="number-phone">{phone}</p>
          {!disabledCallButton && (
            <div
              className="action-call"
              onClick={() => callCustomer(phone, user, value)}
            >
              <CallRoundedIcon />
            </div>
          )}
          {disabledCallButton && (
            <div className="action-hangout">
              <CallEndRoundedIcon onClick={() => hangCall()} />
            </div>
          )}
        </CallOptions>
      ) : (
        <>
          <InputSection>
            <label for="nid">NID:</label>
            <input
              type="number"
              id="nid"
              name="nid"
              value={value}
              placeholder="Escribe tu nid"
              onChange={handleChange}
            />
            {errors && <p className="errors">{errorDescription}</p>}
          </InputSection>
          <button
            onClick={() => {
              busqueda();
            }}
            className={"buscar-btn"}
          >
            Buscar
          </button>
        </>
      )}
    </CallContainer>
  );
};

const CallAction = props => {
  const [callStatus, setCallStatus] = useState("Cargando");
  const [disabledCallButton, setDisabledCallButton] = useState(true);

  useEffect(() => {
    const apikey = process.env.REACT_APP_HABI_TWILIO_API_KEY;
    const rootUrl =
      process.env.REACT_APP_FORM_API_URL_V2 +
      process.env.REACT_APP_ENDPOINT_HABI_TWILIO_API_BASE_PATH;
    const endpointUrl =
      process.env.REACT_APP_ENDPOINT_HABI_TWILIO_API_GET_TOKEN;
    const url = rootUrl + endpointUrl;
    Device.destroy();
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": apikey,
      },
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        Device.setup(data.token, { debug: true });
      });
  }, []);

  const callCustomer = (phoneNumber, user, nid) => {
    let number = validatePhone(phoneNumber);
    if (number !== null) {
      const params = { to_phone: number, agent: user, nid: nid };
      Device.connect(params);
    } else {
      setCallStatus("Telefono Dañado");
    }
  };

  const hangUp = () => {
    Device.disconnectAll();
  };

  const validatePhone = phoneNumber => {
    let specialChars = "!@#$^&%*()=-[]/{}|:<>?,.-_ ";
    for (var i = 0; i < specialChars.length; i++) {
      phoneNumber = phoneNumber
        .toString()
        .replace(new RegExp("\\" + specialChars[i], "gi"), "");
    }
    phoneNumber = phoneNumber.trim();
    if (phoneNumber !== "/^[0-9+]{10,13}$/") {
      let firstCharacter = phoneNumber.startsWith("3");
      if (firstCharacter && phoneNumber.length === 10) {
        phoneNumber = "+57" + phoneNumber;
      } else {
        let firstCharacter = phoneNumber.startsWith("7");
        if (firstCharacter && phoneNumber.length === 11) {
          phoneNumber = "+5" + phoneNumber;
        } else {
          let firstCharacter = phoneNumber.startsWith("5");
          if (firstCharacter && phoneNumber.length === 12) {
            phoneNumber = "+" + phoneNumber;
          }
        }
      }
      return phoneNumber;
    } else {
      return null;
    }
  };

  /* Callback to let us know Twilio Client is ready */
  Device.on("ready", function(device) {
    setDisabledCallButton(false);
    setCallStatus("Ready");
  });

  Device.on("error", function(error) {
    setDisabledCallButton(true);
    // updateButtonSave(false);
    setCallStatus("ERROR: " + error.message);
  });

  Device.on("disconnect", function(connection) {
    // updateButtonSave(false);
    setDisabledCallButton(false);
    // Disable the hangup button and enable the call buttons
    setCallStatus("Llamada finalizada!");
  });

  Device.on("connect", function(connection) {
    // updateButtonSave(true);
    setDisabledCallButton(true);
    setCallStatus("En llamada con " + connection.message.to_phone);
  });

  const [callActive, setCallActive] = useState(false);
  return (
    <WrapperCall>
      <Button
        dataId="test-id"
        size="medium"
        type="submit"
        variant="stroke"
        onClick={() => setCallActive(true)}
      >
        Llamar
      </Button>
      {callActive && (
        <Call
          disabledCallButton={disabledCallButton}
          callCustomer={callCustomer}
          hangUp={hangUp}
          user={props.user}
          validatePhone={validatePhone}
          closeCall={() => setCallActive(false)}
        />
      )}
    </WrapperCall>
  );
};

export default CallAction;
