import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import { Device } from "twilio-client";
import { useDispatch, useSelector } from "react-redux";
import AlertNumberError from "./components/AlertNumberError";

// utils
import {
  updateCallingStatus,
  updateErrorStatus,
  clearCallData,
  startCall,
} from "../../redux/twillio/twillio.actions";
import { callCustomer, hangUp, getOtherToken } from "../../utils/callUtils";
// components
import ListNumbers from "./components/ListNumbers";
import { Call, CallEnd, Cancel, Close } from "@material-ui/icons";
import AccountBoxRoundedIcon from "@material-ui/icons/AccountBoxRounded";
import PhoneAndroidRoundedIcon from "@material-ui/icons/PhoneAndroidRounded";
import Comments from "../Comments/Comments";
// styles
import callingAnimation from "../../static/assets/lotties/calling.json";
import {
  Calling,
  EndCall,
  CallableWrapper,
  CallingContainer,
  FailedContainer,
} from "./Callable.style";
import CallTimeCard from "../CallTimeCard/CallTimeCard";

const Callable = ({ data }) => {
  let { nombre, nid, telefono } = data[0];
  const dispatch = useDispatch();
  const [actualNumberCall, setActualNumberCall] = useState(telefono);
  const [alert, toggleAlert] = useState(false);
  const { formData } = useSelector(state => state);
  const { calling, error, counter } = useSelector(state => state.twillio);

  useEffect(() => {
    Device.on("connect", () => {
      if (!counter) {
        dispatch(startCall());
        setTimeout(() => {
          dispatch(updateCallingStatus(false));
        }, 2500);
      }
    });

    Device.on("error", () => {
      if (!error) {
        dispatch(updateErrorStatus(true));
      }
    });

    Device.on("disconnect", () => {
      if (calling || counter) {
        dispatch(clearCallData());
      }
    });
  }, [calling, error, counter, dispatch, alert]);

  const hideCallingStatus = () => dispatch(updateCallingStatus(false));
  const hideErrorStatus = () => dispatch(updateErrorStatus(false));

  const call = async () => {
    try {
      await getOtherToken();
      await callCustomer({
        phoneNumber: actualNumberCall,
        nid: nid,
        email: formData.email,
        role: formData.role,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const hangout = () => {
    hangUp();
    dispatch(clearCallData());
  };

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: callingAnimation,
  };

  return (
    <>
      {counter && <CallTimeCard contactName={nombre} />}
      <CallableWrapper>
        <div className="card">
          <p className="title">Información de contacto</p>
          <div className="content">
            <div>
              <div className="call-information">
                <AccountBoxRoundedIcon />
                <div className="contact-information">
                  <p className="subtitle">Nombre:</p>
                  <p className="description">{nombre}</p>
                </div>
              </div>
              <div className="call-information">
                <PhoneAndroidRoundedIcon />
                <div className="contact-information">
                  <p className="subtitle">Teléfono:</p>
                  <ListNumbers
                    data={data[0]}
                    actualNumberCall={actualNumberCall}
                    setActualNumberCall={number => setActualNumberCall(number)}
                    toggleAlert={value => toggleAlert(value)}
                  />
                </div>
              </div>
            </div>
            <div className="call-buttons">
              <Calling onClick={call}>
                <Call /> Llamar
              </Calling>
              <EndCall onClick={hangout}>
                <CallEnd /> Finalizar
              </EndCall>
            </div>
          </div>
          {alert && <AlertNumberError />}
        </div>
        <Comments nid={nid} number={actualNumberCall} />
      </CallableWrapper>

      <CallingContainer open={calling} onClose={hideCallingStatus}>
        <div className="content">
          <div className="close" onClick={hideCallingStatus}>
            <Close fontSize="small" />
          </div>
          <Lottie options={lottieOptions} height="150" width="150" />
          <p className="calling-text">Conectando con cliente</p>
        </div>
      </CallingContainer>

      <FailedContainer open={error} onClose={hideErrorStatus}>
        <div className="content">
          <Cancel className="red-circle" />
          <p className="failed-text">No se pudo contactar con el cliente</p>
        </div>
      </FailedContainer>
    </>
  );
};

export default Callable;
