import React from "react";
import Paginator from "../sharedComponents/Paginator/paginator";
import { ListApartmentsWrapper } from "./style";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { withRouter } from "react-router";

const ListSalesPropertiesMap = (props) => {
  const handlePageChange = (newPage) => {
    props.handlePageChange(newPage);
  };

  return (
    <ListApartmentsWrapper>
      <div className="table-container">
        <Table className="table">
          <Thead>
            <Tr>
              <Th>Nid</Th>
              <Th>Nombre Conjunto</Th>
              <Th>area</Th>
              <Th>piso</Th>
              <Th>Baños</Th>
              <Th>Garajes</Th>
              <Th>Ascensor</Th>
              <Th>Teléfono</Th>
              <Th>Precio inicial</Th>
              <Th>Precio Habi</Th>
            </Tr>
          </Thead>
          <Tbody>
            {props.listSalesProperties &&
              props.listSalesProperties.map((apartment, index) => {
                return (
                  <Tr key={index}>
                    <Td>{apartment.nid}</Td>
                    <Td>{apartment.conjunto_edificio}</Td>
                    <Td>{apartment.area}</Td>
                    <Td>{apartment.num_piso}º</Td>
                    <Td>{apartment.banos}</Td>
                    <Td>{apartment.garajes}</Td>
                    <Td>{apartment.num_ascensores}</Td>
                    <Td>{apartment.telefono}</Td>
                    <Td>{apartment.last_ask_price}</Td>
                    <Td>{apartment.precio_habi}</Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </div>
      {props.paginator &&
        props.paginator.totalItemsCount > props.paginator.itemsCountPerPage && (
          <div className="paginator-container">
            <Paginator
              className="paginator"
              handlePageChange={handlePageChange}
              paginator={props.paginator}
            />
          </div>
        )}
    </ListApartmentsWrapper>
  );
};

export default withRouter(ListSalesPropertiesMap);
