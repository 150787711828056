import React, { useState, useEffect } from "react";
import {
  MuiThemeProvider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {
  NoResultsContainer,
  StyledTable,
  TableWrapper,
} from "./TableInfoLot.style";
import { headInfoLot } from "./TableInfoLot.utils";
import { getMinMax } from "../../services/financialToolServices";

const TableInfoLot = props => {
  const { nid } = props;
  const [data, setData] = useState([]);
  const urlHubspot = process.env.REACT_APP_HUBSPOT_LINK;

  const hubspotUrl = process.env.REACT_APP_HUBSPOT_LINK;

  useEffect(() => {
    getMinMax(nid)
      .then(result => {
        const res = [
          {
            nid: result.data.data.max_area,
            range: "Minímo",
            ...result.data.data[0],
          },
          {
            nid: result.data.data.max_area,
            range: "Máximo",
            ...result.data.data[1],
          },
        ];
        setData(res);
      })
      .catch(error => console.log(error));
  }, [nid]);

  return (
    <TableWrapper>
      <h2 className="table-title">Información Conjunto</h2>
      <MuiThemeProvider theme={StyledTable}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {headInfoLot.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                Object.keys(data).length > 0 &&
                Object.values(data).map((row, idx) => {
                  return (
                    <TableRow hover tabIndex={-1} key={idx}>
                      {headInfoLot.map(column => {
                        const value = row[column.id];
                        if (column.id === "nid") {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              <a
                                href={`${urlHubspot}${column.format(value)}`}
                                target="_blank"
                              >
                                {column.format(value)}
                              </a>
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format(value)}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {data.length === 0 && (
          <NoResultsContainer>
            <p className="top-message">No hay información disponible.</p>
          </NoResultsContainer>
        )}
      </MuiThemeProvider>
    </TableWrapper>
  );
};

export default TableInfoLot;
