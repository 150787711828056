import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Layout } from "./style";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import LogIn from "./components/LogIn/login";
import AuthenticatedRoute from "./components/Auth/authenticatedRoute";
import queryString from "query-string";
import { updateData } from "./redux/actions/formData.actions";

import Dashboard from "./components/Dashboard/dashboard";

const App = props => {
  const { authenticated } = props;

  useEffect(() => {
    if (window.location.href.includes("?")) {
      let parsed = queryString.parse(window.location.search);
      props.updateData({
        conjunto: parsed.conjunto,
        nid: parsed.nid,
      });
    }
  }, []);

  return (
    <div>
      <Router>
        <Layout>
          <Route exact path="/">
            <LogIn />
          </Route>
          <AuthenticatedRoute
            path="/dashboard"
            component={Dashboard}
            appProps={{ isAuthenticated: authenticated }}
          />
        </Layout>
      </Router>
    </div>
  );
};
const mapStateToProps = state => ({
  authenticated: state.formData.authenticated,
  conjunto: state.formData.conjunto,
  nid: state.formData.conjunto,
});
const mapDispatchToProps = dispatch => ({
  updateData: data => dispatch(updateData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
