export const crmDataActionTypes = {
  ADD_CRM_DATA: "ADD_CRM_DATA",
  CLEAR_CRM_DATA: "CLEAR_CRM_DATA",
};

export const addCrmDataAction = payload => ({
  type: crmDataActionTypes.ADD_CRM_DATA,
  payload,
});

export const clearCrmDataAction = () => ({
  type: crmDataActionTypes.CLEAR_CRM_DATA,
});
