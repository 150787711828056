import React from "react";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import SortingTableHeader from "../SortingTableHeader/SortingTableHeader";
import Button from "@habi/habi-react-components/dist/Button/button";
import EditIcon from "@material-ui/icons/Edit";
import DotsLoading from "../DotsLoading/dotsLoading";

import { CRMTableContainer, LoadingContainer } from "./CRMDetailsList.style";

const CRMDetailsList = ({
  rows,
  classes,
  headerCells,
  order,
  orderBy,
  handleSort,
  addCrmData,
  loading,
}) => (
  <CRMTableContainer>
    <Paper className={classes.paper}>
      <TableContainer>
        <Table>
          <SortingTableHeader
            headerCells={headerCells}
            keyPropName="label"
            sortPropName="value"
            labelPropName="label"
            orderBy={orderBy}
            order={order}
            handleSort={handleSort}
            classes={classes}
          />
          <TableBody>
            {rows.map(business => (
              <TableRow key={business.nid} className={classes.tableRow}>
                {headerCells.map(cell => {
                  if (cell.value !== "editar")
                    return (
                      <TableCell key={business[cell.value]}>
                        {business[cell.value]}
                      </TableCell>
                    );
                  if (cell.value === "editar")
                    return (
                      <TableCell key={Math.random()}>
                        <Button
                          id="button"
                          typeClass="primary"
                          className="d-inline-block "
                          label="Editar"
                          type="button"
                          onClick={() => {
                            addCrmData(business);
                          }}
                        >
                          <EditIcon className="call-icon" />
                          &nbsp;
                        </Button>
                      </TableCell>
                    );
                  else return "";
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {loading && (
          <LoadingContainer>
            <DotsLoading />
          </LoadingContainer>
        )}
      </TableContainer>
    </Paper>
  </CRMTableContainer>
);

export default CRMDetailsList;
