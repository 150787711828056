import { range } from "./utils";
import * as yup from "yup";

export const createEmptyInitialValues = inputs => {
  const names = inputs.map(input => input.name);
  return names.reduce((acc, curr) => {
    return { ...acc, [curr]: "" };
  }, {});
};

export const createInitialValuesFromData = (inputs, data) => {
  const initialValues = inputs.reduce((acc, input) => {
    const name = input.name;
    return { ...acc, [name]: data[name] ?? "" };
  }, {});
  return initialValues;
};

export const createValidationObject = inputs => {
  const inputValidations = inputs.reduce((acc, input) => {
    return { ...acc, [input.name]: input.validation };
  }, {});
  const validationObj = yup.object().shape(inputValidations);
  return validationObj;
};

export const generateNumberLabels = includeZero => num => {
  const labelLength = includeZero ? num : num - 1;
  const startValue = includeZero ? 0 : 1;
  const arr = range(labelLength);
  const labels = arr.reduce(
    (acc, _, i) => {
      const currentValue = acc[i].value + 1;
      return [...acc, { label: `${currentValue}`, value: currentValue }];
    },
    [{ label: `${startValue}`, value: startValue }]
  );
  return labels;
};

export const generateNumberLabelsFromZero = generateNumberLabels(true);

export const generateNumberLabelsFromOne = generateNumberLabels(false);
