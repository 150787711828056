import React from "react";
import { useEffect, useState } from "react";
import GoogleLogin from "./GoogleButton";
import jwt from 'jwt-decode';
import login from "../../utils/authClient";

const LoginGoogle = ({ onNextStep, onError }) => {
  const [google, setGoogle] = useState();
  const [googleIsLoading, setGoogleIsLoading] = useState(true);
  const HABI_DOMAINS = ["habi.co", "tuhabi.mx"];

  const onSuccessLogin = async (googleResponse) => {
    if (!("credential" in googleResponse)) {
      onError("user offline");
      return;
    }

    const data = jwt(googleResponse.credential);
    const external = !HABI_DOMAINS.some((item) => item === data.hd);
    const cognitoData = await login(data.sub, external);

    if (data) {
      onNextStep({
        roles: cognitoData.data.role,
        email: data.email,
        name: data.given_name,
        lastName: data.family_name,
        imageProfile: data.picture,
        exp: data.exp,
        iat: data.iat,
        id: data.sub,
        tokens: cognitoData.data.AuthenticationResult,
      });
    } else {
      onError("user no permission");
    }
  };

  useEffect(() => {
    setInterval(() => {
      if (typeof window !== "undefined" && (window).google) {
        setGoogle((window).google);
        setGoogleIsLoading(false);
      }
    }, 100);
  }, []);

  return (
    <div>
      {!googleIsLoading && google && (
        <GoogleLogin onGoogleSignIn={onSuccessLogin} />
      )}
    </div>
  );
};

export default LoginGoogle;
