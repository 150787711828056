import React from "react";
import {
  ButtonPrimary,
  ButtonSecondary,
  ImageAndIconContainer,
} from "./Button.style.js";
const Button = ({
  label,
  buttonType,
  size,
  iconSrc,
  svgIcon,
  iconEnd,
  fillDiv,
  svgFill,
  tablet,
  desktop,
  submit,
  style,
  ...props
}) => {
  const hasIcon = iconSrc || svgIcon;
  const iconAtStart = hasIcon && !iconEnd;
  const iconAtEnd = hasIcon && iconEnd;
  const fullWidth = fillDiv ? { width: "100%" } : {};
  const className = `${
    size ?? "extra-small"
  } tablet-${tablet} desktop-${desktop}`;
  const endClassName = iconAtEnd ? "end" : "";
  const ImgIcoContainer = ({ children }) => (
    <ImageAndIconContainer className={endClassName}>
      {children}
    </ImageAndIconContainer>
  );
  const selectIcon = () => {
    if (iconSrc) {
      return (
        <ImgIcoContainer>
          <img src={iconSrc} alt="action icon" />
        </ImgIcoContainer>
      );
    }
    if (svgIcon) {
      return <ImgIcoContainer>{svgIcon}</ImgIcoContainer>;
    }
  };
  const propsStyle = { ...fullWidth, ...style } || {};
  if (buttonType === "secondary")
    return (
      <ButtonSecondary
        {...props}
        className={className}
        svgFill={svgFill}
        style={propsStyle}
      >
        {iconAtStart && selectIcon()}
        {label}
        {iconAtEnd && selectIcon()}
      </ButtonSecondary>
    );
  return (
    <ButtonPrimary
      svgFill={svgFill}
      {...props}
      className={className}
      style={propsStyle}
    >
      {iconAtStart && selectIcon()}
      {label}
      {iconAtEnd && selectIcon()}
    </ButtonPrimary>
  );
};

export default Button;
