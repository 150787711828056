import React, { useState } from "react";
import { IconButton, InputBase, Paper, Divider } from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";

export default function InputNumber({ addNumber }) {
  const [newNumberContact, setNewNumberContact] = useState("");
  const handleSubmit = () => {
    addNumber(newNumberContact);
    setNewNumberContact("");
  };
  return (
    <Paper>
      <InputBase
        className="inputNumber"
        placeholder="Añadir nuevo número"
        onChange={e => setNewNumberContact(e.target.value)}
        value={newNumberContact}
      />
      <IconButton onClick={handleSubmit} aria-label="search">
        <AddCircleOutline />
      </IconButton>
      <Divider orientation="vertical" />
    </Paper>
  );
}
