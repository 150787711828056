import deepClone from "./deepClone";

const globalInterceptor = (config) => {
  const newConfig = deepClone(config);

  if (config.method === 'get' || config.method === 'delete') {
    newConfig.url = `${config.url}?country=CO`;
  } else {
    newConfig.data.country = "CO";
  }

  return {
    ...config,
    url: newConfig.url,
    data: newConfig.data,
  };
};

export default globalInterceptor;
