import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { DataMarkerWrapper } from "./style";
import axios from "axios";
import { fetchDataAxios } from "../../utils/hooks/fetchRequest";
import MarkerConjunto from "../MarkerConjunto/markerConjunto";
import MarkerComparativo from "../MarkerComparativo/markerComparativo";

const DataMarker = (props) => {
  const [dataConjunto, setDataConjunto] = useState([]);
  const apikey = process.env.REACT_APP_HABI_FINANCIAL_TOOL_API_KEY;
  const rootUrl = process.env.REACT_APP_FORM_API_URL_V2 + process.env.REACT_APP_ENDPOINT_HABI_FINANCIAL_TOOL_API_BASE_PATH;
  const endpointUrl = process.env.REACT_APP_ENDPOINT_HABI_FINANCIAL_TOOL_API_GET_INMUEBLES_BY_NID;
  const url = rootUrl + endpointUrl;

  useEffect(() => {
    if (props.selectedTab === "comparativo") {
      const getDataConjunto = () => {
        const fetchData = async () => {
          const body = {
            idconjunto: props.marker.idconjunto,
          };
          await axios({
            url: url,
            method: "post",
            headers: {
              "x-api-key": apikey,
              "Content-Type": "application/json",
            },
            data: body,
          })
            .then((response) => {
              let data = response.data;
              setDataConjunto(data);
            })
            .catch((error) => {
              console.error(error);
            })
            .finally(function() {
              window.scrollTo(0, 0);
            });
        };
        fetchData();
      };
      getDataConjunto();
      return () => {};
    }
  }, []);

  if (props.selectedTa !== "comparativo") {
    return <MarkerConjunto data={props.marker} showTable={props.showTable} />;
  } else {
    return (
      <MarkerComparativo
        dataConjunto={dataConjunto}
        showTable={props.showTable}
      />
    );
  }
};

const mapStateToProps = (state) => ({
  conjunto: state.formData.conjunto,
  nid: state.formData.nid,
  selectedTab: state.formData.selectedTab,
});

export default connect(mapStateToProps)(DataMarker);
