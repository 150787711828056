import styled from "styled-components";
import {
  bogotaBlue100,
  scarpaGray100,
  scarpaGray500,
  size,
} from "../../StylesConstants";
import { makeStyles } from "@material-ui/core/styles";

export const CRMTableContainer = styled.div`
  padding: 24px 0;
  width: 100vw;
  overflow: auto;
  margin: auto;

  @media screen and (min-width: ${size.tablet}) {
    padding: 24px;
    width: calc(100vw - 24px);
  }

  td {
    #button {
      padding: 0 16px;
      width: fit-content;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const LoadingContainer = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const useStyles = makeStyles(theme => ({
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    maxWidth: "100vw",
  },
  tableRow: {
    "&:hover": {
      backgroundColor: `${bogotaBlue100} !important`,
    },
  },
  head: {
    backgroundColor: `${scarpaGray100}`,
    fontWeight: "bold",
    fontSize: 13,
    color: `${scarpaGray500}`,
  },
}));
