import React, { useEffect, useState } from "react";
import Axios from "axios";
import { connect } from "react-redux";
import { addCrmLeadAction } from "../../redux/actions/crmLead.actions";
import { addCrmDataAction } from "../../redux/actions/crmData.actions";
import CRMDetailsList from "./CRMDetailsList.component";
import { headerCells } from "./CRMDetailsList.data";
import { stableSort, getComparator } from "../../utils/tableSort";

import { useStyles } from "./CRMDetailsList.style";

const CRMDetailsListContainer = props => {
  const [resData, setResData] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  const { crmLead, addCrmLeadData, addCrmData } = props;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const apiKey = process.env.REACT_APP_HABI_API_FORMS_KEY;
      const basePath = process.env.REACT_APP_FORM_API_URL_V2;
      const apiUrl = process.env.REACT_APP_ENDPOINT_HABI_API_FORMS_BASE_PATH;
      const endpoint = process.env.REACT_APP_ENDPOINT_GET_DEAL_BY_PHONE;
      const url = basePath + apiUrl + endpoint;
      await Axios({
        url,
        method: "post",
        headers: {
          "x-api-key": apiKey,
          "Content-Type": "application/json",
        },
        data: { telefono: crmLead.telefono },
      })
        .then(res => {
          const data = res.data.result;
          setResData(data);
          addCrmLeadData({ nidParaLlamada: data[0]?.nid });
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          console.error(err);
        });
    };
    fetchData();
    //eslint-disable-next-line
  }, [setResData, crmLead.telefono]);

  const handleSortRequest = prop => {
    const isAsc = orderBy === prop && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(prop);
  };

  const sortedRows = stableSort(resData, getComparator(order, orderBy));

  return (
    <CRMDetailsList
      rows={sortedRows}
      classes={classes}
      headerCells={headerCells}
      order={order}
      orderBy={orderBy}
      handleSort={handleSortRequest}
      addCrmData={addCrmData}
      loading={loading}
    />
  );
};

const mapStateToProps = state => ({
  crmLead: state.crmLead,
});

const mapDispatchToProps = dispatch => ({
  addCrmLeadData: value => dispatch(addCrmLeadAction(value)),
  addCrmData: value => dispatch(addCrmDataAction(value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CRMDetailsListContainer);
