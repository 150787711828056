import React, { useEffect, useState } from "react";
import { getComparable } from "../../services/financialToolServices";
import TableComparable from "../TableComparable/TableComparable";
import { headColumnsPricing } from "./ComparableByPricing.utils";

const ComparableByPricing = props => {
  const { nid } = props;
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [more, setMore] = useState(false);

  useEffect(() => {
    const dataToSend = {
      nid: nid,
      limit: limit,
      offset: offset,
    };
    getComparable(dataToSend, "get_pricing_set")
      .then(response => {
        setData([...data, ...response.data.data]);
        setMore(response.data.more_available);
      })
      .catch(error => console.log(error));
  }, [nid, limit, offset]);

  return (
    <TableComparable
      title="Comparables pricing conjuntos"
      headers={headColumnsPricing}
      data={data}
      limit={limit}
      setLimit={setLimit}
      offset={offset}
      setOffset={setOffset}
      more={more}
    />
  );
};

export default ComparableByPricing;
