import styled from "styled-components";
import { electricPurple500 } from "../../StylesConstants";

export const DotsLoadingWrapper = styled.div`
  .dots-container{
    text-align: center;
    bottom: 10px;
    .spinner-grow {
      color: ${electricPurple500};
      margin: 5px;
    }
  }
`;