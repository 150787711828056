import styled from "styled-components";
import {
  brightTurquoise700,
  goldenYellow500,
  bogotaBlue900,
  error,
  informationPrimary
 } from '../../StylesConstants'

import {
  archiaRegular,
  bogotaBlue100,
  muliBoldFamily,
  nightBlue500,
  scarpaGray100,
  scarpaGray500,
  scarpaGray600,
} from "../../StylesConstants";

export const ListTableWrapper = styled.div`
  .table-container {
    font-family: ${archiaRegular};
    padding: 24px;

    table {
      border-bottom: 1px solid ${scarpaGray100};
    }

    thead {
      background-color: ${scarpaGray100};
      font-weight: bold;
      font-size: 13px;
      color: ${scarpaGray500};

      th {
        vertical-align: middle;
        padding: 16px;

        &.dateCreation {
          width: 170px;
        }
      }
    }

    tbody {
      font-size: 13px;
      color: ${scarpaGray600};
      &:hover {
        background-color: ${bogotaBlue100};
      }

      tr {
        height: 64px;

        th {
          font-weight: normal;
          vertical-align: middle;
          padding: 0 16px;
        }
      }
    }
  }
`;

export const TableTitle = styled.h2`
  font-family: ${muliBoldFamily};
  font-size: 20px;
  line-height: 28px;
  color: ${nightBlue500};
  padding-left: 24px;
  margin-bottom: 0;
`;

export const RedList = styled.th`
  color: ${error};
  font-weight: 700 !important;
`;
export const YellowList = styled.th`
  color: ${goldenYellow500};
  font-weight: 700 !important;
`;
export const GreenList= styled.th`
  color: ${brightTurquoise700};
  font-weight: 700 !important;
`;
export const BlueList = styled.th`
  color: ${informationPrimary};
  font-weight: 700 !important;
`;

export const AlertModal = styled.div`
  position: fixed;
  inset: 0;
  z-index: 1000;

  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0, 0.5);
  
  display: flex ;
  align-items: center;
  justify-content: center;
  .alert {
    width: 455px  ;
  }
`;