import styled from 'styled-components';
import { white } from './StylesConstants';

export const Layout = styled.div`
  body {
    background-color: ${white};
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
  }
`;
