import styled, { keyframes } from "styled-components";
import {
  grey,
  neutralSecondary,
  nightBlue500,
  scarpaGray000,
  white,
} from "../../StylesConstants";

export const Wraap = styled.div`
  width: 384px;
  padding: 24px;
  box-sizing: border-box;
  border-radius: 16px;
  background-color: ${scarpaGray000};
  box-shadow: 5px 5px 10px #cccccc,-5px -5px 10px #f4f4f4;

  #Allcomments {
    overflow: scroll;
    overflow-x: hidden;
    box-sizing: border-box;
    height: 70%;
    background-color: ${scarpaGray000};

    padding: 8px;
    margin-bottom: 16px;
    border-radius: 5px;
    transition-duration: 2s;
    transition-timing-function: ease-in-out;

    &::-webkit-scrollbar {
      width: 0.6em;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: none;
      border-radius: 50px;
    }
  }
`;

const show = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const WrappComment = styled.div`
  width: 100%;
  height: auto;
  background-color: ${neutralSecondary};
  box-shadow: 5px 5px 10px #cccccc, -5px -5px 10px #f4f4f4;
  border-radius: 5px;
  color: ${nightBlue500};
  position: relative;
  margin-bottom: 8px;
  padding: 8px;
  .note {
    margin: 4px 0;
  }
  .autor {
    font-size: 12px;
    text-align: right;
    margin: 4px 0;
  }
  p {
    word-break: break-all;
  }
  opacity: 0;
  animation: 2s ${show} ease-out;
  animation-fill-mode: forwards;
`;

export const WrappInputComment = styled.div`
  background-color: ${white};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  overflow: hidden;
  padding: 8px;
  textarea {
    resize: none;
    outline: none;
    border-color: transparent;
    padding: 8px;
    border-radius: 5px;
    transition-duration: 500ms;
  }
  textarea:focus {
    border-color: ${grey};
  }
  textarea::-webkit-scrollbar {
    width: 0.6em;
  }

  textarea::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  textarea::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: none;
    border-radius: 50px;
  }
  .send {
    background-color: #00c29c;
    /* border-radius: 36px; */
    background: linear-gradient(145deg, #00d0a7, #00af8c);
    box-shadow: 5px 5px 10px #d8d7d9, -5px -5px 10px #f4f3f5;
    border-radius: 50%;
    padding: 8px;
    box-sizing: content-box;
    color: white;
    margin-left: 8px;
    /* #E6E5E7 */
  }
`;
