import React from "react";
import DotsLoading from "../DotsLoading/dotsLoading";

import { WhiteOverlay } from "./LoadingOverlay.style";

const LoadingOverlay = (props) => {
  return (
    <WhiteOverlay>
      <h1>Procesando...</h1>
      <DotsLoading />
    </WhiteOverlay>
  );
};

export default LoadingOverlay;
