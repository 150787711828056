import Axios from "axios";

export const fetchCRMLeads = async ({
  setResData,
  setTotalItems,
  page,
  rowsPerPage,
  setLoading,
}) => {
  setLoading(true);
  const body = {
    page: page + 1,
    items: rowsPerPage,
    filters: {
      telefono: "",
    },
  };
  await Axios({
    url: process.env.REACT_APP_ENDPOINT_GET_INFO_CRM_TABLE,
    method: "post",
    headers: {
      "x-api-key": process.env.REACT_APP_HABI_FINANCIAL_TOOL_API_KEY,
      "Content-Type": "application/json",
    },
    data: body,
  })
    .then(res => {
      setResData(res.data.data);
      setTotalItems(res.data.count);
      setLoading(false);
    })
    .catch(err => {
      setLoading(false);
      console.error(err);
    });
};

export const fetchCRMLeadsByPhone = ({
  setResData,
  setTotalItems,
  page,
  rowsPerPage,
  crmData,
  setLoading,
}) => {
  const fetchData = async () => {
    setLoading(true);

    const body = {
      page: page + 1,
      items: rowsPerPage,
      filters: {
        telefono,
      },
    };
    await Axios({
      url: process.env.REACT_APP_ENDPOINT_GET_INFO_CRM_TABLE,
      method: "post",
      headers: {
        "x-api-key": process.env.REACT_APP_HABI_FINANCIAL_TOOL_API_KEY,
        "Content-Type": "application/json",
      },
      data: body,
    })
      .then(res => {
        setResData(res.data.data);
        setTotalItems(res.data.count);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.error(err);
      });
  };
  const telefono = crmData?.telefonoFiltro;
  if (telefono === "") return fetchData();
  if (telefono?.length >= 7) return fetchData();
  return;
};
