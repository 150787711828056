import React from "react";
import Paginator from "../sharedComponents/Paginator/paginator";
import { ListApartmentsWrapper } from "./style";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { withRouter } from "react-router";

const ListSalesPropertiesMap = props => {
  const handlePageChange = newPage => {
    props.handlePageChange(newPage);
  };

  return (
    <ListApartmentsWrapper>
      <div className="table-container">
        <Table className="table">
          <Thead>
            <Tr>
              <Th>Tipo inmueble</Th>
              <Th>Area</Th>
              <Th>Piso</Th>
              <Th>Hab</Th>
              <Th>Baños</Th>
              <Th>Garajes</Th>
              <Th>Precio</Th>
            </Tr>
          </Thead>
          <Tbody>
            {props.listSalesProperties &&
              props.listSalesProperties.map((apartment, index) => {
                if (
                  apartment.area &&
                  apartment.num_piso &&
                  apartment.num_habitaciones
                ) {
                  return (
                    <Tr key={index}>
                      <Td>{apartment.tipo_inmueble}</Td>
                      <Td>{apartment.area} m</Td>
                      <Td>{apartment.num_piso}º</Td>
                      <Td>{apartment.num_habitaciones}</Td>
                      <Td>{apartment.banos}</Td>
                      <Td>{apartment.garajes}</Td>
                      <Td>
                        ${" "}
                        {apartment.last_ask_price
                          ? `entre ${new Intl.NumberFormat().format(
                              apartment.last_ask_price -
                                (Math.floor(Math.random() * 20) + 1) * 1000000
                            )} y ${new Intl.NumberFormat().format(
                              apartment.last_ask_price +
                                (Math.ceil(Math.random() * 20) + 1) * 1000000
                            )}`
                          : ""}
                      </Td>
                    </Tr>
                  );
                }
              })}
          </Tbody>
        </Table>
      </div>
      {props.paginator &&
        props.paginator.totalItemsCount > props.paginator.itemsCountPerPage && (
          <div className="paginator-container">
            <Paginator
              className="paginator"
              handlePageChange={handlePageChange}
              paginator={props.paginator}
            />
          </div>
        )}
    </ListApartmentsWrapper>
  );
};

export default withRouter(ListSalesPropertiesMap);
