import React, { useEffect, useState, forwardRef } from "react";
import { LargePic, PosterWrapper, SmallPic } from "./style";
import habiLogo from "../../static/assets/logo/Logo_blanco.svg";
import Bed from "../../static/assets/icons/hotel-white-18dp.svg";
import Shower from "../../static/assets/icons/bathtub-white-18dp.svg";
import Stratus from "../../static/assets/icons/dashboard-white-18dp.svg";
import Area from "../../static/assets/icons/crop_free-white-18dp.svg";
import Car from "../../static/assets/icons/directions_car-white-18dp.svg";

const PosterLogo = forwardRef((props, ref) => {
  const { property, convert } = props;
  const [imageLogo1, setImageLogo1] = useState("");
  const [imageLogo2, setImageLogo2] = useState("");
  const [imageLogo3, setImageLogo3] = useState("");

  useEffect(() => {
    const bucketUrl = process.env.REACT_APP_BUCKET_IMG;
    let urlImageLogo1 = "";
    let urlImageLogo2 = "";
    let urlImageLogo3 = "";

    if (property.images) {
      let newImage = property.images.split(",");

      if (newImage[0]) {
        urlImageLogo1 = bucketUrl + newImage[0].split(".").join("-765.");
        convert(urlImageLogo1, setImageLogo1);
      }
      if (newImage[1]) {
        urlImageLogo2 = bucketUrl + newImage[1].split(".").join("-765.");
        convert(urlImageLogo2, setImageLogo2);
      }
      if (newImage[2]) {
        urlImageLogo3 = bucketUrl + newImage[2].split(".").join("-765.");
        convert(urlImageLogo3, setImageLogo3);
      }
    }
  }, [property, convert]);

  return (
    <PosterWrapper>
      {property && (
        <div className="poster-container" ref={ref}>
          <div className="image-container">
            <div className="pic-large">
              <LargePic src={imageLogo1} />
            </div>
            <div className="container-pic-small">
              <SmallPic src={imageLogo2} />
              <SmallPic src={imageLogo3} />
            </div>
          </div>
          <div className="text-container">
            <h2 className="title">Para vivir o invertir</h2>
            <h4 className="subtitle">No recibimos subsidios</h4>
            <h4 className="price">
              {"$ " + new Intl.NumberFormat().format(property.precio_venta)}
            </h4>
            <p className="text">
              Apartamento remodelado en
              <br />
              <span className="sub-text">
                {property.zona_mediana}, {property.zona_grande}.
              </span>
            </p>
            <div className="footer">
              <div className="amenities-logo">
                <span className="text-amenities">
                  {property.habitaciones}
                  <img src={Bed} alt="bed" className="icon" />
                  <br /> Habitaciones
                </span>
                <span className="text-amenities">
                  {property.banos}
                  <img src={Shower} alt="shower" className="icon" />
                  <br />
                  Baños
                </span>
                <span className="text-amenities">
                  {property.estrato}
                  <img src={Stratus} alt="startus" className="icon" />
                  <br /> Estrato
                </span>
                <span className="text-amenities">
                  {property.area}
                  <img src={Area} alt="startus" className="icon" />
                  <br />
                  Área
                </span>
                <span className="text-amenities">
                  {property.garajes}
                  <img src={Car} alt="startus" className="icon" />
                  <br /> Parqueadero
                </span>
              </div>
              <img src={habiLogo} alt="habiLogo" className="logo" />
            </div>
          </div>
        </div>
      )}
    </PosterWrapper>
  );
});

export default PosterLogo;
