import React from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import { compose, withProps, withHandlers, withStateHandlers } from "recompose";
import { MarkerClusterer } from "react-google-maps/lib/components/addons/MarkerClusterer";
import plus from "../../static/assets/img/plus.png";
import DataMarker from "../DataMarker/dataMarker";

const apikey = process.env.REACT_APP_GOOGLE_MAPS_KEY;
const MapWithAMarkerClusterer = compose(
  withProps({
    googleMapURL:
    'https://maps.googleapis.com/maps/api/js?key='+apikey+'&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `70vh` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withHandlers({
    onMarkerClustererClick: () => (markerClusterer) => {
      const clickedMarkers = markerClusterer.getMarkers();
    },
    onMarkerCLick: () => (e, markerClick) => {
      console.log("markerClick", markerClick);
      console.log("e", e);
    },
  }),
  withStateHandlers(
    () => ({
      isOpen: false,
      infoIndex: null,
    }),
    {
      showInfo: ({ isOpen, infoIndex }) => (index) => ({
        isOpen: infoIndex !== index || !isOpen,
        infoIndex: index,
      }),
    }
  ),
  withScriptjs,
  withGoogleMap
)((props) => (
  <GoogleMap
    defaultZoom={11}
    defaultCenter={{ lat: 4.689482551, lng: -74.07281478 }}
  >
    <MarkerClusterer
      onClick={props.onMarkerClustererClick}
      averageCenter
      enableRetinaIcons
      gridSize={60}
      imagePath={plus}
    >
      {props.markers.map((marker, index) => (
        <Marker
          key={index}
          position={{ lat: marker.latitud, lng: marker.longitud }}
          onClick={() => props.showInfo(index)}
          options={{
            icon:
              "https://cms-images-storages.s3.us-east-2.amazonaws.com/habi-marker-pin-1x.png",
          }}
        >
          {props.infoIndex === index && (
            <InfoWindow onCloseClick={props.showInfo}>
              <DataMarker
                marker={marker}
                idconjunto={marker.idconjunto}
                showTable={() => props.showTable(marker.idconjunto)}
              />
            </InfoWindow>
          )}
        </Marker>
      ))}
    </MarkerClusterer>
  </GoogleMap>
));
export default MapWithAMarkerClusterer;
