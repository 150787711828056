import React from "react";
import TextInput from "../sharedComponents/TextInput/textInput";
import CurrencyInput from "../sharedComponents/CurrencyInput/currencyInput";
import { Field } from "formik";
import SelectComponent from "../sharedComponents/SelectComponent/SelectComponent";
import RadioSelector from "../sharedComponents/RadioSelector/RadioSelector.component";
import { isMobile } from "react-device-detect";

export const generateTextInput = ({ props, formikProps }) => {
  const { setFieldValue } = formikProps;
  const { onChange, name, style } = props;
  const inputStyle = isMobile ? { ...style, width: "50%" } : style;
  return (
    <div key={name} style={{ ...inputStyle }}>
      <TextInput
        {...props}
        {...formikProps}
        onChange={e => {
          if (onChange) {
            const res = onChange(e);
            if (res === "return") return;
          }
          setFieldValue(name, e.target.value);
        }}
      />
    </div>
  );
};

export const generateCurrencyInput = ({ props, formikProps }) => {
  const { style, name } = props;
  const inputStyle = isMobile ? { ...style, width: "50%" } : style;
  return (
    <div key={name} style={{ ...inputStyle }}>
      <CurrencyInput {...formikProps} {...props} />
    </div>
  );
};

export const generateSelectComponent = ({ props, formikProps }) => {
  const { style, name } = props;
  const inputStyle = isMobile ? { ...style, width: "50%" } : style;
  return (
    <div key={name} style={{ ...inputStyle }}>
      <Field component={SelectComponent} {...props} form={formikProps} />
    </div>
  );
};

export const generateRadioGroupComponent = ({ props, formikProps }) => {
  const { style, name } = props;
  const inputStyle = isMobile ? { ...style, width: "50%" } : style;
  return (
    <div key={name} style={{ ...inputStyle }}>
      <RadioSelector {...props} {...formikProps} />
    </div>
  );
};

export const generateForm = ({ inputData, formikProps }) =>
  inputData.map(input => {
    const textInputTypes = ["text", "number", "email"];
    if (textInputTypes.includes(input.type)) {
      return generateTextInput({ props: input, formikProps });
    }
    if (input.type === "currency") {
      return generateCurrencyInput({ props: input, formikProps });
    }
    if (input.type === "select") {
      return generateSelectComponent({ props: input, formikProps });
    }
    if (input.type === "radio") {
      return generateRadioGroupComponent({ props: input, formikProps });
    }
  });

const FormGenerator = ({ inputData, formikProps }) => (
  <>{generateForm({ inputData, formikProps })}</>
);

export default FormGenerator;
