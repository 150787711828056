import React, { useRef, useState } from "react";
import { Formik, Form } from "formik";

import CallAction from "../Call/call";
import { FiltersWrapper, FiltersContainer } from "./styleFilters";

import SearchIcon from "@material-ui/icons/Search";
import { DropdownButton, Button } from '@habitech/call-to-action';
import { TextField } from '@habitech/keychain-atoms-inputs';

const Filters = props => {
  const formRef = useRef(null);
  const [reset, setReset] = useState(false);

  const handleSearch = values => props.handleSearch(values);

  const initialValues = {
    habitaciones: "",
    presupuesto: "",
    area_metropolitana: "",
    garajes: "",
    ascensores: "",
    zona_grande: "",
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values, actions) => {
        if (reset) {
          actions.resetForm();
          handleSearch(initialValues);
          setReset(false);
          return;
        }
        handleSearch(values);
      }}
    >
      {({ values, errors, touched, setFieldValue, setFieldTouched }) => (
        <FiltersContainer>
          <Form ref={formRef} id="formFilter">
            <FiltersWrapper>
              <div className="field-20">
                <DropdownButton
                  dataId="test-id"
                  variant="white"
                  listOptions={[
                    { label: "Bogotá", value: 1 },
                    { label: "Valle de Aburrá", value: 2 },
                  ]}
                  setValue={(value) => {
                    {
                      setFieldValue("area_metropolitana", value);
                    }
                  }}
                  size="large fluid"
                  placeholder="Select.."
                  split
                  label="Area Metropolitana"
                  defaultSelected={values.area_metropolitana}
                />
              </div>
              <div className="field-15">
                <TextField
                  name="zona_grande"
                  id="zona_grande"
                  dataId="test-id"
                  label="Zona Grande"
                  onChange={(name, value, e) =>{
                    if (e.target.value < 0) return;
                    setFieldValue("zona_grande", e.target.value);
                  }}
                  value={values.zona_grande}
                  onBlur={() => {}}
                />
              </div>
              <div className="field-10">
                <TextField
                  name="habitaciones"
                  id="habitaciones"
                  dataId="test-id"
                  label="Habitaciones"
                  type="number"
                  value={values.habitaciones}
                  onChange={(name, value, e) =>{
                    if (e.target.value < 0) return;
                    setFieldValue("habitaciones", e.target.value);
                  }}
                  onBlur={() => {}}
                />
              </div>
              <div className="field-10">
                <TextField
                  name="garajes"
                  id="garajes"
                  dataId="test-id"
                  label="Garajes"
                  type="number"
                  value={values.garajes}
                  onChange={(name, value, e) =>{
                    if (e.target.value < 0) return;
                    setFieldValue("garajes", e.target.value);
                  }}
                  onBlur={() => {}}
                />
              </div>
              <div className="field-10">
                <TextField
                  name="ascensores"
                  id="ascensores"
                  dataId="test-id"
                  label="Ascensores"
                  type="number"
                  value={values.ascensores}
                  onChange={(name, value, e) =>{
                    if (e.target.value < 0) return;
                    setFieldValue("ascensores", e.target.value);
                  }}
                  onBlur={() => {}}
                />
              </div>
              <div className="field-20">
                <DropdownButton
                  dataId="test-id"
                  variant="white"
                  listOptions={[
                    { label: "Sin limite", value: "0" },
                    { label: "100 y 150 millones", value: "1" },
                    { label: "150 y 200 millones", value: "2" },
                    { label: "200 y 250 millones", value: "3" },
                    { label: "250 y 300 millones", value: "4" },
                    { label: "300 y 350 millones", value: "5" },
                  ]}
                  setValue={(value) => {
                    {
                      setFieldValue("presupuesto", value);
                    }
                  }}
                  size="large fluid"
                  placeholder="Select.."
                  split
                  label="Presupuesto"
                  defaultSelected={values.presupuesto}
                />
              </div>
              <div className="btn-container field-30">
                <Button
                  dataId="test-id"
                  size="medium"
                  type="submit"
                  variant="primary"
                  iconLeft={<SearchIcon />}
                >
                  Buscar
                </Button>
                <Button
                  dataId="test-id"
                  size="medium"
                  type="submit"
                  variant="stroke"
                  onClick={() => setReset(true)}
                >
                  Reset
                </Button>
                <CallAction user={props.user} />
              </div>
            </FiltersWrapper>
          </Form>
        </FiltersContainer>
      )}
    </Formik>
  );
};

export default Filters;
