import styled from "styled-components";
import {
  electricPurple800,
  robotoRegular,
  electricPurple200,
  monserratRegular,
  white,
} from "@habitech/shared";

export const ContainerCallCard = styled.div`
  //grid-column: 10 / 13;
  display: flex;
  flex-direction: column;
  max-height: 293px;
  width: 40%;
  background: ${electricPurple800};
  border-radius: 8px;
  padding: 24px 32px;
  box-sizing: border-box;
`;
export const NumberTitle = styled.div`
  font-family: ${robotoRegular};
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: ${electricPurple200};
  margin-bottom: 4px;
`;
export const PhoneNumberStatus = styled.div`
  font-family: ${monserratRegular};
  font-style: normal;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  display: flex;
  align-items: center;
  color: ${white};
`;
export const Line = styled.div`
  opacity: 0.5;
  border: 0.5px solid #ffffff;
  margin-bottom: 24px;
`;
export const Separator = styled.div`
  margin-bottom: 8px;
`;
export const SeparatorButtons = styled.div`
  margin-bottom: 16px;
`;
