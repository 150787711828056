import React from "react";
import PropTypes from "prop-types";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";

const headCells = [
  { id: "inmueble", numeric: true, disablePadding: false, label: "NID" },
  {
    id: "conjunto_edificio",
    numeric: false,
    disablePadding: true,
    label: "Conjunto",
    width: 130,
  },
  { id: "zona_grande", numeric: false, disablePadding: true, label: "Zona" },
  {
    id: "disponible_para",
    numeric: false,
    disablePadding: true,
    label: "Disponible para",
  },
  {
    id: "dias_en_venta",
    numeric: false,
    disablePadding: true,
    label: "Dias en venta",
  },
  { id: "num_habitaciones", numeric: true, disablePadding: true, label: "Hab" },
  { id: "banos", numeric: true, disablePadding: true, label: "Baños" },
  { id: "area", numeric: true, disablePadding: true, label: "Área" },
  { id: "num_piso", numeric: true, disablePadding: true, label: "Piso" },
  { id: "garajes", numeric: true, disablePadding: true, label: "Garajes" },
  {
    id: "ascensores",
    numeric: true,
    disablePadding: true,
    label: "Ascensores",
  },
  { id: "precio_venta", numeric: true, disablePadding: true, label: "Precio" },
  { id: "ficha", numeric: false, disablePadding: true, label: "Ficha" },
  { id: "poster", numeric: false, disablePadding: true, label: "Poster" },
];

const ListSalesPropertiesHead = props => {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.head}>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

ListSalesPropertiesHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default ListSalesPropertiesHead;
