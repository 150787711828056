import styled from "styled-components";
import { size } from "../../StylesConstants";

export const FormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: calc(100vw - 240px);
  margin: 0 auto;

  @media screen and (max-width: ${size.tablet}) {
    width: 100%;
  }
`;

export const FormTitle = styled.h1`
  font-family: "Muli Black";
  text-align: center;
`;

export const FormSectionLabel = styled.h2`
  margin-left: 120px;
  font-family: "Muli ExtraBold";

  @media screen and (max-width: ${size.tablet}) {
    margin-lett: unset;
  }
`;

export const ButtonContainer = styled.div`
  margin-left: 120px;
  margin-bottom: 40px;

  @media screen and (max-width: ${size.tablet}) {
    margin-lett: unset;
  }

  #guardar-button {
    width: 200px;
  }
`;
