import { getTokenTwilio } from "../services/financialToolServices";
const Device = require("twilio-client").Device;

export const getOtherToken = async () => {
  await getTokenTwilio()
    .then(response => {
      return response.data;
    })
    .then(async data => {
      await Device.setup(data.token, { debug: true });
    });
};

export const validatePhone = phoneNumber => {
  try {
    let specialChars = "!@#$^&%*()=-[]/{}|:<>?,.-_ ";
    for (var i = 0; i < specialChars.length; i++) {
      phoneNumber = phoneNumber
        .toString()
        .replace(new RegExp("\\" + specialChars[i], "gi"), "");
    }
    phoneNumber = phoneNumber.trim();
    if (phoneNumber !== "/^[0-9+]{10,13}$/") {
      let firstCharacter = phoneNumber.startsWith("3");
      if (firstCharacter && phoneNumber.length === 10) {
        phoneNumber = "+57" + phoneNumber;
      } else {
        let firstCharacter = phoneNumber.startsWith("7");
        if (firstCharacter && phoneNumber.length === 11) {
          phoneNumber = "+5" + phoneNumber;
        } else {
          let firstCharacter = phoneNumber.startsWith("5");
          if (firstCharacter && phoneNumber.length === 12) {
            phoneNumber = "+" + phoneNumber;
          }
        }
      }
      return phoneNumber;
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

export const callCustomer = data => {
  const number = validatePhone(data.phoneNumber);
  if (number) {
    const params = {
      to_phone: number,
      agent: data.email,
      nid: data.nid,
      role: data.role,
    };
    Device.connect(params);
  }
};

export const hangUp = async () => {
  await Device.disconnectAll();
  await Device.destroy();
};
