export const headColumnsZone = [
  {
    id: "nid",
    label: "NID",
    minWidth: 50,
    format: value => (value ? value : "N/A"),
  },
  {
    id: "area",
    label: "Área",
    minWidth: 50,
    format: value => (value ? value : "N/A"),
  },
  {
    id: "garajes",
    label: "Garajes",
    minWidth: 50,
    format: value => (value > 0 ? value : value === 0 ? value : "N/A"),
  },
  {
    id: "banos",
    label: "Baños",
    minWidth: 50,
    format: value => (value ? value : "N/A"),
  },
  {
    id: "num_piso",
    label: "Piso",
    minWidth: 50,
    format: value => (value ? value : "N/A"),
  },
  {
    id: "num_ascensores",
    label: "Ascensores",
    minWidth: 50,
    format: value => (value > 0 ? value : value === 0 ? value : "N/A"),
  },
  {
    id: "anos_antiguedad",
    label: "Antigüedad",
    minWidth: 100,
    format: value => (value ? value : "N/A"),
  },
  {
    id: "conjunto_edificio",
    label: "Nombre de conjunto",
    minWidth: 150,
    format: value => (value ? value : "N/A"),
  },
  {
    id: "fuente",
    label: "Fuente",
    minWidth: 100,
    format: value => (value ? value : "N/A"),
  },
  {
    id: "last_ask_price",
    label: "Ask Price",
    minWidth: 100,
    format: value =>
      value !== null ? "$ " + new Intl.NumberFormat().format(value) : "N/A",
  },
  {
    id: "valormt2",
    label: "Valor m2",
    minWidth: 100,
    format: value =>
      value !== null ? "$ " + new Intl.NumberFormat().format(value) : "N/A",
  },
  {
    id: "oportunidad_del_negocio",
    label: "Oportunidad de negocio",
    minWidth: 50,
    format: value => (value ? value : "N/A"),
  },
];
