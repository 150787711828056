const formDataReducer = (state = {}, action) => {
  switch (action.type) {
    case "UPDATE_DATA": {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

export default formDataReducer;
