import { twillioActiontypes } from "./twillioActionTypes";

export const startCall = () => ({
  type: twillioActiontypes.START_CALL,
  payload: {
    calling: true,
    counter: true,
  },
});

export const updateCallingStatus = status => ({
  type: twillioActiontypes.UPDATE_CALLING_STATUS,
  payload: status,
});

export const updateErrorStatus = status => ({
  type: twillioActiontypes.UPDATE_ERROR_STATUS,
  payload: status,
});

export const updateCounterStatus = status => ({
  type: twillioActiontypes.UPDATE_COUNTER_STATUS,
  payload: status,
});

export const clearCallData = () => ({
  type: twillioActiontypes.CLEAR_CALL_DATA,
});
