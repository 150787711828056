import React, { Fragment } from "react";
import FormGenerator from "../../components/FormGenerator/FormGenerator.component";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay.component";

import { Formik, Form, Field } from "formik";
import Button from "@habi/habi-react-components/dist/Button/button";

import {
  FormContainer,
  FormSectionLabel,
  FormTitle,
  ButtonContainer,
} from "./CRMEditForm.style";

const CRMEditFormPage = ({
  crmData,
  crmLead,
  initialValues,
  validationSchema,
  habiWebPost,
  formSections,
  loading,
}) => {

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={values => {
        habiWebPost({
          ...crmLead,
          ...values,
          tipo_cliente: crmData.tipo_cliente,
          ciudad: crmData.ciudad,
        });
      }}
    >
      {formikProps => (
        <Form>
          <FormTitle>
            Editar Detalles del negocio {crmData.nid} con{" "}
            {crmData.nombre_o_inmobiliaria}
          </FormTitle>
          <hr />
          {formSections.map(section => (
            <Fragment key={section.label}>
              <FormSectionLabel>{section.label}</FormSectionLabel>
              <FormContainer>
                <FormGenerator
                  inputData={section.inputs}
                  formikProps={formikProps}
                />
              </FormContainer>
              <hr />
            </Fragment>
          ))}
          <input type="hidden" name="agent_crm" value="" />
          <ButtonContainer>
            <Button
              id="guardar-button"
              type="submit"
              typeClass="primary"
              label="Guardar"
            />
          </ButtonContainer>
          {loading && <LoadingOverlay />}
        </Form>
      )}
    </Formik>
  );
}
export default CRMEditFormPage;
