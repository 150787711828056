import axios from "axios";
import globalInterceptor from "../utils/globalInterceptor";

const apikey = process.env.REACT_APP_HABI_API_KEY;
const rootUrl =
  process.env.REACT_APP_FORM_API_URL_V2 +
  process.env.REACT_APP_ENDPOINT_HABI_API_BASE_PATH;

const cognitoClient = axios.create({
  baseURL: rootUrl,
  headers: {
    "x-api-key": apikey,
  },
});

cognitoClient.interceptors.request.use(globalInterceptor);

const login = (googleId, external) => {
  return cognitoClient.post("get_cognito_user_role", {
    userId: googleId,
    external: +external,
    with_token: true,
  });
};

export default login;
