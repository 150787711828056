import React from "react";
import { useRef } from "react";
import useScript from "../../hooks/useScript";

const GoogleButton = ({ onGoogleSignIn, text = "signin_with" }) => {
  const googleSignInButton = useRef(null);

  useScript("https://accounts.google.com/gsi/client", () => {
    window.google.accounts.id.initialize({
      client_id:
        "1050153132365-2ucv8d21tdc2hotlbtesmup12lfki83t.apps.googleusercontent.com",
      callback: onGoogleSignIn,
    });
    window.google.accounts.id.renderButton(googleSignInButton.current, {
      theme: "outline",
      size: "large",
      text,
      width: 250,
    });
  });

  return <div className="google-auth" ref={googleSignInButton}></div>;
};

export default GoogleButton;
