export const headInfoLot = [
  {
    id: "nid",
    label: "NID",
    minWidth: 50,
    format: value => (value ? value : "N/A"),
  },
  {
    id: "range",
    label: "Rango",
    minWidth: 50,
    format: value => (value ? value : "N/A"),
  },
  {
    id: "area",
    label: "Área",
    minWidth: 50,
    format: value => (value ? value : "N/A"),
  },
  {
    id: "mt2_value",
    label: "Valor m2",
    minWidth: 100,
    format: value =>
      value !== null ? "$ " + new Intl.NumberFormat().format(value) : "N/A",
  },
  {
    id: "garage",
    label: "Garajes",
    minWidth: 50,
    format: value => (value > 0 ? value : value === 0 ? value : "N/A"),
  },
  {
    id: "bath",
    label: "Baños",
    minWidth: 50,
    format: value => (value ? value : "N/A"),
  },
  {
    id: "floor",
    label: "Piso",
    minWidth: 50,
    format: value => (value ? value : "N/A"),
  },
  {
    id: "room",
    label: "Habitaciones",
    minWidth: 50,
    format: value => (value ? value : "N/A"),
  },
  {
    id: "oportunidad_del_negocio",
    label: "Oportunidad del Negocio",
    minWidth: 50,
    format: value => (value ? value : "N/A"),
  },
];
