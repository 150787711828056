import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import { ModalAlertWrapper } from "./ModalAlert.style";

const ModalAlert = ({ open, toggle, message }) => {

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      className="modal-alert"
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <ModalAlertWrapper>
        <ModalHeader toggle={toggle}>
          <InfoRoundedIcon className="icon-info" />
        </ModalHeader>
        <ModalBody>
          <p className="text-info">{message ?? 'No existe un polígono disponible'}</p>
        </ModalBody>
      </ModalAlertWrapper>
    </Modal>
  );
};

export default ModalAlert;
