import React, { Fragment, useState, useEffect } from "react";

import { Tabs, Tab } from "react-bootstrap";
import { connect } from "react-redux";
import { updateData } from "../../redux/actions/formData.actions";
// coponentes
import Header from "../HeaderSmall/header";
import Simulador from "../Simulador/simulador";
import ListSalesPropertiesContainer from "../ListSalesProperties/listSalesPropertiesContainer";
import Filter from "../Filters/filters";
import MapContainer from "../MapContainer/mapContainer";
import ListSellersContainer from "../SellersCall/listSellersContainer";
// pages
import CRMPage from "../../pages/CRMPage/CRMPage";
import CallCenterPostFormContainer from "../../pages/CallCenterPostFormPage/CallCenterPost.container";
// styles
import { DashboardWrapper } from "./style";

const Dashboard = props => {
  const [key, setKey] = useState("inmuebles");
  const [filters, setFilters] = useState(null);

  useEffect(() => {
    props.updateData({
      selectedTab: key,
    });
    if (props.conjunto || props.nid) {
      setKey("lotes");
      props.updateData({
        selectedTab: "lotes",
      });
    }
  }, [key, props]);

  const handleSearch = fields => {
    setFilters({ ...fields });
  };

  const getTab = k => {
    props.updateData({
      selectedTab: k,
    });
  };

  return (
    <Fragment>
      <Header />
      <DashboardWrapper>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={k => {
            setKey(k);
            getTab(k);
          }}
        >
          <Tab eventKey="inmuebles" title="Inmuebles">
            <Filter handleSearch={handleSearch} user={props.email} />
            <ListSalesPropertiesContainer filters={filters} />
          </Tab>
          <Tab eventKey="simulador" title="Simulador">
            <Simulador />
          </Tab>
          <Tab eventKey="comparativo" title="Comparativo">
            <MapContainer type={"comparativo"} />
          </Tab>
          <Tab eventKey="lotes" title="Lotes">
            <MapContainer type={"lotes"} />
          </Tab>
          {/* {props.role === "ventas" && (
            <Tab eventKey="sellers" title="Sellers">
              <ListSellersContainer />
            </Tab>
          )} */}
          {props.role === "marketing" && (
            <Tab eventKey="crm" title="CRM">
              <CRMPage />
            </Tab>
          )}
          {/* {props.role === "callcenter" ||
            (props.role === "ventas" && (
              <Tab eventKey="newRegistration" title="Nuevo Registro">
                <CallCenterPostFormContainer />
              </Tab>
            ))} */}
        </Tabs>
      </DashboardWrapper>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  conjunto: state.formData.conjunto,
  nid: state.formData.conjunto,
  selectedTab: state.formData.selectedTab,
  email: state.formData.email,
  role: state.formData.role,
});

const mapDispatchToProps = dispatch => ({
  updateData: data => dispatch(updateData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
