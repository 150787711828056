import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import MapImage from "../MapImage/MapImage";

const PolygonModal = props => {
  const { open, toggle, longitude, latitude } = props;

  return (
    <div>
      <Modal
        isOpen={open}
        toggle={toggle}
        className="modal-container"
        style={{
          width: "calc(100vw - 260px)",
          height: "calc(100vh - 260px)",
          margin: 0,
        }}
      >
        <ModalHeader toggle={toggle}>Imagen del Polígono</ModalHeader>
        <ModalBody>
          <div
            style={{
              width: "calc(100vw - 260px)",
              height: "calc(100vh - 260px)",
            }}
          >
            <MapImage longitude={longitude} latitude={latitude} />
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default PolygonModal;
