import React, { useState, useEffect } from 'react'
//
import { useSelector } from "react-redux";
// utils
import { getAllComments, putNote } from "./Comments.utils";
// components
import AllComments from './components/AllComments';
import InputComments from './components/InputComments';
import ModalAlert from '../ModalAlert/ModalAlert'
// styles
import { Wraap } from "./Comments.style";

const Comments = ({ nid, number }) => {
    const [toggleModal, setToglleModal] = useState(false)
    const [textModal, setTextModal] = useState('')
    const [allcomments, setAllComments] = useState([])
    const { formData } = useSelector(state => state);


    useEffect(() => {
        (async () => {
            try {
                const { data, success, message } = await getAllComments(nid)
                if (success) {
                    if (data?.length) setAllComments([...allcomments, ...data])
                } else throw new Error(message);
            } catch (error) {
                setTextModal(error?.message);
                setToglleModal(!toggleModal);
            }
        })();
    }, [])

    const addComment = async note => {
        const date = new Date();
        const formatDate = date.getFullYear() + "-" + (date.getMonth() +1) + "-" + date.getDate();
        try {
            const { success, message } = await putNote(note, nid, formData?.email, number)

            if (success) {
                await setAllComments([
                    ...allcomments,
                    {
                        note,
                        name: formData?.email,
                        date_on: formatDate,
                    }
                ])
            } else {
                throw new Error(message);
            }

        } catch (error) {
            setTextModal(error?.message);
            setToglleModal(!toggleModal);
        }

    } 


    return (
        <Wraap>
            <AllComments
                allComments={allcomments}
            />
            <InputComments
                addComment={addComment}
            />
            <ModalAlert
                open={toggleModal}
                message={textModal}
                toggle={() => setToglleModal(!toggleModal)}
            />
        </Wraap>
    )
}

export default Comments
