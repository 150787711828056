import styled from "styled-components";

export const DataMarkerConjuntoWrapper = styled.section`
  display: grid;
  .row-data-marker {
    display: grid;
    grid-template: 1fr / 1fr 1fr;
    a {
      text-decoration: underline;
    }
    a:hover {
      cursor: pointer;
    }
  }
`;
