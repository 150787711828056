import React, { useEffect, useState, forwardRef } from "react";
import { LargePic, PosterWrapper, SmallPic } from "./style";
import Bed from "../../static/assets/icons/hotel-white-18dp.svg";
import Shower from "../../static/assets/icons/bathtub-white-18dp.svg";
import Stratus from "../../static/assets/icons/dashboard-white-18dp.svg";
import Area from "../../static/assets/icons/crop_free-white-18dp.svg";
import Car from "../../static/assets/icons/directions_car-white-18dp.svg";

const Poster = forwardRef((props, ref) => {
  const { property, convert } = props;
  const [image1, setImage1] = useState("");
  const [image2, setImage2] = useState("");
  const [image3, setImage3] = useState("");

  useEffect(() => {
    const bucketUrl = process.env.REACT_APP_BUCKET_IMG;
    let urlImage1 = "";
    let urlImage2 = "";
    let urlImage3 = "";

    if (property.images) {
      let newImage = property.images.split(",");
      
      if (newImage[0]) {
        urlImage1 = bucketUrl + newImage[0].split(".").join("-765.");
        convert(urlImage1, setImage1);
      }
      if (newImage[1]) {
        urlImage2 = bucketUrl + newImage[1].split(".").join("-765.");
        convert(urlImage2, setImage2);
      }
      if (newImage[2]) {
        urlImage3 = bucketUrl + newImage[2].split(".").join("-765.");
        convert(urlImage3, setImage3);
      }
    }
  }, [property, convert]);

  return (
    <PosterWrapper>
      {property && (
        <div className="poster-container" ref={ref}>
          <div className="image-container">
            <div className="pic-large">
              <LargePic src={image1} />
            </div>
            <div className="container-pic-small">
              <SmallPic src={image2} />
              <SmallPic src={image3} />
            </div>
          </div>
          <div className="text-container">
            <h2 className="title">Para vivir o invertir</h2>
            <h4 className="subtitle">No recibimos subsidios</h4>
            <h2 className="price">
              {"$ " + new Intl.NumberFormat().format(property.precio_venta)}
            </h2>
            <p className="text">
              Apartamento remodelado en
              <br />
              <span className="sub-text">
                {property.zona_mediana}, {property.zona_grande}.
              </span>
            </p>
            <div className="amenities">
              <span className="text-amenities">
                {property.habitaciones}
                <img src={Bed} alt="bed" className="icon" />
                <br /> Habitaciones
              </span>
              <span className="text-amenities">
                {property.banos}
                <img src={Shower} alt="shower" className="icon" />
                <br />
                Baños
              </span>
              <span className="text-amenities">
                {property.estrato}
                <img src={Stratus} alt="startus" className="icon" />
                <br /> Estrato
              </span>
              <span className="text-amenities">
                {property.area}
                <img src={Area} alt="startus" className="icon" />
                <br />
                Área
              </span>
              <span className="text-amenities">
                {property.garajes}
                <img src={Car} alt="startus" className="icon" />
                <br /> Parqueadero
              </span>
            </div>
          </div>
        </div>
      )}
    </PosterWrapper>
  );
});

export default Poster;
