import styled from "styled-components";
import {
  archiaRegular,
  bogotaBlue100,
  madangGreen600,
  madangGreen700,
  nightBlue000,
  scarpaGray100,
  scarpaGray500,
  scarpaGray600,
  tomatoCoral300,
  tomatoCoral500,
  skyBlue300
} from "../../StylesConstants";

export const SellersCallableWrapper = styled.div`
  .table-container {
    font-family: ${archiaRegular};
    padding: 0 24px;
    margin-bottom: 40px;

    table {
      border-bottom: 1px solid ${scarpaGray100};
    }

    thead {
      background-color: ${scarpaGray100};
      font-weight: bold;
      font-size: 13px;
      color: ${scarpaGray500};

      th {
        vertical-align: middle;
        padding: 16px;

        &.dateCreation {
          width: 170px;
        }
      }
    }

    tbody {
      font-size: 13px;
      color: ${scarpaGray600};

      tr {
        height: 64px;

        &:hover {
          background-color: ${bogotaBlue100};
        }

        th {
          font-weight: normal;
          vertical-align: middle;
          padding: 0 16px;
        }

        td {
          font-weight: normal;
          vertical-align: middle;
          padding: 0 16px;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .table-striped tbody tr:nth-of-type(odd) {
      background-color: #ffffff;
    }

    .table td,
    .table th {
      border-top: none;
    }
  }
`;

export const LoaderSection = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledButton = styled.div`
  cursor: pointer;
  width: 38px;
  height: 38px;
  border: 2px solid
    ${props =>
      props.typeButton === "primary" ? madangGreen700 : tomatoCoral500};
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  background-color: ${props =>
    props.callActive && props.typeButton === "primary"
      ? madangGreen700
      : nightBlue000};
  &:hover {
    color: ${nightBlue000};
    background-color: ${props =>
      props.typeButton === "primary" ? madangGreen600 : tomatoCoral300};
  }
  &:active {
    color: ${nightBlue000};
    background-color: ${props =>
      props.typeButton === "primary" ? madangGreen600 : tomatoCoral300};
  }
  .primary {
    color: ${props => (props.callActive ? nightBlue000 : madangGreen700)};
    &:hover {
      color: ${nightBlue000};
    }
  }
  .secondary {
    color: ${tomatoCoral500};
    &:hover {
      color: ${nightBlue000};
    }
  }
`;

export const StyledButtonGroup = styled.div`
  display: flex;
`;

export const HeadWrapper = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: center;
`;
