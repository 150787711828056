import moment from "moment";

export const headColumnsPricing = [
  {
    id: "nid",
    label: "NID",
    minWidth: 50,
    format: value => (value ? value : "N/A"),
  },
  {
    id: "area",
    label: "Área",
    minWidth: 50,
    format: value => (value ? value : "N/A"),
  },
  {
    id: "garajes",
    label: "Garajes",
    minWidth: 50,
    format: value => (value > 0 ? value : value === 0 ? value : "N/A"),
  },
  {
    id: "banos",
    label: "Baños",
    minWidth: 50,
    format: value => (value ? value : "N/A"),
  },
  {
    id: "piso",
    label: "Piso",
    minWidth: 50,
    format: value => (value ? value : "N/A"),
  },
  {
    id: "ascensores",
    label: "Ascensores",
    minWidth: 50,
    format: value => (value > 0 ? value : value === 0 ? value : "N/A"),
  },
  {
    id: "fuente",
    label: "Fuente",
    minWidth: 100,
    format: value => (value ? value : "N/A"),
  },
  {
    id: "ask_price",
    label: "Ask Price",
    minWidth: 100,
    format: value =>
      value !== null ? "$ " + new Intl.NumberFormat().format(value) : "0",
  },
  {
    id: "precio_manual",
    label: "Precio Manual",
    minWidth: 100,
    format: value =>
      value !== null ? "$ " + new Intl.NumberFormat().format(value) : "0",
  },
  {
    id: "precioManualComite",
    label: "Precio manual comité",
    minWidth: 100,
    format: value =>
      value ? "$ " + new Intl.NumberFormat().format(value) : "0",
  },
  {
    id: "fechaPrecioManual",
    label: "Fecha precio manual",
    minWidth: 100,
    format: value => (value ? moment(value).format("DD-MM-YYYY HH:mm") : "0"),
  },
  {
    id: "precioFinalDeCierre",
    label: "Precio final del cierre",
    minWidth: 100,
    format: value =>
      value ? "$ " + new Intl.NumberFormat().format(value) : "0",
  },
  {
    id: "oportunidad_del_negocio",
    label: "Oportunidad de negocio",
    minWidth: 100,
    format: value => (value ? value : "N/A"),
  },
];
