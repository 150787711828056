import React, {useState} from "react";
import { connect } from "react-redux";
import Overlay from "../../components/Overlay/overlay";
import CRMDetailsListContainer from "../../components/CRMDetailsList/CRMDetailsList.container";
import InfoCallContainer from "../../components/InfoCall/infoCallContainer";
import CRMEditFormContainer from "../CRMEditFormPage/CRMEditForm.container";

import { PageTitle, PhoneContainer } from "./CRMDetailsPage.style";
import { selectHasFormData } from "../../redux/selectors/crmData.selectors";
import { addCrmDataAction } from "../../redux/actions/crmData.actions";
import { WhiteOverlay } from "../../components/LoadingOverlay/LoadingOverlay.style";
import { Button } from "react-bootstrap";

const CRMDetailsPage = ({ crmLead, hasFormData, addCrmData, clearCrmLead }) => {
  const [isSave, setIsSave] = useState(0);

  return (
    <>
      <PageTitle>Negocios con {crmLead.nombre_o_inmobiliaria}</PageTitle>
      <CRMDetailsListContainer />
      <PhoneContainer>
        <InfoCallContainer
          phone={crmLead.telefono}
          nid={crmLead.nidParaLlamada}
        />
      </PhoneContainer>
      <Overlay
        openLeftModal={hasFormData}
        dispatch={() => addCrmData({ nid: null })}
      >
        {hasFormData && <CRMEditFormContainer isSave={isSave} setIsSave={setIsSave} />}
      </Overlay>
      {isSave == 2 && (
        <WhiteOverlay>
          <h2>No se pudo guardar la información, intente nuevamente.  :(</h2>
          <Button onClick={() => {setIsSave(0); clearCrmLead();}}>Cerrar</Button>
        </WhiteOverlay>
      )}
      {isSave == 1 && (
        <WhiteOverlay>
          <h2>La información se guardo correctamente.  :)</h2>
          <Button onClick={() => {setIsSave(0); clearCrmLead();}}>Cerrar</Button>
        </WhiteOverlay>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  crmLead: state.crmLead,
  hasFormData: selectHasFormData(state),
});

const mapDispatchToProps = dispatch => ({
  addCrmData: value => dispatch(addCrmDataAction(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CRMDetailsPage);
