import styled from "styled-components";
import {
  scarpaGray200,
  electricPurple600,
  scarpaGray100,
  archiaRegular,
  scarpaGray600,
  scarpaGray300,
} from "../../StylesConstants";

export const SearchWrapper = styled.div`
  padding: 6px;
  display: grid;
  grid-template-columns: 20px 140px 57px;
  border: 1px solid ${scarpaGray200};
  border-radius: 8px;
  height: 40px;
  width: 229px;
  align-items: center;

  .icon {
    color: ${electricPurple600};
  }

  input {
    outline: none;
    border-left: 1px solid ${scarpaGray100};
    border-top: none;
    border-right: none;
    border-bottom: none;
    font-size: 16px;
    margin: 0px 0px 0px 11px;
    padding: 0px 0px 0px 8px;

    &::placeholder {
      color: ${scarpaGray300};
    }

    /* Hide Arrows in Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Hide Arrows in Firefox */
    & [type="number"] {
      -moz-appearance: textfield;
    }
  }

  button {
    background: none;
    border: none;
    outline: none;

    span {
      font-size: 15px;
      font-family: ${archiaRegular};
      color: ${scarpaGray600};
      text-decoration: underline;
    }
  }
`;
