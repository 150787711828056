export const crmHeadCells = [
  {
    value: "nombre_o_inmobiliaria",
    label: "Nombre",
  },
  {
    value: "correo",
    label: "Correo",
  },
  {
    value: "telefono",
    label: "Telefono",
  },
  {
    value: "consultar",
    label: "",
  },
];
