import React from "react";
import { DataMarkerComparativoWrapper } from "./style";

const MarkerComparativo = ({ dataConjunto, showTable, idconjunto }) => (
  <DataMarkerComparativoWrapper>
    <div className="row-data-marker">
      <div>
        <p>Nombre del conjunto</p>
      </div>
      <div>
        <p>{dataConjunto.proyecto}</p>
      </div>
    </div>
    <div className="row-data-marker">
      <div>
        <p>Direccion</p>
      </div>
      <div>
        <p>{dataConjunto.direccion}</p>
      </div>
    </div>
    <div className="row-data-marker">
      <div>
        <p>Numero total de inmuebles</p>
      </div>
      <div>
        <p>{dataConjunto.AP}</p>
      </div>
    </div>
    <div className="row-data-marker">
      <div>
        <p>Inmuebles a la venta</p>
      </div>
      <div>
        <p>{dataConjunto.CA}</p>
      </div>
    </div>
    <div className="row-data-marker">
      <div>
        <p>Estrato</p>
      </div>
      <div>
        <p>{dataConjunto.estrato}</p>
      </div>
    </div>
    <div className="row-data-marker">
      <div>
        <p>Antigüedad</p>
      </div>
      <div>
        <p>{dataConjunto.antiguedadmax}</p>
      </div>
    </div>
    <div className="row-data-marker">
      <div>
        <p>Valor promedio</p>
      </div>
      <div>
        <p>{dataConjunto.promedio_valor_total}</p>
      </div>
    </div>
    <div className="row-data-marker">
      <div>
        <p>Valor promedio M2</p>
      </div>
      <div>
        <p>{dataConjunto.precio_por_m2_promedio}</p>
      </div>
    </div>
    <div className="row-data-marker">
      <div>
        <p>Rango Area</p>
      </div>
      <div>
        <p>{`${dataConjunto.area_minima} - ${dataConjunto.area_maxima}`}</p>
      </div>
    </div>
    <div className="row-data-marker">
      <div>
        <p>Rango Habitaciones</p>
      </div>
      <div>
        <p>{`${dataConjunto.habitaciones_minimo} - ${dataConjunto.habitaciones_minimo}`}</p>
      </div>
    </div>
    <div className="row-data-marker">
      <div>
        <p>Ascensores</p>
      </div>
      <div>
        <p>{dataConjunto.numero_ascensores}</p>
      </div>
    </div>
    <div className="row-data-marker">
      <div>
        <a onClick={() => showTable(idconjunto)}>Ver detalles..</a>
      </div>
      <div></div>
    </div>
  </DataMarkerComparativoWrapper>
);

export default MarkerComparativo;
