import React, { useState, useEffect } from "react";
import { ErrorMessage } from "formik";
import {
  ErrorMsg,
  OptionsContainer,
  InputContainer,
  OptionDiv,
} from "./RadioSelector.style";
import { disabled } from "../../../StylesConstants";

const RadioSelector = ({
  label,
  options,
  name,
  setFieldValue,
  setFieldTouched,
  values,
  errors,
  touched,
  width,
  disabled,
  ...props
}) => {
  const [selected, setSelected] = useState(props.default);

  useEffect(() => {
    setSelected(values[name]);
  }, [setSelected, values, name]);

  return (
    <InputContainer>
      <label>{label}</label>
      <OptionsContainer>
        {options.map((option, i) => {
          const isSelected = selected === option.value;
          const isFirst = i === 0;
          const isLast = i === options.length - 1;
          const classString = `${isSelected && "selected"} ${isFirst &&
            "first-item"} ${isLast && "last-item"} ${width && "large"} ${disabled && "disabled"}`;
          return (
            <OptionDiv
              onClick={() => {
                if (!disabled) {
                  setFieldTouched(name, true);
                  setFieldValue(name, option.value);
                }
              }}
              className={classString}
            >
              {option.label}
            </OptionDiv>
          );
        })}
      </OptionsContainer>
      <ErrorMessage name={name}>
        {msg => <ErrorMsg>{msg}</ErrorMsg>}
      </ErrorMessage>
    </InputContainer>
  );
};

export default RadioSelector;
