import React, { useEffect, useRef } from "react";
import Comment from "./Comment";

const AllComments = ({ allComments }) => {
  const containerComments = useRef();
  const delay = 1;

  useEffect(() => {
    containerComments.current.scrollTo = containerComments.current.scrollHeight;
  }, [allComments]);

  return (
    <div id="Allcomments" ref={containerComments}>
      {allComments.map((comment, index) => {
        return <Comment comment={comment} delay={delay + index} key={index} />;
      })}
    </div>
  );
};

export default AllComments;
