import styled from "styled-components";
import {
  grey,
  scarpaGray100,
  electricPurple500,
  white,
  error,
} from "../../../StylesConstants";

export const InputContainer = styled.div`
  margin-left: 15px;
`;

export const ErrorMsg = styled.div`
  color: ${error};
`;

export const OptionsContainer = styled.div`
  display: flex;
  overflow: hidden;
  width: fit-content;
  cursor: pointer;
`;

export const OptionDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  border: 1px solid ${grey};
  height: 38px;
  border-right: unset;

  &:hover {
    background-color: ${scarpaGray100};
  }

  &.first-item {
    border-radius: 8px 0 0 8px;
  }

  &.last-item {
    border: 1px solid ${grey};
    border-radius: 0 8px 8px 0;
  }

  &.selected {
    background-color: ${electricPurple500};
    border-color: ${electricPurple500};
    color: ${white};
    font-family: "Muli Black";
  }

  &.large {
    width: 150px;
  }

  &.disabled {
    cursor: not-allowed;
  }
`;
