import React, { useState } from "react";
import { CalculatorAndServiceWrapper } from "./style";
import { Collapse, CardBody, Card } from "reactstrap";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import LoanCalculatorContainer from "../LoanCalculator/loanCalculatorContainer";
import MoneyBorrowCalculatorContainer from "../MoneyBorrowCalculator/moneyBorrowCalculatorContainer";
import Requirements from "../Requirements/requirements";

const CreditCalculatorContainer = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenExpenses, setIsOpenExpenses] = useState(false);
  const [isOpenRequirements, setIsOpenRequirements] = useState(false);
  const [isOpenUsed, setIsOpenUsed] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const toggleExpenses = () => setIsOpenExpenses(!isOpenExpenses);
  const toggleRequirements = () => setIsOpenRequirements(!isOpenRequirements);
  const toggleUsed = () => setIsOpenUsed(!isOpenUsed);

  return (
    <CalculatorAndServiceWrapper ref={props.refCal}>
      <div className="container-services" id="container-services">
        <div className="calculator-side" ref={props.referenceCalculator}>
          <div className="calculadora-mob">
            <div color="primary" onClick={toggle} className="collapsed-items">
              <div className="name">Cuál sería mi cuota mensual</div>
              <div className="icon">
                {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </div>
            </div>
            <Collapse isOpen={isOpen}>
              <Card style={{ border: "none" }}>
                <CardBody style={{ padding: "0" }}>
                  <LoanCalculatorContainer />
                </CardBody>
              </Card>
            </Collapse>
          </div>
        </div>
        <div className="calculator-side" ref={props.referenceCalculator}>
          <div className="calculadora-mob">
            <div
              color="primary"
              onClick={toggleExpenses}
              className="collapsed-items"
            >
              <div className="name">Cuánto me pueden prestar</div>
              <div className="icon">
                {isOpenExpenses ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </div>
            </div>
            <Collapse isOpen={isOpenExpenses}>
              <Card style={{ border: "none" }}>
                <CardBody style={{ padding: "0" }}>
                  <MoneyBorrowCalculatorContainer />
                </CardBody>
              </Card>
            </Collapse>
          </div>
        </div>
        <div className="calculator-side" ref={props.referenceCalculator}>
          <div className="calculadora-mob">
            <div
              color="primary"
              onClick={toggleRequirements}
              className="collapsed-items"
            >
              <div className="name">Requisitos</div>
              <div className="icon">
                {isOpenRequirements ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </div>
            </div>
            <Collapse isOpen={isOpenRequirements}>
              <Card style={{ border: "none" }}>
                <CardBody style={{ padding: "0" }}>
                  <Requirements />
                </CardBody>
              </Card>
            </Collapse>
          </div>
        </div>
      </div>
    </CalculatorAndServiceWrapper>
  );
};

export default CreditCalculatorContainer;
