import React, { Fragment, useState, useEffect } from "react";
import {
  ListTableWrapper,
  BlueList,
  RedList,
  YellowList,
  GreenList,
  AlertModal,
} from "./styles";
import Button from "../sharedComponents/ButtonV2/Button";
import AttachMoneyRoundedIcon from "@material-ui/icons/AttachMoneyRounded";
import WallpaperRoundedIcon from "@material-ui/icons/WallpaperRounded";
import { useSelector } from "react-redux";
import PolygonModal from "../PolygonModal/PolygonModal";
import ModalAlert from "../ModalAlert/ModalAlert";
import typeListButtons from "./utils";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Alert from "@material-ui/lab/Alert";

const ComparableCardInfo = ({ countDataHeaders, apartmentData, ...props }) => {
  const { formData, polygon } = useSelector(state => state);
  const [open, setOpen] = useState(false);
  const [showModalAlert, setShowModalAlert] = useState(true)
  const urlHubspot = process.env.REACT_APP_HUBSPOT_LINK;
  let color;
  let severity;

  useEffect(
    () => {
      let timer1 = setTimeout(() => setShowModalAlert(false), 3000)
      return () => {
        clearTimeout(timer1)
      }
    },
    [showModalAlert]
  )

  const openLeftModal = () => {
    setOpen(true);
  };

  const closeLeftModal = () => {
    setOpen(false);
  };

  const userData = {
    name: formData.name,
    lastName: formData.lastName,
    email: formData.email,
    role: formData.role,
    authenticated: formData.authenticated,
    imageProfile: formData.imageProfile,
  };

  const list = typeList => {
    switch (typeList) {
      case "G":
        severity = "warning";
        color = "yellow";
        return <YellowList>{typeListButtons.yellow.title}</YellowList>;
      case "W":
        severity = "success";
        color = "green";
        return <GreenList>{typeListButtons.green.title}</GreenList>;
      case "B":
        severity = "error";
        color = "red";
          return <RedList>{typeListButtons.red.title}.</RedList>;
      default:
        severity = "info";
        color = "blue";
        return <BlueList>{typeListButtons.blue.title}.</BlueList>;
    }
  };

  return (
    <Fragment>
      <ListTableWrapper>
        <div className="table-container">
          <table className="table">
            <thead>
              <tr>
                {countDataHeaders.map((item, idx) => {
                  if (item === "Fecha de Creación") {
                    return <th className="dateCreation">{item}</th>;
                  }
                  return <th key={idx}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              <tr key={apartmentData.inmueble_id}>
                <th>
                  <a href={`${urlHubspot}${apartmentData.nid}`} target="_blank">
                    {apartmentData.nid}
                  </a>
                </th>
                <th>{apartmentData.apartamento_venta}</th>
                <th>{apartmentData.apartamento_venta_area}</th>
                <th>{apartmentData.apartamento_venta_zona}</th>
                <th>
                  {apartmentData.valor_promedio !== null
                    ? "$ " +
                      Intl.NumberFormat().format(
                        Math.ceil(apartmentData.valor_promedio)
                      )
                    : "0"}
                </th>
                <th>
                  {apartmentData.valor_minimo !== null
                    ? "$ " +
                      Intl.NumberFormat().format(
                        Math.ceil(apartmentData.valor_minimo)
                      )
                    : "0"}
                </th>
                <th>
                  {apartmentData.precio_manual !== null
                    ? "$ " +
                      new Intl.NumberFormat().format(
                        apartmentData.precio_manual
                      )
                    : "0"}{" "}
                  -
                  {apartmentData.precio_manual !== null
                    ? "$ " +
                      new Intl.NumberFormat().format(
                        apartmentData.max_precio_manual
                      )
                    : "0"}
                </th>
                <th>
                  {apartmentData.dif_precios !== null
                    ? "$ " +
                      new Intl.NumberFormat().format(apartmentData.dif_precios)
                    : "0"}
                </th>
                <th>{apartmentData.oportunidad_del_negocio || "N/A"}</th>

                {list(apartmentData.blacklist_str)}

                <th>
                  <Button
                    label="Price"
                    size="small"
                    svgIcon={<AttachMoneyRoundedIcon />}
                    onClick={() =>
                      window.open(
                        `${
                          process.env.REACT_APP_PRICING_TOOL_PATH
                        }?${new URLSearchParams({
                          data: JSON.stringify(userData),
                          nid: apartmentData.nid,
                        }).toString()}`,
                        "_blank"
                      )
                    }
                  />
                </th>
                <th>
                  <Button
                    label="Polígono"
                    size="small"
                    svgIcon={<WallpaperRoundedIcon />}
                    onClick={() => openLeftModal()}
                  />
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </ListTableWrapper>
      {polygon.length > 0 ? (
        <PolygonModal
          open={open}
          toggle={closeLeftModal}
          longitude={apartmentData.longitud}
          latitude={apartmentData.latitud}
        />
      ) : (
        <ModalAlert open={open} toggle={closeLeftModal} />
      )}
      {showModalAlert &&
        <AlertModal>
          <Alert severity={severity} className="alert">
            <AlertTitle>{typeListButtons[color].title}</AlertTitle>
            <p>{typeListButtons[color].message}</p>
          </Alert>
        </AlertModal>
      }
    </Fragment>
  );
};

export default ComparableCardInfo;
