import React from "react";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Alert from "@material-ui/lab/Alert";

export default function AlertNumberError() {
  return (
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      <p>{`Este número es incorrecto o duplicado, `}</p>
      <strong>verifica que tenga 10 digítos</strong>
    </Alert>
  );
}
