import React, { useEffect } from "react";
import { Call, Close } from "@material-ui/icons";
import { CallTimeWrapper } from "./CallTimeCard.style";
import { useDispatch, useSelector } from "react-redux";
import { updateCounterStatus } from "../../redux/twillio/twillio.actions";
import { useState } from "react";

const CallTimeCard = ({ contactName }) => {
  const dispatch = useDispatch();
  const [time, setTime] = useState({ seconds: 0, minutes: 0 });
  const { counter } = useSelector(state => state.twillio);
  useEffect(() => {
    let run = null;
    if (counter && time.minutes < 100) {
      run = setInterval(() => {
        if (time.seconds > 59) {
          setTime(prev => ({ seconds: 0, minutes: prev.minutes + 1 }));
        } else {
          setTime(prev => ({
            minutes: prev.minutes,
            seconds: prev.seconds + 1,
          }));
        }
      }, 1000);
    }

    return () => {
      clearInterval(run);
    };
  }, [time, counter]);

  const hideCounter = () => dispatch(updateCounterStatus(false));

  return (
    <CallTimeWrapper>
      <div className="close" onClick={hideCounter}>
        <Close fontSize="small" />
      </div>
      <span className="iconWrapper">
        <Call className="callIcon" />
      </span>
      <div className="textWrapper">
        <p className="title">Estás hablando con</p>
        <p className="information">{contactName}</p>
        <p className="time">{`${time.minutes}:${time.seconds < 10 ? "0" : ""}${
          time.seconds
        }`}</p>
      </div>
    </CallTimeWrapper>
  );
};

export default CallTimeCard;
