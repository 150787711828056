import styled, { css } from "styled-components";
import { size } from "../../StylesConstants";

export const FiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  width: 100vw;
  height: 100%;

  .btn-container {
    display: flex;
    margin: 15px;
    align-items: flex-end;
    justify-content: center;
    gap: 0.5rem;

    div {
      padding: 0 2px;
    }
  }

  @media screen and (min-width: ${size.tablet}) {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 0 24px;
  }

  @media screen and (min-width: ${size.desktop}) {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 0 24px;
  }

  .btn-container {
    display: flex;
    margin: 15px;
    align-items: flex-end;
    justify-content: center;

    div {
      padding: 0 4px;
    }

    button {
      width: 120px;
      height: 40px;
      padding: 0px 16px;
    }
  }

  /* label {
    margin-left: 10%;
  } */

  .input-filter {
    input {
      margin-top: 3px;
    }
  }
`;

const responsiveFlexItem = css`
  width: 100vw;
  margin-top: 24px;

  @media screen and (min-width: ${size.tablet}) {
    width: 50%;
  }
`;

export const FiltersContainer = styled.section`
  @media screen and (min-width: ${size.desktop}) {
    background-color: white;
    top: 10%;
    width: 100%;
    height: 17%;
    left: 25%;
    border-radius: 80px;
    justify-items: center;
  }

  @media screen and (min-width: 1600px) {
    background-color: white;
    width: 100%;
    height: 15%;
    border-radius: 80px;
    justify-items: center;
  }

  .field-10 {
    ${responsiveFlexItem}
    padding: 0 0.5rem;
    @media screen and (min-width: ${size.desktop}) {
      width: 10%;
      margin-top: 24px;
    }
  }

  .button-llamar {
    display: grid;
    align-items: flex-end;
  }

  .field-15 {
    ${responsiveFlexItem}
    padding: 0 0.5rem;
    @media screen and (min-width: ${size.desktop}) {
      width: 15%;
      margin-top: 24px;
    }
  }

  .field-20 {
    ${responsiveFlexItem}
    padding: 0 0.5rem;
    >div{
      width: auto;
    }
    @media screen and (min-width: ${size.desktop}) {
      width: 20%;
      margin-top: 24px;
    }
  }

  .field-25 {
    ${responsiveFlexItem}
    @media screen and (min-width: ${size.desktop}) {
      width: 25%;
      margin-top: 24px;
    }
  }

  .field-30 {
    ${responsiveFlexItem}
    @media screen and (min-width: ${size.desktop}) {
      width: 30%;
      margin-top: 24px;
    }
  }
`;
