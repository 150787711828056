import React, { useEffect, useState } from "react";
import "react-block-ui/style.css";
import { connect } from "react-redux";
import ListSellersCallable from "./listSellersCallable";
import SearchFilter from "../SearchFilter/searchFilter";
import Loader from "@habi/habi-react-components/dist/Loader/loader";
import { HeadWrapper, LoaderSection } from "./style";
import ComparableCardContainer from "../ComparableCard/comparableCardContainer";
import {
  getInfoByNid,
  getNid,
  getTokenTwilio,
} from "../../services/financialToolServices";
import ComparableByZone from "../ComparableByZone/ComparableByZone";
import ComparableByLot from "../ComparableByLot/ComparableByLot";
import ComparableByPricing from "../ComparableByPricing/ComparableByPricing";
import TableInfoLot from "../TableInfoLot/TableInfoLot";
import Callable from "../Callable/Callable.jsx";
const Device = require("twilio-client").Device;

const ListSellersContainer = props => {
  // handling states
  const [inputValue, setInputValue] = useState("");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
 
  const nidInfo = async nid => {
    setIsLoading(true);
    let res = {};
    const resNid = await getNid({ nid });
    try {
      if (resNid.data.body) {
        res = await getInfoByNid({
          ...resNid.data.body,
          precio_manual: resNid.data.body.precio_manual || 0,
          last_ask_price: resNid.data.body.last_ask_price || 0,
          antiguedad: resNid.data.body.antiguedad || 0,
          area: resNid.data.body.area || 0,
          proyecto: resNid.data.body.proyecto || "",
        });
        setData([res.data.data]);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);

    getTokenTwilio()
      .then(response => {
        return response.data;
      })
      .then(res => {
        Device.setup(res.token, { debug: true });
      });
  };

  useEffect(() => {
    if (inputValue.length >= 10) {
      nidInfo(inputValue);
    } else {
      setData([]);
    }
  }, [inputValue]);

  return (
    <>
      <HeadWrapper>
        <SearchFilter
          className="search-bar"
          inputValue={inputValue}
          setInputValue={setInputValue}
        />
      </HeadWrapper>
      {isLoading && (
        <LoaderSection>
          <Loader />
        </LoaderSection>
      )}
      {data.length > 0 && !isLoading && (
        <>
          <Callable isLoading={isLoading} data={data} />
          <ListSellersCallable isLoading={isLoading} data={data} />
          <ComparableCardContainer apartmentData={data[0]} />
          <TableInfoLot nid={inputValue} />
          <ComparableByLot nid={inputValue} />
          <ComparableByZone nid={inputValue} />
          <ComparableByPricing nid={inputValue} />
        </>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  role: state.formData.role,
});

export default connect(mapStateToProps)(ListSellersContainer);
