import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { filter } from "../../utils/utils";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import ListSalesProperties from "./listSalesProperties";
import { isMobileOnly } from "react-device-detect";

const ListSalesPropertyContainer = ({ filters }) => {
  //States
  const [listProperties, setListProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [areaMetropolitana, setAreaMetropolitana] = useState(null);
  const [zonaGrande, setZonaGrande] = useState(null);
  const [habitaciones, setHabitaciones] = useState(0);
  const [garajes, setGarajes] = useState(0);
  const [ascensores, setAscensores] = useState(0);
  const [presupuesto, setPresupuesto] = useState(0);
  const ALL_ITEMS = 2147483647;

  useEffect(() => {
    const rootUrl =
      process.env.REACT_APP_FORM_API_URL_V2 +
      process.env.REACT_APP_ENDPOINT_HABI_CMS_SALES_API_BASE_PATH;
    const endpointUrl =
      process.env.REACT_APP_ENDPOINT_HABI_CMS_SALES_API_READ_LIST_PROPERTY;
    const url = rootUrl + endpointUrl;
    const apikey = process.env.REACT_APP_HABI_CMS_SALES_API_KEY;
    const fetchAllData = async () => {
      setIsLoading(true);
      const body = {
        page: 1,
        items: ALL_ITEMS,
      };
      await axios({
        url: url,
        method: "post",
        headers: {
          "x-api-key": apikey,
          "Content-Type": "application/json",
        },
        data: body,
      })
        .then(response => {
          const data = response.data.data;
          setListProperties(data);
        })
        .catch(error => {
          console.error(error);
        })
        .finally(function() {
          window.scrollTo(0, 0);
          setIsLoading(false);
        });
    };
    fetchAllData();
  }, []);

  useEffect(() => {
    if (!filters) return;

    const {
      area_metropolitana,
      zona_grande,
      habitaciones,
      garajes,
      ascensores,
      presupuesto,
    } = filters;
    setAreaMetropolitana(+area_metropolitana);
    setZonaGrande(zona_grande);
    setHabitaciones(+habitaciones);
    setGarajes(garajes);
    setAscensores(+ascensores);
    setPresupuesto(+presupuesto);
  }, [filters]);

  const getPriceRange = priceRange => {
    switch (priceRange) {
      case 0:
        return { minValue: 0, maxValue: ALL_ITEMS };
      case 1:
        return { minValue: 100000000, maxValue: 150000000 };
      case 2:
        return { minValue: 150000000, maxValue: 200000000 };
      case 3:
        return { minValue: 200000000, maxValue: 250000000 };
      case 4:
        return { minValue: 250000000, maxValue: 300000000 };
      case 5:
        return { minValue: 300000000, maxValue: 350000000 };
      default:
        break;
    }
  };

  const { minValue, maxValue } = getPriceRange(presupuesto);

  const byPublicadoStatus = property => property.publicado === 2;
  const byAreaMetropolitana = property =>
    !areaMetropolitana || property.area_metropolitana_id === areaMetropolitana;
  const byZonaGrande = property =>
    !zonaGrande ||
    property.zona_grande.toLowerCase().includes(zonaGrande.toLowerCase());
  const byHabitaciones = property =>
    !habitaciones || property.num_habitaciones === habitaciones;
  const byGarajes = property => !garajes || property.garajes === garajes;
  const byAscensores = property =>
    !ascensores || property.ascensores === ascensores;
  const byPresupuesto = property =>
    property.precio_venta > minValue && property.precio_venta < maxValue;

  const allFilters = filter(
    byPublicadoStatus,
    byAreaMetropolitana,
    byZonaGrande,
    byHabitaciones,
    byGarajes,
    byAscensores,
    byPresupuesto
  );

  const filteredProperties = allFilters(listProperties);

  return (
    <BlockUi
      tag="div"
      blocking={isLoading}
      style={{
        padding: isMobileOnly ? "24px 0" : 24,
        width: isMobileOnly ? "100vw" : "calc(100vw - 24px)",
        overflow: "auto",
        margin: "auto",
      }}
    >
      <ListSalesProperties listSalesProperties={filteredProperties} />
    </BlockUi>
  );
};

const mapStateToProps = state => ({
  role: state.formData.role,
});

export default connect(mapStateToProps)(ListSalesPropertyContainer);
