import styled from "styled-components";

export const PageTitle = styled.h1`
  font-family: "Muli Black";
  text-align: center;
`;

export const PhoneContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
