import styled from "styled-components";

export const PaginatorWrapper = styled.div`
  .pagination {
    display: inline-block;
    padding-left: 0;
    margin: 20px 0;
    border-radius: 4px;
  }

  .pagination > li {
    display: inline;
  }

  .pagination > li > a:focus,
  .pagination > li > a:hover,
  .pagination > li > span:focus,
  .pagination > li > span:hover {
    z-index: 2;
    color: #23527c;
    background-color: #eee;
    border-color: #ddd;
  }

  .pagination > li > a,
  .pagination > li > span {
    position: relative;
    float: left;
    padding: 6px 12px;
    line-height: 1.42857143;
    text-decoration: none;
    color: #7c01ff;
    background-color: #fff;
    border: 1px solid #ddd;
    margin-left: -1px;
    font-weight: 600;
  }

  .pagination > .disabled > a,
  .pagination > .disabled > a:focus,
  .pagination > .disabled > a:hover,
  .pagination > .disabled > span,
  .pagination > .disabled > span:focus,
  .pagination > .disabled > span:hover {
    color: #777;
    background-color: #fff;

    border: none;
    cursor: not-allowed;
  }

  .pagination > .active > a,
  .pagination > .active > a:focus,
  .pagination > .active > a:hover,
  .pagination > .active > span,
  .pagination > .active > span:focus,
  .pagination > .active > span:hover {
    z-index: 3;
    color: #fff;
    background-color: #7c01ff;
    border: none;
    border-radius: 50%;
    cursor: default;
  }
  .pagination > li > a,
  .pagination > li > span {
    border: none;
  }
`;
