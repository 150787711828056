import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { addCrmDataAction } from "../../redux/actions/crmData.actions";
import {
  addCrmLeadAction,
  clearCrmLeadAction,
} from "../../redux/actions/crmLead.actions";
import { selectHasLead } from "../../redux/selectors/crmLead.selectors";
import { crmHeadCells } from "./CRMLeadsList.data";
import { useStyles } from "./CRMLeadsList.style";
import { fetchCRMLeads, fetchCRMLeadsByPhone } from "./CRMLeadsList.utils";
import { stableSort, getComparator } from "../../utils/tableSort";

import CRMLeadsList from "./CRMLeadsList.component";

const CRMLeadsListContainer = props => {
  const [resData, setResData] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  const { hasLead, addCrmData, clearCrmLead, crmData, addCrmLead } = props;

  useEffect(() => {
    fetchCRMLeads({
      setResData,
      setTotalItems,
      page,
      rowsPerPage,
      setLoading,
    });
  }, [setResData, setTotalItems, page, rowsPerPage, setLoading]);

  useEffect(() => {
    fetchCRMLeadsByPhone({
      setResData,
      setTotalItems,
      page,
      rowsPerPage,
      crmData,
      setLoading,
    });
  }, [setResData, setTotalItems, page, rowsPerPage, crmData, setLoading]);

  const handlePageChange = (_, newPage) => setPage(newPage);

  const handleRowsPerPageChange = e => setRowsPerPage(e.target.value);

  const handleSortRequest = prop => {
    const isAsc = orderBy === prop && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(prop);
  };

  const sortedRows = stableSort(resData, getComparator(order, orderBy));

  return (
    <div>
      <CRMLeadsList
        rows={sortedRows}
        handleSortRequest={handleSortRequest}
        crmHeadCells={crmHeadCells}
        order={order}
        orderBy={orderBy}
        classes={classes}
        addCrmData={addCrmData}
        addCrmLead={addCrmLead}
        hasLead={hasLead}
        clearCrmLead={clearCrmLead}
        page={page}
        handlePageChange={handlePageChange}
        totalItems={totalItems}
        handleRowsPerPageChange={handleRowsPerPageChange}
        rowsPerPage={rowsPerPage}
        searchFilterValue={crmData.telefonoFiltro}
        loading={loading}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  hasLead: selectHasLead(state),
  crmData: state.crmData,
});

const mapDispatchToProps = dispatch => ({
  addCrmData: data => dispatch(addCrmDataAction(data)),
  addCrmLead: data => dispatch(addCrmLeadAction(data)),
  clearCrmLead: data => dispatch(clearCrmLeadAction(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CRMLeadsListContainer);
