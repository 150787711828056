import React, { Fragment, useState } from "react";
import { LoginWrapper } from "./style";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { updateData } from "../../redux/actions/formData.actions";
import logo from "../../static/assets/logo/habi.svg";
import loginDecorationStart from "../../static/assets/images/home/login-decoration-start.svg";
import loginDecorationEnd from "../../static/assets/images/home/login-decoration-end.svg";
import { availableRoles } from "./login.utils";
import PortraitIcon from "@material-ui/icons/Portrait";
import LoginGoogle from "../LoginGoogle";
import GoogleLogin from "react-google-login";

const LogIn = props => {
  const [chooseRole, setChooseRole] = useState(true);
  const [roleName, setRoleName] = useState("");

  const handleClick = role => {
    setChooseRole(false);
    setRoleName(role);
  };

  const onRejectLogin = response => {
    props.history.push("/");
  };

  const onSuccessLogin = response => {
    const profile = response;
    const habiDomains = ["habi.co", "tuhabi.mx"];

    if (profile.id) {
      //Check email domain
      const domain = profile.email.split("@")[1];
      let external = 1;
      if (habiDomains.find((item) => item === domain)) {
        external = 0
      }

      const data = {
        userId: profile.googleId,
        external: external,
      };

      if (profile.roles.includes(roleName)) {
        props.updateData({
          role: roleName,
          email: profile.email,
          name: profile.name,
          lastName: profile.lastName,
          imageProfile: profile.imageProfile,
          authenticated: true,
        });
        props.history.push("/dashboard");
      } else {
        setChooseRole(true);
      }
    }
  };

  return (
    <Fragment>
      <LoginWrapper chooseRole={chooseRole}>
        <div className="decoration">
          <img src={loginDecorationStart} alt="Habi decoration" />
          <img src={loginDecorationEnd} alt="Habi decoration" />
        </div>
        <div className="content">
          <div className="login-container">
            <img src={logo} className="logo-img" alt="logo-img" />
            <p className="title">Financial Tool</p>
            {chooseRole && (
              <div className="role-buttons">
                <p className="question">¿Con cuál rol deseas ingresar?</p>
                {Object.keys(availableRoles).map((item, idx) => (
                  <div
                    key={`role-${idx}`}
                    className="role"
                    onClick={() => handleClick(availableRoles[item])}
                  >
                    <PortraitIcon />
                    <span>{item}</span>
                  </div>
                ))}
              </div>
            )}
            {!chooseRole && (
              <div className="pass-button">
                <LoginGoogle
                  onError={onRejectLogin}
                  onNextStep={onSuccessLogin}
                />
              </div>
            )}
          </div>
        </div>
      </LoginWrapper>
    </Fragment>
  );
};
const mapStateToProps = state => ({
  role: state.formData.role,
  name: state.formData.name,
  lastName: state.formData.lastName,
  email: state.formData.email,
  imageProfile: state.formData.imageProfile,
});
const mapDispatchToProps = dispatch => ({
  updateData: data => dispatch(updateData(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LogIn));
