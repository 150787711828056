import styled from "styled-components";
import { createTheme } from "@material-ui/core/styles";
import {
  archiaBoldFamily,
  archiaRegular,
  bogotaBlue100,
  muliBoldFamily,
  nightBlue100,
  nightBlue500,
  scarpaGray100,
  scarpaGray500,
} from "../../StylesConstants";

export const TableWrapper = styled.div`
  padding: 24px;

  .table-title {
    font-family: ${muliBoldFamily};
    font-size: 20px;
    line-height: 28px;
    color: ${nightBlue500};
    margin-bottom: 16px;
  }
`;

export const StyledTable = createTheme({
  overrides: {
    MuiTableCell: {
      stickyHeader: {
        backgroundColor: scarpaGray100,
        fontFamily: archiaBoldFamily,
        fontSize: "13px",
        color: scarpaGray500,
      },
      body: {
        fontFamily: archiaRegular,
        fontSize: "13px",
        color: scarpaGray500,
      },
    },
    MuiTablePagination: {
      root: {
        borderBottom: `1px solid ${scarpaGray100}`,
      },
    },
    MuiTableRow: {
      hover: {
        "&:hover": {
          backgroundColor: `${bogotaBlue100} !important`,
        },
      },
    },
  },
});

export const NoResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  color: ${nightBlue100};
  text-transform: uppercase;
  letter-spacing: 0.2em;
  height: 72px;
  text-align: center;
  border-bottom: 1px solid ${scarpaGray100};

  .top-message {
    font-family: "Open Sans ExtraBold";
    margin: unset;
  }
`;
