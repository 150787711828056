import React, { useState } from "react";
import { RequirementsWrapper } from "./style";
import { Tabs, Tab } from "react-bootstrap";

const Requirements = props => {
  const [key, setKey] = useState("empleados");

  return (
    <RequirementsWrapper>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={k => setKey(k)}
      >
        <Tab eventKey="empleados" title="Empleados">
          <ul>
            <li>Foto o copia de tu Cédula de Ciudadanía.</li>
            <li>
              Certificado Laboral (no mayor a 90 días) indicando: cargo, sueldo,
              antigüedad y tipo de contrato.
            </li>
            <li>
              Declaración de Renta del último año gravable (si estás obligado a
              declarar).
            </li>
            <li>
              Certificado de Ingresos y Rentenciones del último año gravable (si
              presentas la Declaración de Renta, no es necesario este
              documento).
            </li>
            <li>
              Si tienes ingresos adicionales: Arrendamientos, Honorarios y
              Comisiones, Contratos, Referencias Comerciales (Cámara de
              Comercio, Certificado de Tradición y Libertad). Extractos o
              documentos que soporten estos ingresos.
            </li>
            <li>
              Contrato fijo o a término indefinido: Comprobantes de pago de
              nómina de los últimos 2 meses.
            </li>
            <li>
              Contrato de prestación de servicios: Documentos que soporten tus
              ingresos mensuales.
            </li>
          </ul>
        </Tab>
        <Tab eventKey="independientes" title="Independientes">
          <ul>
            <li>Foto o copia de tu Cédula de Ciudadanía.</li>
            <li>
              Declaración de Renta del último año gravable ( si estás obligado a
              declarar).
            </li>
            <li>Extractos Bancarios de los últimos 3 meses.</li>
            <li>Otros documentos que soporten sus ingresos.</li>
            <li>
              Documentos opcionales:<br></br>
              Resumen de facturas de compras o ventas.<br></br> Certificado de
              Cámara y Comercio.
            </li>
          </ul>
        </Tab>
        <Tab eventKey="pensionados" title="Pensionados">
          <ul>
            <li>Foto o copia de tu Cédula de Ciudadanía.</li>
            <li>Comprobantes de pago de pensión de los últimos 2 meses.</li>
            <li>
              Declaración de Renta del último año gravable (si estás obligado a
              declarar).<br></br>
              <br></br>
              Si no declaras renta puedes adjuntar certificado de ingresos y
              retenciones del último año.
            </li>
          </ul>
        </Tab>
      </Tabs>
    </RequirementsWrapper>
  );
};

export default Requirements;
