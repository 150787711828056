import React from "react";
import logo from "../../static/assets/logo/habi.svg";
import { HeaderWrapper } from "./style";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { ROLE } from "../const/constants";

const HeaderSmall = props => {
  const goToIndex = () => {
    props.history.push("/dashboard");
  };

  return (
    <HeaderWrapper className={props.role === ROLE.VENTANERO ? "ventanero" : "callcenter"}>
      <div className="items-container" onClick={goToIndex}>
        <img src={logo} alt="Habi logo" className="logo"></img>
      </div>
      <div className="profile">
        <p>
          {props.name} {props.lastName}
        </p>
        <img src={props.imageProfile} alt="image-profile" />
      </div>
    </HeaderWrapper>
  );
};
const mapStateToProps = state => ({
  role: state.formData.role,
  name: state.formData.name,
  lastName: state.formData.lastName,
  email: state.formData.email,
  imageProfile: state.formData.imageProfile
});
export default withRouter(connect(mapStateToProps)(HeaderSmall));
