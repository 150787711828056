import React, { Fragment } from "react";
import { Calculator, PrettoSlider } from "./style";
import ArrowRightAltRoundedIcon from "@material-ui/icons/ArrowRightAltRounded";
import { isMobileOnly } from "react-device-detect";
import Button from "../sharedComponents/Button/button";

const LoanCalculator = props => {
  return (
    <Calculator id="calculadora">
      <div className="toggles">
        <div className="tittle-section">
          {" "}
          SIMULADOR FINANCIERO & GASTOS MENSUALES
        </div>
        <div className="menu-toggle">
          <div
            className={"toggle-left " + props.activeCredito}
            onClick={props.handleChangeTipoCredito.bind(this, "credito")}
          >
            Crédito hipotecario
          </div>
          <div
            className={"toggle-right " + props.activeLeasing}
            onClick={props.handleChangeTipoCredito.bind(this, "leasing")}
          >
            Leasing habitacional
          </div>
        </div>
      </div>
      <div className="detalles">
        <div className="name">
          <p>Valor del inmueble</p>
          <p>Valor del crédito</p>
        </div>
        <div className="value">
          <div>
            <span>$</span>
            <input
              type="number"
              className="input-value"
              onChange={props.handleChangeInmuebleValue}
            />
          </div>
          <p>${new Intl.NumberFormat().format(props.valorCredito)}</p>
        </div>
        <div></div>
        <Button
          label="Calcular"
          typeClass="primary"
          id="button-calcular"
          onClick={props.calculate}
        />
      </div>
      <div className="controls">
        <div className="title-initial">
          <p className="title-init">
            Cuota inicial{" "}
            <b className="tab-years">{props.cuotaInicialPercentage}%</b>{" "}
            <ArrowRightAltRoundedIcon />{" "}
            <b className="tab-years">
              <span>${props.cuotaInicial}</span>
            </b>
          </p>
        </div>

        {isMobileOnly ? (
          <PrettoSlider
            valueLabelDisplay="auto"
            aria-label="pretto slider"
            defaultValue={20}
            onChange={props.handleChangeCuota}
            value={props.cuotaInicialPercentage}
            className="slider-cuota-mob"
            valueLabelFormat={`${props.cuotaInicialPercentage}%`}
            min={props.tipoCredito === "leasing" ? 10 : 30}
          />
        ) : (
          <PrettoSlider
            valueLabelDisplay="auto"
            aria-label="pretto slider"
            defaultValue={20}
            onChange={props.handleChangeCuota}
            value={props.cuotaInicialPercentage}
            className="slider-cuota"
            valueLabelFormat={`${props.cuotaInicial}${" "}(${
              props.cuotaInicialPercentage
            }%)`}
            min={props.tipoCredito === "leasing" ? 10 : 30}
          />
        )}

        <div className="title-initial" style={{ marginTop: "32px" }}>
          <p className="title-init">
            Crédito diferido a{" "}
            <b className="tab-years">{props.plazoYears} años</b>
          </p>
        </div>
        <PrettoSlider
          valueLabelDisplay="auto"
          aria-label="pretto slider"
          defaultValue={20}
          min={5}
          max={30}
          value={props.plazoYears}
          onChange={props.handleChangeYears}
        />
        <p className="note">
          * Cuota calculada con plan de amortización en pesos y tasa M.V del
          <b> 0.8%.</b>
        </p>
      </div>
      <div className="monthly-pay">
        <div className="monthly-title">
          <p>Tu pago mensual sería de:</p>
        </div>
        <div className="monthly-amount">
          <span>$</span>
          <p>{new Intl.NumberFormat().format(props.cuotaMensual)}</p>
        </div>
      </div>
      <div className="income">
        <div className="needed-income">
          <div className="needed-income-title">
            <p>Necesitas ingresos de: </p>
          </div>
          <div className="needed-income-amount">
            <span>$</span>
            <p>{new Intl.NumberFormat().format(props.incomeNeeded)}</p>
          </div>
        </div>
        <div className="fix-rate">
          <div className="fix-rate-title">
            <p>Tasa fija de: </p>
          </div>
          <div className="fix-rate-amount">
            <input
              type="number"
              className="input-rate-value"
              onChange={props.handleChangeRate}
              value={props.tasaMensual}
            />
            <span>% M.V.</span>
          </div>
        </div>
      </div>
    </Calculator>
  );
};

export default LoanCalculator;
