import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addPolygonDataAction } from "../../redux/actions/polygon.actions";
import { getComparable } from "../../services/financialToolServices";
import TableComparable from "../TableComparable/TableComparable";
import { headColumnsLot } from "./ComparableByLot.utils";

const ComparableByLot = props => {
  const { nid } = props;
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [more, setMore] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const dataToSend = {
      nid: nid,
      limit: limit,
      offset: offset,
    };
    getComparable(dataToSend, "get_comparable_set")
      .then(response => {
        setData([...data, ...response.data.data]);
        setMore(response.data.more_available);
        dispatch(addPolygonDataAction(response.data.poligono));
      })
      .catch(error => console.log(error));
  }, [nid, limit, offset]);

  return (
    <TableComparable
      title="Comparables por conjunto"
      headers={headColumnsLot}
      data={data}
      limit={limit}
      setLimit={setLimit}
      offset={offset}
      setOffset={setOffset}
      more={more}
    />
  );
};

export default ComparableByLot;
