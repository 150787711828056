import React, { useState } from "react";
import InputNumber from "./InputNumber";
import { List, ListItem, ListItemText, IconButton } from "@material-ui/core";
import { putNumberContact } from "../../../services/financialToolServices";
import UseAnimations from "react-useanimations";
import Trash2 from "react-useanimations/lib/trash2";
import { WhatsApp } from "@material-ui/icons";
import { StyledIconButton } from "../Callable.style";

const ListNumbers = ({
  data,
  actualNumberCall,
  setActualNumberCall,
  toggleAlert,
}) => {
  const { telefono, telefono_2, telefono_3, nid } = data;
  const [phoneList, setPhoneList] = useState({
    1: telefono,
    2: telefono_2,
    3: telefono_3,
  });
  const arrayList = Object.values(phoneList);

  const checkEmptyPhone = () => {
    const checkNull = arrayList.includes(null);
    const checkEmp0tyStrings = arrayList.includes("");
    const isEmpty = checkNull || checkEmp0tyStrings;
    return isEmpty;
  };

  const checkEmptyIndex = () => {
    const indexNull = arrayList.indexOf(null);
    const indexEmptyString = arrayList.indexOf("");

    if (indexNull !== -1) {
      return indexNull + 1;
    } else if (indexEmptyString !== -1) {
      return indexEmptyString + 1;
    }
  };

  const checkRepeatedPhone = number => arrayList.includes(number);

  const showError = () => {
    toggleAlert(true);
    setTimeout(() => toggleAlert(false), 3500);
  };

  const handleNewStatus = (index, newNumber) => {
    const newList = {
      2: { ...phoneList, 2: newNumber },
      3: { ...phoneList, 3: newNumber },
    };
    setPhoneList(newList[index]);
  };

  const addNumber = async number => {
    try {
      const parsedNumber = `+57 ${number}`;
      const checkNumber = /^\+\w{2}\s\w{10}$/.test(parsedNumber);

      if (checkRepeatedPhone(parsedNumber) || !checkNumber) {
        showError();
      } else {
        const index = checkEmptyIndex();
        const { data, status } = await putNumberContact(
          nid,
          `telefono_${index}`,
          parsedNumber
        );

        if (status === 200 && data.success) {
          handleNewStatus(index, parsedNumber);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deletePhoneNumber = async index => {
    try {
      const { data, status } = await putNumberContact(
        nid,
        `telefono_${index}`,
        ""
      );
      if (status === 200 && data.success) {
        handleNewStatus(index, "");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const phoneTransform = phone => phone.replace(/[ +]/g, "");

  return (
    <div className="numbers">
      <List component="nav" aria-label="main mailbox folders">
        {arrayList.map((item, index) => {
          if (item) {
            return (
              <ListItem selected={actualNumberCall === item} key={index}>
                {!!index && (
                  <IconButton onClick={() => deletePhoneNumber(index + 1)}>
                    <UseAnimations
                      animation={Trash2}
                      size={32}
                      style={{ padding: 8 }}
                    />
                  </IconButton>
                )}

                <ListItemText
                  className="numberText"
                  onClick={() => setActualNumberCall(item)}
                >
                  {item}
                </ListItemText>
                <StyledIconButton
                  visible={actualNumberCall === item}
                  onClick={() =>
                    window.open(`https://wa.me/${phoneTransform(item)}`)
                  }
                >
                  <WhatsApp />
                </StyledIconButton>
              </ListItem>
            );
          } else {
            return null;
          }
        })}
        {checkEmptyPhone() && <InputNumber addNumber={addNumber} />}
      </List>
    </div>
  );
};

export default ListNumbers;
