import { combineReducers } from "redux";

import formDataReducer from "./reducers/formData.reducer";
import crmDataReducer from "./reducers/crmData.reducer";
import crmLeadReducer from "./reducers/crmLead.reducer";
import polygonReducer from "./reducers/polygon.reducer";
import { twillioReducer } from "./twillio/twillio.reducers";

const rootReducer = combineReducers({
  formData: formDataReducer,
  crmData: crmDataReducer,
  crmLead: crmLeadReducer,
  polygon: polygonReducer,
  twillio: twillioReducer,
});

export default rootReducer;
