import { electricPurple500, scarpaGray700 } from "../../StylesConstants";

export const handleApiLoaded = (map, maps, center, polygonData) => {
  let polygon = new maps.Polygon({
    paths: polygonData,
    strokeColor: electricPurple500,
    strokeWeight: 3,
    fillColor: electricPurple500,
    fillOpacity: 0.2,
  });

  polygon.setMap(map);

  new maps.Circle({
    strokeColor: scarpaGray700,
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: scarpaGray700,
    fillOpacity: 0,
    map,
    center: center,
    radius: 500,
  });
};
