import {
  brightTurquoise400,
  electricPurple400,
  muliExtraBoldFamily,
  muliRegular,
  white,
} from "../../StylesConstants";
import styled, { css } from "styled-components";

export const PosterWrapper = styled.div`
  width: 100%;
  .poster-container {
    background-color: ${electricPurple400};
    display: grid;
    grid-template-columns: repeat(12, 40px);
    grid-template-rows: repeat(12, 40px);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    font-family: ${muliExtraBoldFamily};
    color: ${white};

    .image-container {
      grid-area: 1 / 1 / 7 / 13;

      img {
        width: 100%;
        height: auto;
      }

      .pic-large {
        width: 66.7%;
        float: left;
      }

      .container-pic-small {
        width: 33.3%;
        display: flex;
        flex-direction: column;
      }
    }

    .text-container {
      grid-area: 7 / 1 / 13 / 13;
      justify-self: center;
      text-align: center;
      width: 100%;

      .title {
        margin: 0;
        margin-top: 10px;
      }
      .subtitle {
        color: ${brightTurquoise400};
        margin: 0;
      }
      .price {
        background: ${brightTurquoise400};
        width: fit-content;
        padding: 8px 16px;
        border-radius: 7px;
        color: ${electricPurple400};
        margin: 8px auto;
        font-size: 26px;
      }
      .text {
        font-family: ${muliRegular};
        font-weight: 400;
        font-size: 14px;
        text-align: center;
        margin-bottom: 0;
        .sub-text {
          font-size: 16px;
          font-weight: 900;
          color: ${brightTurquoise400};
        }
      }

      .amenities {
        display: flex;
        font-family: ${muliRegular};
        justify-content: center;
        margin-top: 10px;

        .text-amenities {
          font-size: 11px;
          padding: 0px 4px;
          .icon {
            width: 17px;
            height: 17px;
            margin-bottom: 3px;
            margin-left: 3px;
          }
        }
      }

      .footer {
        display: grid;
        grid-template-columns: 385px 70px;
        column-gap: 26px;
        align-items: center;

        .amenities-logo {
          display: flex;
          font-family: ${muliRegular};
          justify-content: flex-end;
          .text-amenities {
            font-size: 11px;
            padding: 0px 4px;
            .icon {
              width: 17px;
              height: 17px;
              margin-bottom: 3px;
              margin-left: 3px;
            }
          }
        }
        .logo {
          position: relative;
          bottom: 10px;
          width: 56px;
          height: 56px;
        }
      }
    }
  }
`;

export const SmallPic = styled.div`
  width: 160px;
  height: 120px;
  ${props =>
    props.src &&
    css`
      background-image: url(${props.src});
    `}
  background-size: cover;
  background-repeat: no-repeat;
`;

SmallPic.defaultProps = {
  img: "",
};

export const LargePic = styled.div`
  width: 320px;
  height: 240px;
  ${props =>
    props.src &&
    css`
      background-image: url(${props.src});
    `}
  background-size: cover;
  background-repeat: no-repeat;
`;

LargePic.defaultProps = {
  img: "",
};