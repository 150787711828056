import React from 'react'
import { WrappComment } from '../Comments.style'


const Comment = ({ comment, delay }) => {
    const {
        note,
        date_on,
        name
    } = comment;

    return (
        <WrappComment className="Comment" delay={delay}>
            <p className="note">{note} </p> 
            <p className="autor">{name} - {date_on} </p>
        </WrappComment>
    )
}

export default Comment;
// - {date}