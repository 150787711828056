import React, { useState } from "react";
import {
  MuiThemeProvider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import {
  NoResultsContainer,
  StyledTable,
  TableWrapper,
} from "./TableComparable.style";

const TableComparable = props => {
  const {
    title,
    headers,
    data,
    limit,
    setLimit,
    offset,
    setOffset,
    more,
  } = props;
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    if (more) {
      setLimit(limit + 10);
      setOffset(offset + 10);
    }
    setPage(newPage);
  };
  const urlHubspot = process.env.REACT_APP_HUBSPOT_LINK;

  return (
    <TableWrapper>
      <h2 className="table-title">{title}</h2>
      <MuiThemeProvider theme={StyledTable}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {headers.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.slice(page * 5, page * 5 + 5).map(row => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.nid}>
                      {headers.map(column => {
                        const value = row[column.id];
                        if (column.id === "nid") {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              <a
                                href={`${urlHubspot}${column.format(value)}`}
                                target="_blank"
                              >
                                {column.format(value)}
                              </a>
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format(value)}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {data.length > 0 && (
          <TablePagination
            rowsPerPageOptions={[5]}
            component="div"
            count={data.length}
            rowsPerPage={5}
            page={page}
            onChangePage={handleChangePage}
          />
        )}
        {data.length === 0 && (
          <NoResultsContainer>
            <p className="top-message">
              No hay inmuebles {title} con estas características.
            </p>
          </NoResultsContainer>
        )}
      </MuiThemeProvider>
    </TableWrapper>
  );
};

export default TableComparable;
