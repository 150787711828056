import React, { useRef } from "react";
import Poster from "../Poster/poster";
import PosterLogo from "../Poster/posterLogo";
import { exportComponentAsPNG } from "react-component-export-image";
import PropertyPosterWrapper from "./style";
import Button from "@habi/habi-react-components/dist/Button/button";
import CloudDownloadRoundedIcon from "@material-ui/icons/CloudDownloadRounded";

const PropertyPoster = ({ property, ...props }) => {
  const posterRef = useRef();
  const posterLogoRef = useRef();

  const printTwoPoster = () => {
    let options = {
      windowWidth: 1280,
      windowHeight: 1280,
      scrollX: 0,
      scrollY: 0,
    };
    exportComponentAsPNG(posterRef, "poster", null, "image/png", options);
    exportComponentAsPNG(
      posterLogoRef,
      "poster_logo",
      null,
      "image/png",
      options
    );
  };

  const baseUrl = process.env.REACT_APP_FORM_API_URL_V2;
  const endPointUrl =
    process.env.REACT_APP_ENDPOINT_HABI_CMS_SALES_API_BASE_PATH;
  const keyUrl = process.env.REACT_APP_HABI_CMS_SALES_API_KEY;

  const convert = (urlImage, setSource) => {
    const converter_url = baseUrl + endPointUrl + "/base64_converter";
    fetch(converter_url, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        "x-api-key": keyUrl,
      },
      body: JSON.stringify({ img_url: urlImage }),
    })
      .then(response => response.json())
      .then(data => {
        const base64Img = "data:image/jpg;base64," + data.base64;
        setSource(base64Img);
      })
      .catch(error => {
        console.error("Error:", error);
      });
  };

  return (
    <PropertyPosterWrapper>
      <div className="posters">
        <Poster ref={posterRef} property={property} convert={convert} />
        <PosterLogo ref={posterLogoRef} property={property} convert={convert} />
      </div>
      <div className="button-container">
        <Button
          id="download"
          typeClass="primary"
          className="d-inline-block"
          type="button"
          label="Descargar Imagen"
          onClick={printTwoPoster}
        >
          <CloudDownloadRoundedIcon className="download-icon" />
        </Button>
      </div>
    </PropertyPosterWrapper>
  );
};

export default PropertyPoster;
