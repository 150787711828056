export const polygonActionTypes = {
  ADD_POLYGON_DATA: "ADD_POLYGON_DATA",
  CLEAR_POLYGON_DATA: "CLEAR_POLYGON_DATA",
};

export const addPolygonDataAction = payload => ({
  type: polygonActionTypes.ADD_POLYGON_DATA,
  payload,
});

export const clearPolygonDataAction = () => ({
  type: polygonActionTypes.CLEAR_POLYGON_DATA,
});
