import React from "react";
import mainMarker from "../../static/assets/icons/main-map-marker.png";
import GoogleMapReact from "google-map-react";
import { handleApiLoaded } from "./MapImage.utils";
import {
  muliExtraBoldFamily,
  nightBlue000,
  nightBlue500,
} from "../../StylesConstants";
import { useSelector } from "react-redux";

export const MainMarker = () => (
  <img
    style={{ transform: "translate(-30px,-54px)" }}
    src={mainMarker}
    alt="main inmueble"
  />
);

export const RadiousInfo = () => (
  <div
    style={{
      width: "64px",
      height: "32px",
      backgroundColor: `${nightBlue500}`,
      borderRadius: "8px",
      color: `${nightBlue000}`,
      fontFamily: `${muliExtraBoldFamily}`,
      fontSize: "14px",
      lineHeight: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      transform: "translate( -30px, -242px)",
    }}
  >
    {" "}
    500 m{" "}
  </div>
);

const MapImage = props => {
  const { longitude, latitude } = props;
  const { polygon } = useSelector(state => state);
  const polygonData = polygon;

  const defaultProps = {
    zoom: 16,
    center: { lat: latitude, lng: longitude },
  };

  const defaultMapOptions = {
    disableDefaultUI: true,
    draggable: false,
  };

  return (
    <div
      style={{
        height: "calc(100vh - 155px)",
        width: "calc(100vw - 255px)",
      }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAPS_KEY,
        }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        options={defaultMapOptions}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) =>
          handleApiLoaded(
            map,
            maps,
            {
              lat: defaultProps.center.lat,
              lng: defaultProps.center.lng,
            },
            polygonData
          )
        }
      >
        <MainMarker
          lat={defaultProps.center.lat}
          lng={defaultProps.center.lng}
        />
        <RadiousInfo
          lat={defaultProps.center.lat}
          lng={defaultProps.center.lng}
        />
      </GoogleMapReact>
    </div>
  );
};

export default MapImage;
