import styled from "styled-components";

export const SimuladorWrapper = styled.section`
  display: grid;
  grid-template: 0.5fr 1fr /1fr;
  justify-items: center;

  .grid-simulador {
    width: 90%;
  }
`;
export const TitleWrapper = styled.section`
  display: grid;
  margin-top: 10%;
  margin-bottom: 10%;
  .title {
    align-self: center;
    justify-self: center;
    h1 {
      font-size: 2rem;
    }
  }
`;
