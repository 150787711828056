import styled from "styled-components";
import { bogotaBlue100 } from "../../StylesConstants";

export const InfoCallWrapper = styled.div`
  .call_card {
    width: 248px;
    height: fit-content;
    background: ${bogotaBlue100};
    box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05),
      0px 2px 4px rgba(48, 49, 51, 0.1);
    border-radius: 16px;
    text-align: center;

    .phone-call {
      padding: 16px;
      font-size: 20px;
    }

    .buttons-container {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    .response-call {
      padding: 16px;
    }
  }
`;
