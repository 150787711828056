import React, { useEffect, useState } from "react";
import { SearchWrapper } from "./style";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import { useDispatch, useSelector } from "react-redux";
import { clearPolygonDataAction } from "../../redux/actions/polygon.actions";

const SearchFilter = ({ inputValue, setInputValue, placeholder, ...props }) => {
  const [isActive, setActive] = useState(false);
  const { polygon } = useSelector(state => state);
  const dispatch = useDispatch();

  const handleChange = event => {
    event.preventDefault();
    setInputValue(event.target.value);
    if (polygon.length > 0) {
      dispatch(clearPolygonDataAction());
    }
  };

  const handleClean = () => {
    setInputValue("");
    dispatch(clearPolygonDataAction());
  };

  useEffect(() => {
    if (inputValue) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [inputValue]);

  return (
    <SearchWrapper>
        <SearchRoundedIcon className="icon" />
        <input
          value={inputValue}
          onChange={handleChange}
          type="number"
          placeholder={placeholder ?? "Buscar por NID"}
        />
        {isActive && (
          <button
            onClick={handleClean}
            type="button"
            id="reset-button"
            className="reset"
            disabled={props.disabled}
          >
            <span>Borrar</span>
          </button>
        )}
    </SearchWrapper>
  );
};

export default SearchFilter;
