import { crmDataActionTypes } from "../actions/crmData.actions";

const INITIAL_STATE = {};

const crmDataReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case crmDataActionTypes.ADD_CRM_DATA:
      return {
        ...state,
        ...payload,
      };
    case crmDataActionTypes.CLEAR_CRM_DATA:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default crmDataReducer;
