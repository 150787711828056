import * as yup from "yup";
import Axios from "axios";
import {
  generateNumberLabelsFromOne,
  generateNumberLabelsFromZero,
} from "../../utils/formUtils";
import { removeObjProps, pipe, replaceObjProps } from "../../utils/utils";
const fuentes = [
  {
    type: "select",
    name: "fuente_id",
    label: "Fuentes",
    options: [
      {
        label: "Comercial",
        value: 35,
      },
      { label: "Ventana scrapping", value: 28 },
      {
        label: "Habi constructora",
        value: 33,
      },
      {
        label: "Línea telefónica Cali",
        value: 34,
      },
      { label: "Línea telefónica Medellin", value: 29 },
      {
        label: "Centro de experiencia Medellín",
        value: 30,
      },
      {
        label: "Centro de experiencia Bogotá",
        value: 36,
      },
      {
        label: "Centro de experiencia Cali",
        value: 38,
      },
    ],
    style: {
      marginBottom: "16px",
      width: "30%",
    },
    validation: yup.string().required(),
  },
];
const infoInputs = [
  {
    type: "text",
    name: "nombre_o_inmobiliaria",
    placeholder: "Nombre del contacto",
    label: "Nombre o Inmobiliaria",
    style: {
      marginBottom: "16px",
      width: "25%",
    },
    validation: yup.string().required(),
  },
  {
    type: "text",
    name: "telefono",
    placeholder: "Teléfono",
    label: "Teléfono",
    style: {
      marginBottom: "16px",
      width: "25%",
    },
    validation: yup.string().required(),
  },
  {
    type: "email",
    name: "correo",
    placeholder: "correo",
    label: "Correo",
    style: {
      marginBottom: "16px",
      width: "25%",
    },
    validation: yup
      .string()
      .email()
      .required(),
  },
  {
    type: "radio",
    label: "Es Inmobiliaria",
    name: "inmobiliaria",
    options: [
      { label: "No", value: 0 },
      { label: "Sí", value: 1 },
    ],
    style: {
      marginBottom: "16px",
      width: "25%",
    },
    validation: yup.number().required(),
  },
];

const direccionInputs = [
  {
    type: "select",
    name: "ciudad",
    label: "Ciudad",
    options: [
      { label: "Bogotá", value: "bogota" },
      { label: "Medellín", value: "medellin" },
      { label: "Bello", value: "bello" },
      { label: "Barranquilla", value: "barranquilla" },
      { label: "Envigado", value: "envigado" },
      { label: "Itagüí", value: "itagui" },
      { label: "La Estrella", value: "la_estrella" },
      { label: "Sabaneta", value: "sabaneta" },
      { label: "Caldas", value: "caldas" },
      { label: "Copacabana", value: "copacabana" },
      { label: "Barbosa", value: "barbosa" },
      { label: "Cota", value: "cota" },
      { label: "Facatativá", value: "facatativa" },
      { label: "Funza", value: "funza" },
      { label: "Madrid", value: "madrid" },
      { label: "Zipaquirá", value: "zipaquira" },
      { label: "Soacha", value: "soacha" },
      { label: "Chía", value: "chia" },
      { label: "Mosquera", value: "mosquera" },
      { label: "Rio Negro", value: "rio_negro" },
      { label: "Cali", value: "cali" },
      { label: "Palmira", value: "palmira" },
      { label: "Yumbo", value: "yumbo" },
      { label: "Candelaria", value: "candelaria" },
      { label: "Jamundí", value: "jamundi" },
      { label: "Vijes", value: "vijes" },
    ],
    style: {
      marginBottom: "16px",
      width: "25%",
    },
    validation: yup.string().required(),
  },
  {
    type: "select",
    name: "tipo_calle",
    label: "Tipo Calle",
    options: [
      { label: "Calle", value: "calle" },
      { label: "Carrera", value: "carrera" },
      { label: "Avenida", value: "avenida" },
      { label: "Diagonal", value: "diagonal" },
      { label: "Transversal", value: "transversal" },
      { label: "Circular", value: "circular" },
    ],
    style: {
      marginBottom: "16px",
      width: "25%",
    },
    validation: yup.string().required(),
  },
  {
    type: "text",
    name: "num1",
    placeholder: "100",
    label: "Principal",
    style: {
      marginBottom: "16px",
      width: "200px",
    },
    validation: yup.string().required(),
  },
  {
    type: "text",
    name: "num2",
    label: "(#) Número",
    placeholder: "54",
    style: {
      marginBottom: "16px",
      width: "200px",
    },
    validation: yup.string().required(),
  },
  {
    type: "text",
    name: "num3",
    label: "(-) Número",
    placeholder: "20",
    style: {
      marginBottom: "16px",
      width: "200px",
    },
    validation: yup.string().required(),
  },
];

const ubicacionValorAntiguedadInputs = [
  {
    type: "currency",
    name: "ask_price",
    placeholder: "Ask Price",
    label: "Ask Price",
    style: {
      marginBottom: "16px",
      width: "25%",
    },
    validation: yup.string().required(),
  },
  {
    type: "select",
    name: "estrato",
    label: "Estrato",
    options: generateNumberLabelsFromOne(6),
    style: {
      marginBottom: "16px",
      width: "25%",
    },
    validation: yup.number().required(),
  },
  {
    type: "text",
    name: "conjunto_edificio",
    placeholder: "nombre del conjunto",
    label: "Nombre del conjunto",
    style: {
      marginBottom: "16px",
      width: "25%",
    },
    validation: yup.string().required(),
  },
  {
    type: "number",
    name: "anos_antiguedad",
    placeholder: "Años de antiguedad",
    label: "Años de antiguedad",
    style: {
      marginBottom: "16px",
      width: "25%",
    },
    validation: yup.string().required(),
  },
];

export const detallesInputs = [
  {
    type: "select",
    name: "tipo_inmueble_id",
    label: "Tipo de Inmueble",
    options: [
      { label: "Apartamento", value: 1 },
      { label: "Casa", value: 2 },
      { label: "Casa Conjunto", value: 3 },
    ],
    style: {
      marginBottom: "16px",
      width: "25%",
    },
    validation: yup.number().required(),
  },
  {
    type: "number",
    name: "area",
    placeholder: "Área",
    label: "Área",
    style: {
      marginBottom: "16px",
      width: "25%",
    },
    validation: yup.string().required(),
  },
  {
    type: "select",
    name: "banos",
    label: "Baños",
    options: generateNumberLabelsFromOne(6),
    style: {
      marginBottom: "16px",
      width: "25%",
    },
    validation: yup.number().required(),
  },
  {
    type: "select",
    name: "num_ascensores",
    label: "Número de ascensores",
    options: generateNumberLabelsFromZero(3),
    style: {
      marginBottom: "16px",
      width: "25%",
    },
    validation: yup.number().when("tipo_inmueble_id", {
      is: 1,
      then: yup.number().required(),
      otherwise: yup.number(),
    }),
  },
  {
    type: "select",
    name: "num_habitaciones",
    label: "Número de habitaciones (sin incluir servicio)",
    options: generateNumberLabelsFromOne(6),
    style: {
      marginBottom: "16px",
      width: "25%",
    },
    validation: yup.number().required(),
  },
  {
    type: "select",
    name: "garajes",
    label: "Número de garajes",
    options: generateNumberLabelsFromZero(6),
    style: {
      marginBottom: "16px",
      width: "25%",
    },
    validation: yup.number().required(),
  },
  {
    type: "number",
    name: "num_apartamento",
    placeholder: "Número de Apto",
    label: "Número de Apto/Casa",
    style: {
      marginBottom: "16px",
      width: "25%",
    },
    validation: yup.string().nullable(true),
  },
  {
    type: "select",
    name: "num_piso",
    label: "Número de piso",
    options: generateNumberLabelsFromOne(40),
    style: {
      marginBottom: "16px",
      width: "25%",
    },
    validation: yup.number().when("tipo_inmueble_id", {
      is: 1,
      then: yup.number().required(),
      otherwise: yup.number(),
    }),
  },
  {
    type: "radio",
    label: "Último piso",
    name: "ultimo_piso",
    options: [
      { label: "No", value: false },
      { label: "Sí", value: true },
    ],
    style: {
      marginBottom: "16px",
      width: "25%",
    },
    validation: yup.boolean().when("tipo_inmueble_id", {
      is: 1,
      then: yup.boolean().required(),
      otherwise: yup.boolean(),
    }),
  },
  {
    type: "radio",
    label: "Está en conjunto",
    name: "flag_conjunto",
    options: [
      { label: "No", value: 0 },
      { label: "Sí", value: 1 },
    ],
    style: {
      marginBottom: "16px",
      width: "25%",
    },
    validation: yup.number().required(),
  },
];

export const allInputs = [
  ...fuentes,
  ...infoInputs,
  ...direccionInputs,
  ...ubicacionValorAntiguedadInputs,
  ...detallesInputs,
];

export const formSections = [
  { label: "Fuente", inputs: fuentes },
  { label: "Información de contacto", inputs: infoInputs },
  { label: "Dirección", inputs: direccionInputs },
  {
    label: "Ubicación, valor y antiguedad",
    inputs: ubicacionValorAntiguedadInputs,
  },
  { label: "Detalles del inmueble", inputs: detallesInputs },
];

export const reconstituteDireccion = body => {
  const { tipo_calle, num1, num2, num3 } = body;
  return `${tipo_calle} ${num1} # ${num2} - ${num3}`;
};

export const formCompatibleValue = data => {
  const isApartment = data.tipo_inmueble_id === 1;
  if (isApartment) return data.num_apartamento;
  return data.num_casa;
};

export const createReqBody = data => {
  const direccion = reconstituteDireccion(data);
  const unwantedProps = [
    "fuente",
    "nidParaLlamada",
    "nid",
    "tipo_calle",
    "num1",
    "num2",
    "num3",
  ];
  const removeUnwantedProps = removeObjProps(...unwantedProps);

  const castAntiguedadToInt = data => ({
    ...data,
    anos_antiguedad: +data.anos_antiguedad,
  });
  const castAreaToInt = data => ({ ...data, area: +data.area });
  const castPropsToInt = pipe(castAntiguedadToInt, castAreaToInt);

  const setFlagConjuntoToZero = replaceObjProps({ flag_conjunto: 0 });
  const removeNumApto = removeObjProps("num_apartamento");

  const addNumCasa = replaceObjProps({ num_casa: data.num_apartamento });
  const changeNumAptoToNumCasa = pipe(addNumCasa, removeNumApto);
  const setFlagConjuntoToOne = replaceObjProps({ flag_conjunto: 1 });

  const removeAptoProps = removeObjProps("num_piso", "ultimo_piso");
  const setAscensorToZero = replaceObjProps({ num_ascensores: 0 });

  const casaHandler = pipe(setFlagConjuntoToZero, removeNumApto);

  const casaConjuntoHandler = pipe(
    changeNumAptoToNumCasa,
    setFlagConjuntoToOne
  );

  const notApartmentHandler = pipe(removeAptoProps, setAscensorToZero);

  const cleanerFns = [removeUnwantedProps, castPropsToInt];

  const isCasa = data.tipo_inmueble_id === 2;
  const isCasaConjunto = data.tipo_inmueble_id === 3;
  const isNotApartment = data.tipo_inmueble_id !== 1;

  if (isCasa) cleanerFns.push(casaHandler);
  if (isCasaConjunto) cleanerFns.push(casaConjuntoHandler);
  if (isNotApartment) cleanerFns.push(notApartmentHandler);

  const formCleaner = pipe(...cleanerFns);

  const cleanedData = formCleaner(data);

  const propsToAdd = {
    estado: "web_incompleto",
    user: data?.mailOwner ?? "",
    fuente_id: data.fuente_id,
    is_private: 0,
    terms_accepted: true,
  };
  const reqBody = { direccion, ...cleanedData, ...propsToAdd };
  return reqBody;
};

const habiPutCallCenter = async body => {
  const apiKey = process.env.REACT_APP_HABI_FINANCIAL_TOOL_API_KEY_CONTAINER;
  const rootUrl = process.env.REACT_APP_FORM_API_URL_V2;
  const apiFormsBase =
    process.env.REACT_APP_ENDPOINT_HABI_FINANCIAL_TOOL_API_BASE_PATH_CONTAINER;
  const putEndpoint =
    process.env.REACT_APP_ENDPOINT_HABI_FINANCIAL_TOOL_API_PUT_FORM_CALL_CENTER;
  const url = rootUrl + apiFormsBase + putEndpoint;
  await Axios({
    url,
    method: "put",
    headers: {
      "x-api-key": apiKey,
      "Content-Type": "application/json",
    },
    data: body,
  });
};

export const habiPostCallCenter = setLoading => async data => {
  const body = createReqBody(data);
  const apiKey = process.env.REACT_APP_HABI_FINANCIAL_TOOL_API_KEY_CONTAINER;
  const rootUrl = process.env.REACT_APP_FORM_API_URL_V2;
  const apiFormsBase =
    process.env.REACT_APP_ENDPOINT_HABI_FINANCIAL_TOOL_API_BASE_PATH_CONTAINER;
  const postEndpoint =
    process.env
      .REACT_APP_ENDPOINT_HABI_FINANCIAL_TOOL_API_POST_FORM_CALL_CENTER;
  const url = rootUrl + apiFormsBase + postEndpoint;
  setLoading(true);
  await Axios({
    url,
    method: "post",
    headers: {
      "x-api-key": apiKey,
      "Content-Type": "application/json",
    },
    data: body,
  })
    .then(res => {
      const id = res.data.idcodigo;
      const putBody = { ...body, id };
      // habiPutCallCenter(putBody);
      setLoading(false);
    })
    .catch(err => {
      console.error(err);
      setLoading(false);
    });
};
