import { twillioActiontypes } from "./twillioActionTypes";

const INITIAL_STATE = {
  calling: false,
  counter: false,
  error: false,
};

export const twillioReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case twillioActiontypes.START_CALL:
      return { ...state, ...payload };

    case twillioActiontypes.UPDATE_CALLING_STATUS:
      return { ...state, calling: payload };

    case twillioActiontypes.UPDATE_COUNTER_STATUS:
      return { ...state, counter: payload };

    case twillioActiontypes.UPDATE_ERROR_STATUS:
      return { ...state, error: payload };

    case twillioActiontypes.CLEAR_CALL_DATA:
      return INITIAL_STATE;

    default:
      return INITIAL_STATE;
  }
};
