import axios from "axios";
const Device = require("twilio-client").Device;

const rootUrl = process.env.REACT_APP_FORM_API_URL_V2;
const apiKey = process.env.REACT_APP_HABI_FINANCIAL_TOOL_API_KEY;

export const getNid = nid => {
  return axios({
    url: process.env.REACT_APP_ENDPOINT_GET_INFO_INMUEBLES_NID,
    method: "post",
    headers: {
      "x-api-key": apiKey,
      "Content-Type": "application/json",
    },
    data: nid,
  });
};

export const getInfoByNid = data => {
  return axios({
    url: process.env.REACT_APP_ENDPOINT_HABI_GET_COUNT_DATA,
    method: "post",
    headers: {
      "x-api-key": process.env.REACT_APP_HABI_FINANCIAL_TOOL_API_KEY,
      "Content-Type": "application/json",
    },
    data: data,
  });
};

export const getTokenTwilio = () => {
  const apikey = process.env.REACT_APP_HABI_TWILIO_API_KEY;
  const basePath = process.env.REACT_APP_ENDPOINT_HABI_TWILIO_API_BASE_PATH;
  const endpointUrl = process.env.REACT_APP_ENDPOINT_HABI_TWILIO_API_GET_TOKEN;
  const url = rootUrl + basePath + endpointUrl;
  // Device.destroy();
  return axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": apikey,
    },
  });
};

export const getComparable = (data, endPoint) => {
  const basePath =
    process.env.REACT_APP_ENDPOINT_HABI_FINANCIAL_TOOL_API_BASE_PATH;
  const url = rootUrl + basePath + endPoint;
  return axios.get(url, {
    params: {
      ...data,
    },
    headers: {
      "x-api-key": apiKey,
      "Content-Type": "application/json",
    },
  });
};

export const getMinMax = nid => {
  const basePath =
    process.env.REACT_APP_ENDPOINT_HABI_FINANCIAL_TOOL_API_BASE_PATH;
  const url = rootUrl + basePath + "get_comparable_min_max/" + nid;
  return axios.get(url, {
    headers: {
      "x-api-key": process.env.REACT_APP_HABI_FINANCIAL_TOOL_API_KEY,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "http://127.0.0.1:3000",
      " Access-Control-Allow-Headers": "Content-Type, Authorization",
    },
  });
};

export const putNumberContact = (nid, column_number, new_number) => {
  const url = process.env.REACT_APP_ENDPOINT_PUT_NUMBER_CONTACT;
  const data = {
    nid,
    column_number,
    new_number,
  };
  const headers = {
    "x-api-key": apiKey,
    "Content-Type": "text/plain",
  };
  return axios.put(url, data, { headers });
};
