import React, { useState, useEffect } from "react";
import { InputWrap } from "./style";
import NumberFormat from "react-number-format";
import { ErrorMessage } from "formik";

const CurrencyInput = ({
  name,
  value,
  errors,
  touched,
  values,
  id,
  label,
  className,
  setFieldValue,
  setFieldTouched,
  ...props
}) => {
  const [state, setState] = useState({
    value: props.default ? props.default : "",
    isValid: true,
  });

  useEffect(() => {
    const isValidField = () => {
      return errors[name] && touched[name];
    };
    setState({ ...state, isValid: isValidField() });
    if (values[name] || values[name] === "") {
      setState({ value: values[name], isValid: isValidField() });
    }
  }, [state.value, touched, values]);

  const { style, noStyleProps } = props;

  return (
    <InputWrap className={className}>
      <div className={`${state.isValid ? "errorInput" : ""}`}>
        <label>
          {label} {props.required ? "*" : ""}
        </label>
        <NumberFormat
          id={name}
          thousandSeparator={true}
          prefix={"$"}
          value={state.value}
          maxLength={props.maxLength}
          className="form-control"
          placeholder="$000.000"
          onValueChange={vals => {
            setState({ value: vals.formattedValue });
            setFieldTouched(name, true);
            if (vals.formattedValue === "") {
              setFieldValue(name, "");
            } else {
              setFieldValue(name, vals.floatValue);
            }
          }}
          {...noStyleProps}
        />
        {state.isValid && <div className="input-feedback">{errors[name]}</div>}
        <ErrorMessage
          name={name}
          component="div"
          className="invalid-feedback"
        />
      </div>
    </InputWrap>
  );
};

export default CurrencyInput;
