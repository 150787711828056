import { crmLeadActionTypes } from "../actions/crmLead.actions";

const INITIAL_STATE = {};

const crmLeadReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case crmLeadActionTypes.ADD_CRM_LEAD_DATA:
      return {
        ...state,
        ...payload,
      };
    case crmLeadActionTypes.CLEAR_CRM_LEAD_DATA:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default crmLeadReducer;
