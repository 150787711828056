import { polygonActionTypes } from "../actions/polygon.actions";

const INITIAL_STATE = [];

const polygonReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case polygonActionTypes.ADD_POLYGON_DATA:
      return [...state, ...payload];
    case polygonActionTypes.CLEAR_POLYGON_DATA:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default polygonReducer;
