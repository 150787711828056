import React from "react";
import { DataMarkerConjuntoWrapper } from "./style";

const MarkerConjunto = ({ data, showTable }) => (
  <DataMarkerConjuntoWrapper>
    <div className="row-data-marker">
      <div>
        <p>Nid</p>
      </div>
      <div>
        <p>{data.nid}</p>
      </div>
    </div>
    <div className="row-data-marker">
      <div>
        <p>Nombre del conjunto</p>
      </div>
      <div>
        <p>{data.conjunto_edificio}</p>
      </div>
    </div>
    <div className="row-data-marker">
      <div>
        <p>Area</p>
      </div>
      <div>
        <p>{data.area}</p>
      </div>
    </div>
    <div className="row-data-marker">
      <div>
        <p>Piso</p>
      </div>
      <div>
        <p>{data.num_piso}</p>
      </div>
    </div>
    <div className="row-data-marker">
      <div>
        <p>Baños</p>
      </div>
      <div>
        <p>{data.banos}</p>
      </div>
    </div>
    <div className="row-data-marker">
      <div>
        <p>Garajes</p>
      </div>
      <div>
        <p>{data.garajes}</p>
      </div>
    </div>
    <div className="row-data-marker">
      <div>
        <p>Ascensores</p>
      </div>
      <div>
        <p>{data.num_ascensores}</p>
      </div>
    </div>
    <div className="row-data-marker">
      <div>
        <p>Telefono</p>
      </div>
      <div>
        <p>{data.telefono}</p>
      </div>
    </div>
    <div className="row-data-marker">
      <div>
        <p>Precio Inicial</p>
      </div>
      <div>
        <p>{data.last_ask_price}</p>
      </div>
    </div>
    <div className="row-data-marker">
      <div>
        <p>Precio Habi</p>
      </div>
      <div>
        <p>{data.precio_habi}</p>
      </div>
    </div>
    <div className="row-data-marker">
      <div>
        <a onClick={() => showTable(data.idconjunto)}>Ver detalles..</a>
      </div>
      <div></div>
    </div>
  </DataMarkerConjuntoWrapper>
);

export default MarkerConjunto;
