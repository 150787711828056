import React from "react";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import SortingTableHeader from "../SortingTableHeader/SortingTableHeader";
import Button from "@habi/habi-react-components/dist/Button/button";
import InfoIcon from "@material-ui/icons/Info";
import Overlay from "../Overlay/overlay";
import CRMDetailsPage from "../../pages/CRMDetailsPage/CRMDetailsPage";
import SearchFilter from "../SearchFilter/searchFilter";

import {
  CRMTableContainer,
  SearchContainer,
  LoadingContainer,
} from "./CRMLeadsList.style";
import DotsLoading from "../DotsLoading/dotsLoading";

const CRMLeadsList = ({
  rows,
  handleSortRequest,
  crmHeadCells,
  order,
  orderBy,
  classes,
  addCrmData,
  addCrmLead,
  hasLead,
  clearCrmLead,
  page,
  handlePageChange,
  handleRowsPerPageChange,
  rowsPerPage,
  totalItems,
  searchFilterValue,
  loading,
}) => (
  <CRMTableContainer>
    <SearchContainer>
      <SearchFilter
        inputValue={searchFilterValue}
        placeholder="Buscar telefono"
        setInputValue={value => {
          addCrmData({ telefonoFiltro: value });
        }}
      />
    </SearchContainer>
    <Paper className={classes.paper}>
      <TableContainer>
        <Table className={classes.table}>
          <SortingTableHeader
            headerCells={crmHeadCells}
            keyPropName="label"
            sortPropName="value"
            labelPropName="label"
            orderBy={orderBy}
            order={order}
            handleSort={handleSortRequest}
            classes={classes}
          />
          <TableBody>
            {!loading &&
              rows.map(lead => (
                <TableRow key={lead.celular} className={classes.tableRow}>
                  {crmHeadCells.map(cell => {
                    if (cell.value !== "consultar")
                      return (
                        <TableCell key={lead[cell.value]}>
                          {lead[cell.value] || "No hay datos"}
                        </TableCell>
                      );
                    if (cell.value === "consultar")
                      return (
                        <TableCell key={Math.random()}>
                          <Button
                            id="button"
                            typeClass="primary"
                            className="d-inline-block "
                            label="Consultar"
                            type="button"
                            onClick={() => {
                              addCrmLead({ ...lead });
                            }}
                          >
                            <InfoIcon className="call-icon" />
                            &nbsp;
                          </Button>
                        </TableCell>
                      );
                    else return "";
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          rowsPerPageOptions={[10, 20, 30]}
          count={totalItems}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
        />
      </TableContainer>
      {loading && (
        <LoadingContainer>
          <DotsLoading />
        </LoadingContainer>
      )}
    </Paper>
    <Overlay openLeftModal={hasLead} dispatch={clearCrmLead}>
      {hasLead && <CRMDetailsPage clearCrmLead={clearCrmLead} />}
    </Overlay>
  </CRMTableContainer>
);

export default CRMLeadsList;
