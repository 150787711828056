import styled from "styled-components";
import { black, white, purple, error, size } from "../../../StylesConstants";

export const InputWrap = styled.div`
  margin: 0;

  .form-control {
    border: 2px solid #dddddd;
    box-sizing: border-box;
    border-radius: 100px;
    padding: 15px;
    height: 40px;
    font-size: 16px;
    line-height: 19px;
    color: ${black};
  }

  .form-control.is-invalid,
  .was-validated .form-control:invalid {
    background-image: none;
    background-color: rgba(255, 0, 0, 0.1);
    color: ${error};
  }

  .input-feedback {
    margin: 8px 0px 0px 32px;
    color: ${error};
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
  }

  .errorInput {
    .form-control {
      background-color: rgba(255, 0, 0, 0.1);
      color: ${error};
      border: 2px solid ${error};
    }
  }

  .form-control:focus {
    color: ${black};
    background-color: ${white};
    border-color: ${purple};
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(87, 1, 179, 0.2);
  }

  .input-label {
    font-size: 16px;
    line-height: 19px;
    color: ${black};
  }

  @media screen and (min-width: ${size.tablet}) {
    margin: 0 15px;

    .form-control {
      padding: 15px 32px;
    }
  }
`;
