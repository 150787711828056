import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { updateData } from "../../redux/actions/formData.actions";
import { MapWrapper, Title, Buttons, Map } from "./style";
import Button from "../sharedComponents/Button/button";
import { withRouter } from "react-router";

import { isMobile } from "react-device-detect";
import axios from "axios";
import { fetchDataAxios } from "../../utils/hooks/fetchRequest";

import MapWithAMarkerClusterer from "./mapWithAMarkerClusterer";
import ListSalesPropertiesMapContainer from "../ListSalesPropertiesMap/listSalesPropertiesMapContainer";

const MapContainer = props => {
  const [markers, setMarkers] = useState([]);
  const [table, setTable] = useState(false);
  const [loteid, setLoteId] = useState("");

  const [dataConjunto, setDataConjunto] = useState([]);
  const apikey = process.env.REACT_APP_HABI_FINANCIAL_TOOL_API_KEY;
  const rootUrl =
    process.env.REACT_APP_FORM_API_URL_V2 +
    process.env.REACT_APP_ENDPOINT_HABI_FINANCIAL_TOOL_API_BASE_PATH;
  const endpointUrlnid =
    process.env.REACT_APP_ENDPOINT_HABI_FINANCIAL_TOOL_API_GET_INMUEBLES_BY_NID;
  const endpointUrlmap =
    process.env.REACT_APP_ENDPOINT_HABI_FINANCIAL_TOOL_API_GET_MAPA_LOTES;
  const urlnid = rootUrl + endpointUrlnid;
  const urlmap = rootUrl + endpointUrlmap;

  useEffect(() => {
    if (props.type === "comparativo") {
      const getMarkers = () => {
        const fetchData = async () => {
          const body = {
            lote_id: "",
          };
          await axios({
            url: urlmap,
            method: "post",
            headers: {
              "x-api-key": apikey,
              "Content-Type": "application/json",
            },
            data: body,
          })
            .then(response => {
              let data = response.data;
              setMarkers(data);
            })
            .catch(error => {
              console.error(error);
            })
            .finally(function() {
              window.scrollTo(0, 0);
            });
        };
        fetchData();
      };
      getMarkers();
      return () => {};
    } else {
      const getMarkersNid = () => {
        const fetchData = async () => {
          const body = {
            nid: props.nid,
          };
          await axios({
            url: urlnid,
            method: "post",
            headers: {
              "x-api-key": apikey,
              "Content-Type": "application/json",
            },
            data: body,
          })
            .then(response => {
              let data = response.data;
              setMarkers(data);
            })
            .catch(error => {
              console.error(error);
            })
            .finally(function() {
              window.scrollTo(0, 0);
            });
        };
        fetchData();
      };
      getMarkersNid();
      return () => {};
    }
  }, []);

  const showTable = loteid => {
    setTable(true);
    setLoteId(loteid);
  };

  return (
    <Fragment>
      <MapWrapper>
        <Map>
          <MapWithAMarkerClusterer markers={markers} showTable={showTable} />
        </Map>
        <div className="table-map">
          {table ? <ListSalesPropertiesMapContainer loteid={loteid} /> : ""}
        </div>
      </MapWrapper>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  latitude: state.formData.latitude,
  longitude: state.formData.longitude,
  address: state.formData.address,
  inmueble_id: state.formData.inmueble_id,
  conjunto: state.formData.conjunto,
  nid: state.formData.nid,
});

const mapDispatchToProps = dispatch => ({
  updateData: data => dispatch(updateData(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MapContainer)
);
