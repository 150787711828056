export const range = (end, start, step = 1) => {
  if (!start) return [...Array(end).keys()];

  const length = Math.ceil((end - start) / step);

  const arr = [...Array(length).keys()];

  return arr.map(x => x + start + x * (step - 1));
};

export const head = arr => arr.slice(0, 1)[0];

export const tail = arr => arr.slice(1, arr.length);

export const decoupleHead = arr => [head(arr), tail(arr)];

export const evalPredicates = (...fns) => x => {
  const [head, tail] = decoupleHead(fns);
  return tail.length === 0
    ? head(x)
    : head(x)
    ? evalPredicates(...tail)(x)
    : false;
};

export const pipe = (...fns) => x => {
  const [head, ...tail] = fns;
  const res = head(x);
  return tail.length > 0 ? pipe(...tail)(res) : res;
};

export const map = fn => x => x.map(fn);

export const filter = (...fns) => x =>
  fns.length === 1 ? x.filter(fns[0]) : x.filter(evalPredicates(...fns));

export const removeObjProps = (...props) => data => {
  const cleanedData = props.reduce((acc, prop) => {
    const isNullOrUndefined = data[prop] === null || data[prop] === undefined;
    if (isNullOrUndefined) return acc;
    const { [prop]: omittedValue, ...rest } = acc;
    return rest;
  }, data);
  return cleanedData;
};

export const removeObjPropsDir = (data, ...props) => {
  const cleanedData = props.reduce((acc, prop) => {
    if (!data[prop]) return acc;
    const { [prop]: omittedValue, ...rest } = acc;
    return rest;
  }, data);
  return cleanedData;
};

export const replaceObjProps = kvPairs => data => ({ ...data, ...kvPairs });
