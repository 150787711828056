import React from 'react';
import { DotsLoadingWrapper } from './style';

const DotsLoading = props => {
  return (
    <DotsLoadingWrapper>
      <div className="dots-container">
        <div className="spinner-grow spinner-grow-sm" role="status">
          <span className="sr-only">Loading...</span>
        </div>
        <div className="spinner-grow spinner-grow-sm" role="status">
          <span className="sr-only">Loading...</span>
        </div>
        <div className="spinner-grow spinner-grow-sm" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </DotsLoadingWrapper>
  )
}

export default DotsLoading
